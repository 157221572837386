import fetch from 'cross-fetch';
import { get } from 'lodash';
import { api, subdomain } from '../../config';
import * as c from '../../constants/Api';

const receiveAccount = (json) => ({
  type: c.API_ACCOUNT_SUCCESS,
  payload: json,
});

export const fetchAccount = () => fetch(`${api}/account/${subdomain}`).then((res) => res.json());

// Before deployment add subdomain instead of school in endpoint
// eslint-disable-next-line import/prefer-default-export
export const fetchAccountInfoIfNeeded = () => (dispatch, getState) => {
  const account = get(getState(), 'account', false) || get(getState().resort, 'id', false);
  if (!account.length) {
    return fetchAccount()
      .then((json) => dispatch(receiveAccount(json)));
  }
  return Promise.resolve(account);
};
