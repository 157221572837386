const seTranslations = {
  buyer: {
    participant: 'Deltagare #',
    participant_info: 'Välj aktivitet och specialitet först',
    select: 'Välj...',
    name: 'Namn*',
    firstname: 'Förnamn*',
    enter_name: 'Ange ditt namn',
    surname: 'Efternamn',
    enter_surname: 'Ange ditt efternamn',
    age: 'Ålder*',
  },
  daypicker: {
    no_available_dates: '*Inga tillgängliga datum som matchar lektionspreferenser',
    select_activity: '*Välj aktivitet först',
    select_speciality: '*Välj specialitet först',
    fill_participants: '* Fyll i deltagare först',
  },
  footer: {
    get_in_touch: 'KOMMA I KONTAKT',
    address: 'Adress',
    phone: 'Telefon',
    email: 'E-postadress',
    map: 'Platskarta',
  },
  header: {
    checkout: 'Kolla upp',
  },
  item_extra_content: {
    add_to_cart: 'LÄGG TILL I KUNDVAGN',
    product_removed: 'Produkten borttagen',
    product_added: 'Produkten har lagts till',
    remove: 'TA BORT',
    day: 'Dag',
  },
  lesson: {
    remove: 'Ta bort',
    prefered_language: 'Önskat språk*',
    activity: 'Aktivitet*',
    speciality: 'Specialitet*',
    participants_info: 'Deltagarinformation:',
    number_of_participants: 'Antal deltagare:',
    max_no_participants: '*Max antal deltagare:',
    calculating: 'Beräknande...',
  },
  not_found: {
    title: 'Hoppsan',
    description: 'Sidan du letar efter kan inte hittas',
    button: 'GÅ TILLBAKA',
  },
  search: {
    resort: 'Tillflykt',
    add_filters: 'Ytterligare filter',
    clear_filters: 'Rensa filter',
    button: 'SÖK',
    activity: 'Aktivitet',
    speciality: 'Specialitet',
    start_date: 'Start datum',
    end_date: 'Slutdatum',
    language: 'Språk',
    type: 'Typ',
    age: 'Ålder',
  },
  validators: {
    required: 'Detta fält är obligatoriskt',
    phone: 'Ogiltig telefon',
    email: 'Ogiltig e-postadress',
    less: 'Måste vara lika med eller mindre än 10',
    more: 'Måste vara lika med eller fler än 10',
  },
  schoolinfo: {
    no_info: 'Ingen information',
  },
  price: {
    admin_fee: 'Administrationsavgift',
    total: 'Total:',
  },
  header_desktop: {
    loading: 'Läser in...',
  },
  spacees: {
    available_spaces: 'Tillgängliga utrymmen:',
  },
  no_participant: {
    disabled: 'Inaktiverad',
    max: 'Maximalt antal deltagare nått',
    no: 'Antal deltagare',
  },
  checkout_status: {
    thank: 'TACK',
    error: 'NÅGOT GICK FEL',
    back: 'TILLBAKA TILL KASSAN',
    continue: 'FORTSÄTTA',
  },
  client: {
    details: 'Dina detaljer:',
    title: 'Titel*',
    mrs: 'Fru',
    mr: 'herr',
    name: 'Namn*',
    firstname: 'Förnamn*',
    enter_name: 'Ange ditt namn',
    surname: 'Efternamn*',
    enter_surname: 'Ange ditt efternamn',
    tel: 'Telefonnummer*',
    enter_tel: 'Skriv in ditt telefonnummer',
    next: 'NÄSTA',
  },
  preview: {
    review: 'Granska din beställning',
    participants: 'Deltagarna',
    name: 'namn',
    firstname: 'Förnamn',
    surname: 'Efternamn',
    age: 'Ålder',
    exp: 'Erfarenhetsnivå',
    details: 'Dina detaljer:',
    email: 'E-post:',
    tel: 'Telefonnummer:',
    back: 'TILLBAKA',
    pay: 'BETALA NU',
  },
  error: {
    try_again: 'Försök igen senare',
  },
  resort_select: {
    resort: 'Tillflykt',
  },
  item_list: {
    no_results: 'Inga resultat',
  },
  success: {
    placed: 'Din beställning gjordes framgångsrikt',
  },
  levels: {
    first_steps: 'Första stegen',
    beginner: 'Nybörjare',
    intermediate: 'Mellanliggande',
    advanced: 'Avancerad',
  },
  cart: {
    empty: 'Varukorgen är tom',
  },
  api_activity: {
    Snowboard: 'Snowboard',
    'Cross-country': 'Längdåkning',
    'Ice Climbing': 'Isklättring',
    Snowshoes: 'Snöskor',
    Telemark: 'Telemark',
    'Ice Skating': 'Skridskoåkning',
    SKI: 'ÅKA SKIDOR',
    Any: 'Några',
  },
  api_speciality: {
    Racing: 'Tävlings',
    'Ice Hockey': 'Ishockey',
    'Cat skiing': 'Cat skiing',
    Standard: 'Standard',
    Heliskiing: 'Heli skidåkning',
    'Figure skating': 'Konståkning',
    Skating: 'Skridskoåkning',
    Any: 'Några',
    Handicap: 'Handikapp',
    Freeride: 'Snålskjuts',
    Guiding: 'Styrning',
    Freestyle: 'Freestyle',
    Classic: 'Klassisk',
    Touring: 'Touring',
  },
  api_type: {
    Any: 'Några',
    Private: 'enskild',
    Group: 'grupp',
  },
};

export default seTranslations;
