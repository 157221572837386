import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import DatePicker, { registerLocale } from 'react-datepicker';
import enDate from 'date-fns/locale/en-US';
import deDate from 'date-fns/locale/de';
import frDate from 'date-fns/locale/fr';
import itDate from 'date-fns/locale/it';
import csDate from 'date-fns/locale/cs';
import skDate from 'date-fns/locale/sk';
import bgDate from 'date-fns/locale/bg';
import plDate from 'date-fns/locale/pl';
import ruDate from 'date-fns/locale/ru';
import roDate from 'date-fns/locale/ro';
import siDate from 'date-fns/locale/sl'; // si
import esDate from 'date-fns/locale/es';
import hrDate from 'date-fns/locale/hr';
import nlDate from 'date-fns/locale/nl';
import fiDate from 'date-fns/locale/fi';
import seDate from 'date-fns/locale/sv'; // se
import grDate from 'date-fns/locale/el'; // gr
import huDate from 'date-fns/locale/hu';
import cnDate from 'date-fns/locale/ca'; // cn
import jpDate from 'date-fns/locale/ja'; // jp
import rsDate from 'date-fns/locale/sr-Latn'; // rs SRB
import krDate from 'date-fns/locale/ko'; // kr
import noDate from 'date-fns/locale/nb'; // no
import trDate from 'date-fns/locale/tr';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import CalendarIcon from '../Icons/CalendarIcon';

registerLocale('en', enDate);
registerLocale('de', deDate);
registerLocale('fr', frDate);
registerLocale('it', itDate);
registerLocale('cs', csDate);
registerLocale('sk', skDate);
registerLocale('bg', bgDate);
registerLocale('pl', plDate);
registerLocale('ru', ruDate);

registerLocale('ro', roDate);
registerLocale('si', siDate);
registerLocale('es', esDate);
registerLocale('hr', hrDate);
registerLocale('nl', nlDate);
registerLocale('fi', fiDate);
registerLocale('se', seDate);
registerLocale('gr', grDate);
registerLocale('hu', huDate);
registerLocale('cn', cnDate);
registerLocale('jp', jpDate);
registerLocale('rs', rsDate);
registerLocale('kr', krDate);
registerLocale('no', noDate);
registerLocale('tr', trDate);

const locales = ['en', 'de', 'fr', 'it', 'cs', 'sk', 'bg', 'pl', 'ru', 'ro', 'si', 'es', 'hr', 'nl', 'fi', 'se', 'gr', 'hu', 'cn', 'jp', 'rs', 'kr', 'no', 'tr'];
let locale;
i18n.on('initialized', () => {
  const lng = i18n.resolvedLanguage;
  locales.forEach((loc) => {
    if (lng === loc) locale = loc;
  });
});

class SkicDatePicker extends DatePicker {
  // Fix: It wont let user to open react-select, while react-datepicker is currently open
  deferFocusInput = () => {
    this.cancelFocusInput();
  }
}

const convertToUTC = (date) => moment.utc(date).subtract(new Date(date).getTimezoneOffset() / 60, 'hour');

const DateField = ({
  input,
  placeholder,
  label,
  meta: { touched, error },
  initialDate,
  includeDates,
  minDate,
  maxDate,
  changeDate,
  className = '',
  dateFormat = 'dd/MM/yyyy',
  momentFormat = 'DD/MM/YYYY',
  daysCount,
  startDate,
  endDate,
}) => {
  let highlight = [];
  for (let i = 1; i <= daysCount; i += 1) {
    highlight = [...highlight, moment(startDate).add(i, 'days').toDate()];
  }
  const highlightDates = [
    { 'react-datepicker__day--highlighted': highlight },
    { 'react-datepicker__day--highlighted-last': [moment(startDate).toDate(), moment(endDate).toDate()] },
  ];

  const handleDateChangeRaw = (e) => {
    e.preventDefault();
  };

  return (
    <div className="field field__select">
      <span className={`${className}__label`}>
        {label}
      </span>
      <SkicDatePicker
        {...input}
        dateFormat={dateFormat}
        includeDates={includeDates}
        className={`input ${className}`}
        placeholderText={placeholder}
        popperModifiers={{
          preventOverflow: {
            enabled: true,
          },
        }}
        selected={input.value ? moment(input.value, momentFormat).toDate() : initialDate.toDate()}
        value={moment(input.value || initialDate, momentFormat).toDate()}
        autoComplete="off"
        onChange={(val) => {
          const date = convertToUTC(val).toISOString().split('T')[0];
          const dateTime = `${date}T00:00:00.000Z`;
          input.onChange(moment(dateTime).format(momentFormat));
          changeDate(moment(dateTime), input.name);
        }}
        minDate={minDate.toDate()}
        maxDate={maxDate.toDate()}
        highlightDates={highlightDates}
        onChangeRaw={handleDateChangeRaw}
        showMonthDropdown
        showYearDropdown
        locale={locale}
      />
      <CalendarIcon />
      {touched && error && <span>{error}</span>}
    </div>
  );
};

DateField.propTypes = {
  startDate: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]),
  endDate: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]),
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.instanceOf(moment),
      PropTypes.string,
    ]).isRequired,
  }).isRequired,
  includeDates: PropTypes.arrayOf(),
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.bool,
  }),
  daysCount: PropTypes.number,
  placeholder: PropTypes.string,
  dateFormat: PropTypes.string,
  momentFormat: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string,
  initialDate: PropTypes.instanceOf(moment),
  changeDate: PropTypes.func,
  minDate: PropTypes.instanceOf(moment),
  maxDate: PropTypes.instanceOf(moment),
  handleDateChangeRaw: PropTypes.func,
};

export default DateField;
