import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { maxBy } from 'lodash';
import i18n from 'i18next';

// Components
import Buyer from './Buyer';
import SelectField from './SelectField';
import DayPicker from './DayPicker';

// Utils
import {
  LESSON_TYPE_INDIVIDUAL,
  LESSON_TYPE_GROUP,
  formatPrice,
  fetchPrice,
  mapLessonToGetPricePayload,
  getInitialLessonPrice,
  mapItemsToActivityOptions,
  mapItemsToSpecialityOptions,
  mapItemsToDates,
  invertDates,
  getSpecialityId,
} from '../utils';
import { lessonType } from '../../Item/ItemExtraContent';
import { formatTime } from '../../../utils/format';
import { mapLanguagesToOptions } from '../../../utils/map';
import { required, requiredAny } from '../../../utils/validators';

const Lesson = ({
  currency,
  lesson,
  onChange,
  onRemove,
}) => {
  const [price, setPrice] = useState(getInitialLessonPrice(lesson));
  const [isPriceFetching, setIsPriceFetching] = useState(false);
  const availableDates = mapItemsToDates(lesson.items, lesson.activity, lesson.speciality, lesson.buyers, lesson.type); // eslint-disable-line
  const disabledDates = [
    ...invertDates(availableDates),
    {
      before: new Date(availableDates[0]),
      after: new Date(availableDates[availableDates.length - 1]),
    },
  ];
  const maxCapacity = lesson.type === LESSON_TYPE_GROUP ? maxBy(lesson.items, 'capacity').capacity : null;

  const [initialSpeciality, showActivity] = useMemo(() => ([
    lesson.speciality,
    lesson.activityName === 'Any' || lesson.specialityName === 'Any',
  ]), []);

  const showSpeciality = useMemo(() => !!lesson.activity && lesson.specialityName === 'Any', [lesson.activity]);

  const [activityDisabled, specialityDisabled] = useMemo(() => ([
    isPriceFetching || lesson.activityName !== 'Any',
    isPriceFetching || lesson.specialityName !== 'Any',
  ]), [isPriceFetching]);

  const recalculatePrice = async (l) => {
    if (!l.dates.length || !l.buyers.length) return;
    try {
      setIsPriceFetching(true);
      const res = await fetchPrice(mapLessonToGetPricePayload(l));
      if (res.status !== 200) throw new Error();
      setPrice(formatPrice(res.body.price));
      onChange({ ...l, price: formatPrice(res.body.price) });
    } catch (e) {
      // Do nothing
    } finally {
      setIsPriceFetching(false);
    }
  };

  const onSelectDate = (date, { disabled }) => {
    if (disabled) return;
    const isoDate = date.toISOString();
    const updated = lesson.dates.includes(isoDate)
      ? lesson.dates.filter((el) => el !== isoDate) : [...lesson.dates, isoDate];

    onChange({ dates: updated });
    // Recalculate price
    recalculatePrice({ ...lesson, dates: updated });
  };

  const onPlusMinus = (increase) => {
    const updated = lesson.buyers;
    if (increase) {
      if (maxCapacity && lesson.buyers.length === maxCapacity) return;
      updated.push({ ...lesson.type === LESSON_TYPE_INDIVIDUAL ? { name: '', level: '' } : { name: '', surname: '', age: '' } });
    }
    if (!increase && updated.length > 1) {
      updated.pop();
    }
    onChange({ buyers: updated });
    // Recalculate price
    recalculatePrice({ ...lesson, buyers: updated });
  };

  const onBuyerChange = (idx, updatedData) => {
    const updated = lesson.buyers;
    updated[idx] = { ...updated[idx], ...updatedData };
    onChange({ buyers: updated });
  };

  return (
    <div className="skic-checkout__lesson-block__item">
      {/* Calendar */}
      <div className="calendar-placeholder" />
      <div className="skic-checkout__lesson-block__item__options-container">
        <div className="skic-checkout__lesson-block__item__options-container__header">
          <div className="skic-checkout__lesson-block__item__options-container__header__title">
            {lesson.name}
          </div>
          <div
            className="skic-checkout__lesson-block__item__options-container__header__remove-button"
            onClick={() => onRemove(lesson.id)}
          >
            {i18n.t('lesson.remove')}
            <div className="skic-icon skic-icon__remove" />
          </div>
        </div>
        {/* Meta data */}
        <div className="skic-checkout__lesson-block__item__options-container__lesson-info">
          <div className="lesson-info-item">
            <div className="skic-icon skic-icon__clock" />
            {formatTime(lesson.timeFrom)}
            {' '}
            -
            {formatTime(lesson.timeTo)}
          </div>
          <div className="lesson-info-item">
            <div className="skic-icon skic-icon__resort" />
            {lesson.resortName}
          </div>
          <div className="lesson-info-item">
            <div className="skic-icon skic-icon__users" />
            {lessonType(lesson.type)}
          </div>
        </div>
        {/* Language, Activity, Speciality */}
        <div className="skic-checkout__lesson-block__item__options-container__additional-options">
          <div className="options-row">
            <div className="option-selector">
              <SelectField
                clearable={false}
                className="checkout__language"
                onChange={(value) => onChange({ language: value })}
                label={i18n.t('lesson.prefered_language')}
                options={mapLanguagesToOptions(lesson.languages)}
                validate={[required]}
                value={lesson.language}
                disabled={isPriceFetching}
              />
            </div>
          </div>
          <div className="options-row">
            {showActivity && (
              <div className="option-selector">
                <SelectField
                  clearable={false}
                  label={i18n.t('lesson.activity')}
                  options={mapItemsToActivityOptions(lesson.items)}
                  onChange={(value) => {
                    onChange({
                      activity: value,
                      // Reset
                      speciality: initialSpeciality
                        ? getSpecialityId(lesson.items, value, lesson.specialityName)
                        : undefined,
                      dates: [],
                      buyers: lesson.buyers.map((el) => ({ ...el, name: '', level: '' })),
                    });
                  }}
                  validate={[required]}
                  value={lesson.activity}
                  disabled={activityDisabled}
                />
              </div>
            )}
            {showSpeciality && (
              <div className="option-selector">
                <SelectField
                  clearable={false}
                  label={i18n.t('lesson.speciality')}
                  options={mapItemsToSpecialityOptions(lesson.items, lesson.activity)}
                  simpleValue
                  onChange={(value) => onChange({
                    speciality: value,
                    // Reset
                    dates: [],
                    buyers: lesson.buyers.map((el) => ({ ...el, name: '', level: '' })),
                  })}
                  validate={[requiredAny]}
                  value={lesson.speciality}
                  disabled={specialityDisabled}
                />
              </div>
            )}
          </div>
        </div>
        {/* Counter */}
        <div className="skic-checkout__lesson-block__item__options-container__participants-info">
          <div className="section-title">
            {i18n.t('lesson.participants_info')}
          </div>
          <div className="participants-counter">
            <div className="checkout__number">
              <div className="checkout-lesson-label">
                {i18n.t('lesson.number_of_participants')}
              </div>
              <div className="plusminus">
                <button
                  type="button"
                  onClick={() => onPlusMinus(false)}
                  className="decreaser"
                  disabled={isPriceFetching}
                >
                  -
                </button>
                <div className="plusminus-value">
                  <div>
                    {lesson.buyers.length}
                  </div>
                </div>
                <button
                  type="button"
                  onClick={() => onPlusMinus(true)}
                  className="increaser"
                  disabled={isPriceFetching}
                >
                  +
                </button>
              </div>
              {maxCapacity && (
                <div className="plusminus-note">
                  {i18n.t('lesson.max_no_participants')}
                  &nbsp;
                  {maxCapacity}
                </div>
              )}
            </div>
          </div>
        </div>
        {/* Level / Name, Surname, Age */}
        <div className="experience-selector">
          <div className="lesson-type">
            {lesson.buyers.map((el, idx) => (
              <Buyer
                buyer={el}
                key={idx}  // eslint-disable-line
                idx={idx}
                lesson={lesson}
                onChange={(updatedData) => onBuyerChange(idx, updatedData)}
                disabled={isPriceFetching}
              />
            ))}
          </div>
        </div>
        <DayPicker
          onDayClick={onSelectDate}
          disabledDays={disabledDates}
          availableDates={availableDates}
          lesson={lesson}
          disabled={isPriceFetching}
        />
        <div className="price-container">
          {isPriceFetching ? (
            <div className="price-container__loader">
              {i18n.t('lesson.calculating')}
            </div>
          ) : (
            <>
              {price}
              {' '}
              {currency}
            </>
          )}
        </div>
        <div
          className="remove-button-mobile"
          onClick={() => onRemove(lesson.id)}
        >
          <div className="button-title">
            {i18n.t('lesson.remove')}
          </div>
          <div className="skic-icon skic-icon__remove" />
        </div>
      </div>
    </div>
  );
};

Lesson.propTypes = {
  currency: PropTypes.string,
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
  lesson: PropTypes.object, // eslint-disable-line
};

export default Lesson;

export const LessonShimmer = () => (
  <div className="skic-checkout__lesson-block__item">
    <div className="calendar-placeholder" />
    <div className="skic-checkout__lesson-block__item__options-container lesson-spinner">
      <div className="lesson-spinner__item" />
      <div className="lesson-spinner__item" />
      <div className="lesson-spinner__item" />
      <div className="lesson-spinner__item" />
      <div className="lesson-spinner__item" />
      <div className="lesson-spinner__item" />
      <div className="lesson-spinner__item" />
      <div className="lesson-spinner__item" />
      <div className="lesson-spinner__item" />
    </div>
  </div>
);
