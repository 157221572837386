import React from 'react';
import i18n from 'i18next';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { map } from 'lodash';
import languages from 'language-list';

// Components
import Price from '../../../components/Price';

// Utils
import { formatDate, formatTime, formatLevel } from '../../../utils/format';
import { lessonType } from '../../Item/ItemExtraContent';
import { LESSON_TYPE_GROUP } from '../utils';

const Preview = ({
  administrationFee,
  account,
  client,
  lessons,
  totalPrice,
  isValid,
  isSubmitting,
  onSubmit,
}) => (
  <div className="order-review">
    <div className="order-review__content">
      <div className="order-review__content__title">
        {i18n.t('preview.review')}
      </div>
      <div className="order-review__content__participants-info">
        <div className="order-review-participants">
          {map(lessons, (lesson) => {
            if (lesson.type === LESSON_TYPE_GROUP) {
              return (
                <div className="order-review-participants-block" key={lesson.id}>
                  <div className="order-review-participants-block__lesson-info">
                    <div className="lesson-title">
                      {lesson.name}
                    </div>
                    <div className="lesson-dates-info">
                      <div className="skic-icon skic-icon__date" />
                      <div>
                        {map(lesson.dates, (date) => (
                          <div
                            className="lesson-dates-info__date"
                            key={date}
                          >
                            {formatDate(date)}
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="lesson-info-row">
                      <div className="skic-icon skic-icon__time" />
                      {formatTime(lesson.timeFrom)}
                      {' '}
                      -
                      {formatTime(lesson.timeTo)}
                    </div>
                    <div className="lesson-info-row">
                      <div className="skic-icon skic-icon__location" />
                      {lesson.resortName}
                    </div>
                    <div className="lesson-info-row">
                      <div className="skic-icon skic-icon__type" />
                      {lessonType(lesson.type)}
                    </div>
                    <div className="lesson-info-row">
                      <div className="skic-icon skic-icon__language" />
                      {languages().getLanguageName(lesson.language)}
                    </div>
                    <div className="lesson-info-row">
                      <div className="skic-icon skic-icon__level" />
                      {formatLevel(lesson.level)}
                    </div>
                  </div>
                  <div className="order-review-participants-block__participants-info">
                    <div className="order-review-participants-block__participants-info__section-title">
                      {i18n.t('preview.participants')}
                    </div>
                    <div className="participants-table">
                      <div className="participants-table__header">
                        <div className="participants-table__header__row">
                          <div className="participants-table__header__row__cell">
                            {i18n.t('preview.firstname')}
                          </div>
                          <div className="participants-table__header__row__cell">
                            {i18n.t('preview.surname')}
                          </div>
                          <div className="participants-table__header__row__cell">
                            {i18n.t('preview.age')}
                          </div>
                        </div>
                      </div>
                      <div className="participants-table__body">
                        {map(lesson.buyers, (buyer, idx) => (
                          <div className="participants-table__body__row" key={idx}> {/* eslint-disable-line */}
                            <div className="participants-table__body__row__cell">
                              {buyer.firstname}
                            </div>
                            <div className="participants-table__body__row__cell">
                              {buyer.surname}
                            </div>
                            <div className="participants-table__body__row__cell">
                              {buyer.age}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="order-review-participants-block__footer">
                      {lesson.price}
                      {account.currency}
                    </div>
                  </div>
                </div>
              );
            }
            return (
              <div className="order-review-participants-block-private" key={lesson.id}>
                <div className="order-review-participants-block-private__lesson-info">
                  <div className="lesson-title">
                    {lesson.name}
                  </div>
                  <div className="lesson-dates-info">
                    <div className="skic-icon skic-icon__date" />
                    <div>
                      {map(lesson.dates, (date, index) => (
                        <div
                          className="lesson-dates-info__date"
                          key={index}
                        >
                          {formatDate(date)}
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="lesson-info-row">
                    <div className="skic-icon skic-icon__time" />
                    {formatTime(lesson.timeFrom)}
                    {' '}
                    -
                    {formatTime(lesson.timeTo)}
                  </div>
                  <div className="lesson-info-row">
                    <div className="skic-icon skic-icon__location" />
                    {lesson.resortName}
                  </div>
                  <div className="lesson-info-row">
                    <div className="skic-icon skic-icon__type" />
                    {lessonType(lesson.type)}
                  </div>
                  <div className="lesson-info-row">
                    <div className="skic-icon skic-icon__language" />
                    {languages().getLanguageName(lesson.language)}
                  </div>
                  <div className="lesson-info-row">
                    <div className="skic-icon skic-icon__level" />
                    {formatLevel(lesson.level)}
                  </div>
                </div>
                <div className="order-review-participants-block-private__participants-info">
                  <div className="order-review-participants-block-private__participants-info__section-title">
                    {i18n.t('preview.participants')}
                  </div>
                  <div className="participants-table">
                    <div className="participants-table__header">
                      <div className="participants-table__header__row">
                        <div className="participants-table__header__row__cell">
                          {i18n.t('preview.exp')}
                        </div>
                      </div>
                    </div>
                    <div className="participants-table__body">
                      {map(lesson.buyers, (buyer, idx) => (
                        <div className="participants-table__body__row" key={idx}> {/* eslint-disable-line */}
                          <div className="participants-table__body__row__cell">
                            {formatLevel(buyer.level)}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="order-review-participants-block-private__footer">
                    {lesson.price}
                    {' '}
                    {account.currency}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <Price
          administrationFee={administrationFee}
          currency={account.currency}
          price={totalPrice}
        />
        <div className="order-review-personal-info">
          <div className="order-review-personal-info__title-container">
            <div className="title">
              {i18n.t('preview.details')}
            </div>
          </div>
          <div className="order-review-personal-info__personal-details-container">
            <div className="client-name-row">
              {`${client.gender === 'male' ? 'Mr.' : 'Mrs.'} ${client.name} ${client.surname}`}
            </div>
            <div className="client-contacts-row">
              <div className="client-contacts-row__client-contanct-cell">
                <div className="client-contacts-row__client-contanct-cell__label">
                  {i18n.t('preview.email')}
                </div>
                <div className="client-contacts-row__client-contanct-cell__info">
                  {client.email}
                </div>
              </div>
              <div className="client-contacts-row__client-contanct-cell">
                <div className="client-contacts-row__client-contanct-cell__label">
                  {i18n.t('preview.tel')}
                </div>
                <div className="client-contacts-row__client-contanct-cell__info">
                  {client.phoneNumber}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="order-review__content__button-container">
        <div className="empty-div" />
        <div className="button-panel">
          <div className="button-panel__buttons">
            <NavLink
              className="back-button"
              to="/checkout"
              onClick={(e) => { if (isSubmitting) e.preventDefault(); }}
            >
              {i18n.t('preview.back')}
            </NavLink>
            <button
              className="next-button"
              onClick={onSubmit}
              disabled={!isValid || isSubmitting}
              type="button"
            >
              {i18n.t('preview.pay')}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
);

Preview.propTypes = {
  administrationFee: PropTypes.string,
  account: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  client: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  isSubmitting: PropTypes.bool,
  isValid: PropTypes.bool,
  lessons: PropTypes.array, // eslint-disable-line react/forbid-prop-types
  onSubmit: PropTypes.func,
  totalPrice: PropTypes.string,
};

export default Preview;
