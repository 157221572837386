import React from 'react';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CheckoutStatus from '../../components/CheckoutStatus/CheckoutStatus';
import { clearCart } from '../../redux/actions/cartActions';

class Success extends React.Component {
  componentDidMount() {
    const { clearCartAction } = this.props;
    localStorage.clear();
    clearCartAction();
  }

  render() {
    return (
      <CheckoutStatus success={i18n.t('success.placed')} />
    );
  }
}

Success.propTypes = {
  clearCartAction: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  clearCartAction: bindActionCreators(clearCart, dispatch),
});

export default connect(null, mapDispatchToProps)(Success);
