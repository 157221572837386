import React from 'react';
import PropTypes from 'prop-types';
import ReactPhoneInput from 'react-phone-input-2';
import { Grid } from 'semantic-ui-react';
import 'react-phone-input-2/lib/style.css';

// hooks
import useField from '../../../hooks/field';

const RenderPhoneInput = ({
  label,
  onChange,
  validate,
  value,
}) => {
  const field = useField({ initialValue: value, validate, onPhoneChange: onChange });
  const dirty = field.dirty();
  const error = field.error();

  return (
    <Grid.Column width={8}>
      <div className="phone-input-wrapper">
        <div className={dirty && error ? 'checkout-lesson-label checkout-lesson-label--error' : 'checkout-lesson-label'}>
          {label}
        </div>
        <div className="phone-input">
          <ReactPhoneInput
            {...field.attributes}
            enableSearchField
            name="phoneNumberInput"
          />
        </div>
        {dirty && error && <small className="error">{error}</small>}
      </div>
    </Grid.Column>
  );
};

RenderPhoneInput.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  validate: PropTypes.array, // eslint-disable-line
  value: PropTypes.string,
};

export default RenderPhoneInput;
