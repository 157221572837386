const siTranslations = {
  buyer: {
    participant: 'Udeleženec #',
    participant_info: 'Najprej izberite dejavnost in specialnost',
    select: 'Izberite ...',
    name: 'Ime*',
    firstname: 'Ime*',
    enter_name: 'Vnesite svoje ime',
    surname: 'Priimek',
    enter_surname: 'Vnesite svoj priimek',
    age: 'Starost*',
  },
  daypicker: {
    no_available_dates: '*Ni razpoložljivih datumov, ki bi ustrezali željam lekcije',
    select_activity: '*Najprej izberite dejavnost',
    select_speciality: '*Najprej izberite posebnost',
    fill_participants: '*Najprej vnesite udeležence',
  },
  footer: {
    get_in_touch: 'POVEŽITE SE',
    address: 'Naslov',
    phone: 'Telefon',
    email: 'Email naslov',
    map: 'Zemljevid lokacije',
  },
  header: {
    checkout: 'Preveri',
  },
  item_extra_content: {
    add_to_cart: 'DODAJ V VOZIČEK',
    product_removed: 'Izdelek odstranjen',
    product_added: 'Izdelek dodan',
    remove: 'ODSTRANI',
    day: 'Dan',
  },
  lesson: {
    remove: 'Odstrani',
    prefered_language: 'Želeni jezik*',
    activity: 'Dejavnost*',
    speciality: 'Posebnost*',
    participants_info: 'Informacije o udeležencih:',
    number_of_participants: 'Število udeležencev:',
    max_no_participants: '*Največje število udeležencev:',
    calculating: 'Izračun ...',
  },
  not_found: {
    title: 'Ups',
    description: 'Strani, ki jo iščete, ni mogoče najti',
    button: 'POJDI NAZAJ',
  },
  search: {
    resort: 'Letovišče',
    add_filters: 'Dodatni filtri',
    clear_filters: 'Počisti filtre',
    button: 'ISKANJE',
    activity: 'Dejavnost',
    speciality: 'Posebnost',
    start_date: 'Začetni datum',
    end_date: 'Končni datum',
    language: 'Jezik',
    type: 'Vrsta',
    age: 'Starost',
  },
  validators: {
    required: 'To polje je obvezno',
    phone: 'Neveljaven telefon',
    email: 'Neveljaven e-poštni naslov',
    less: 'Mora biti enako ali manjše od 10',
    more: 'Mora biti enako ali več kot 10',
  },
  schoolinfo: {
    no_info: 'Ni informacij',
  },
  price: {
    admin_fee: 'Administrativna provizija',
    total: 'Skupaj:',
  },
  header_desktop: {
    loading: 'Nalaganje...',
  },
  spacees: {
    available_spaces: 'Prosti prostori:',
  },
  no_participant: {
    disabled: 'Onemogočeno',
    max: 'Doseženo je največje število udeležencev',
    no: 'Število udeležencev',
  },
  checkout_status: {
    thank: 'HVALA VAM',
    error: 'NEKAJ JE ŠLO NAROBE',
    back: 'NAZAJ NA BLAGAJNO',
    continue: 'NADALJUJ',
  },
  client: {
    details: 'Vaši podatki:',
    title: 'Naslov*',
    mrs: 'Ga',
    mr: 'Gospod',
    name: 'Ime*',
    firstname: 'Ime*',
    enter_name: 'Vnesite svoje ime',
    surname: 'Priimek*',
    enter_surname: 'Vnesite svoj priimek',
    tel: 'Telefonska številka*',
    enter_tel: 'Vnesite svojo telefonsko številko',
    next: 'NASLEDNJI',
  },
  preview: {
    review: 'Preglej svoje naročilo',
    participants: 'Udeleženci',
    name: 'Ime',
    firstname: 'Ime',
    surname: 'Priimek',
    age: 'Starost',
    exp: 'Raven izkušenj',
    details: 'Vaši podatki:',
    email: 'E-naslov:',
    tel: 'Telefonska številka:',
    back: 'NAZAJ',
    pay: 'PLAČAJ ZDAJ',
  },
  error: {
    try_again: 'Nekaj ​​je šlo narobe ... Poskusite znova pozneje',
  },
  resort_select: {
    resort: 'Letovišče',
  },
  item_list: {
    no_results: 'Brez rezultatov',
  },
  success: {
    placed: 'Vaše naročilo je bilo uspešno oddano',
  },
  levels: {
    first_steps: 'Prvi koraki',
    beginner: 'Začetnik',
    intermediate: 'Vmesni',
    advanced: 'Napredno',
  },
  cart: {
    empty: 'Košarica je prazna',
  },
  api_activity: {
    Snowboard: 'Deskanje na snegu',
    'Cross-country': 'Tek na smučeh',
    'Ice Climbing': 'Ledno plezanje',
    Snowshoes: 'Krplje',
    Telemark: 'Telemark',
    'Ice Skating': 'Drsanje na ledu',
    SKI: 'SMUČI',
    Any: 'Vse',
  },
  api_speciality: {
    Racing: 'Dirkanje',
    'Ice Hockey': 'Hokej na ledu',
    'Cat skiing': 'Mačje smučanje',
    Standard: 'Privzeto',
    Heliskiing: 'Heli smučanje',
    'Figure skating': 'Umetnostno drsanje',
    Skating: 'Drsanje na ledu',
    Any: 'Vse',
    Handicap: 'Hendikep',
    Freeride: 'Brezplačna vožnja',
    Guiding: 'Voditi',
    Freestyle: 'Prosti slog',
    Classic: 'Klasična',
    Touring: 'Turneja',
  },
  api_type: {
    Any: 'Vse',
    Private: 'posameznika',
    Group: 'skupina',
  },
};

export default siTranslations;
