import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';

// hooks
import useField from '../../../hooks/field';

const TextField = ({
  onChange,
  validate,
  placeholder,
  label,
  width = 8,
  labelClass = '',
  className = '',
  maxLength,
  value,
  type,
  disabled,
}) => {
  const field = useField({ initialValue: value, validate, onChange });
  const dirty = field.dirty();
  const error = field.error();

  return (
    <Grid.Column width={width}>
      <div className={dirty && error ? `checkout-lesson-label--error ${labelClass}` : labelClass}>
        {label}
      </div>
      <div className={`ui fluid input ${className}`}>
        <input
          {...field.attributes}
          placeholder={placeholder}
          maxLength={maxLength}
          type={type}
          disabled={disabled}
        />
      </div>
      {dirty && error && <small className="error">{error}</small>}
    </Grid.Column>
  );
};

TextField.propTypes = {
  value: PropTypes.any, // eslint-disable-line
  onChange: PropTypes.func,
  validate: PropTypes.array, // eslint-disable-line
  label: PropTypes.string,
  labelClass: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  width: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  maxLength: PropTypes.number,
  type: PropTypes.string,
  disabled: PropTypes.bool,
};

export default TextField;
