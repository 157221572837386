import * as c from '../../constants/Redux';

export const addToCart = (payload) => ({
  type: c.REDUX_CART_ADD,
  payload,
});

export const removeFromCart = (id) => ({
  type: c.REDUX_CART_REMOVE,
  id,
});

export const addToCartFromLocalStorage = (ids) => ({
  type: c.REDUX_CART_LOCAL_STORAGE,
  ids,
});

export const clearCart = () => ({
  type: c.REDUX_CART_CLEAR,
});
