import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Field, reduxForm, getFormValues, change,
} from 'redux-form';

import i18n from 'i18next';

import { Checkbox, Form, Dropdown } from 'semantic-ui-react';
import moment from 'moment';
import languages from 'language-list';
import {
  forEach, includes, map, remove, mapValues,
} from 'lodash';
import classNames from 'classnames';

import DateField from '../../components/Field/Date';
import TextField from '../../components/Field/TextField';
import {
  requestCustomLessonsPage,
  setTimeFromDate,
  setTimeToDate,
} from '../../redux/actions/lessonActions';
import ResortsSelection from './ResortsSelection';
import { addSelectedResort } from '../../redux/actions/resortsActions';
import {
  addSelectedSpecialities,
  fetchSpecialitiesIfNeeded,
} from '../../redux/actions/specialitiesActions';
import {
  addSelectedLanguages,
  fetchLanguagesIfNeeded,
} from '../../redux/actions/languagesActions';
import { addSelectedType } from '../../redux/actions/typeActions';
import { addSelectedAge } from '../../redux/actions/ageActions';

const languagesList = (languages)();
const typeOptions = [
  { value: 'individual', label: 'Private' },
  { value: 'group', label: 'Group' },
  { value: 'any', label: 'Any' },
];

class Search extends Component {
  constructor(props) {
    super(props);

    const startDate = moment();
    const endDate = moment().add(1, 'month').set({
      hour: 23,
      minute: 59,
      second: 59,
    });

    this.state = {
      startDate,
      endDate,
      expanded: false,
      selectedResort: {
        value: null,
        label: i18n.t('search.resort'),
      },
      selectedActivities: [],
      selectedSpecialities: ['Any'],
      selectedLanguages: [],
      selectedType: 'any',
      activityCheck: {},
      specialityCheck: {},
      languageCheck: {},
      isInitialValuesSet: false,
    };

    this.initialStartDate = startDate.format('YYYY-MM-DD');
    this.initialEndDate = endDate.format('YYYY-MM-DD');
  }

  componentDidMount() {
    const {
      account,
      requestCustomLessonsPageAction,
      fetchSpecialitiesIfNeededA,
      fetchLanguagesIfNeededA,
    } = this.props;

    const {
      startDate,
      endDate,
    } = this.state;

    requestCustomLessonsPageAction(1, 5, {
      timeFrom: startDate.toISOString(),
      timeTo: endDate.toISOString(),
      ...account && account.resort ? { resorts: [account.resort.id] } : {},
    });
    fetchSpecialitiesIfNeededA();
    fetchLanguagesIfNeededA().then((data) => this.setState({
      languageCheck: data.payload.languages.reduce((obj, cur) => ({ ...obj, [cur]: false }), {}),
      // transforming current fetched data into object for checkbox checked state
      // ["ru", "en"] => { ru: false, en: false }
    }));
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      specialitiesNames,
      activitiesNames,
    } = nextProps;

    const { isInitialValuesSet } = this.state;

    if (!isInitialValuesSet && activitiesNames && specialitiesNames) {
      this.setState({
        isInitialValuesSet: true,
        activityCheck: activitiesNames.reduce((obj, cur) => ({ ...obj, [cur]: false }), {}),
        // transforming activitiesNames values into object with key:false
        // ["SKI", "Snowboard"] => { SKI: false, Snowboard: false }
        specialityCheck: specialitiesNames.reduce((obj, cur) => ({ ...obj, [cur]: false }), {}),
        // transforming activitiesNames values into object with key:false
        // ["SKI", "Snowboard"] => { SKI: false, Snowboard: false }
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state !== prevState) {
      this.filterCounter();
    }
  }

  onSearchButtonClick() {
    const {
      formValues,
      setTimeFromDateA,
      setTimeToDateA,
      requestCustomLessonsPageAction,
      filter: {
        type,
        specialities,
        resorts,
      },
      setVisible,
    } = this.props;
    const { selectedLanguages, startDate, endDate } = this.state;
    let tFrom = startDate;
    let tTo = endDate;

    if (formValues && formValues.timeFrom) {
      tFrom = moment.utc(formValues.timeFrom, 'DD/MM/YYYY').toISOString();
      setTimeFromDateA(tFrom);
    }

    if (formValues && formValues.timeTo) {
      tTo = moment.utc(formValues.timeTo, 'DD/MM/YYYY').toISOString();
      setTimeToDateA(tTo);
    }

    let minAge = 0;

    if (formValues && formValues.minAge) {
      ({ minAge } = formValues);
    }

    const payload = {
      timeFrom: tFrom,
      timeTo: tTo,
      type,
      specialities,
      languages: selectedLanguages,
      resorts,
      minAge,
    };
    requestCustomLessonsPageAction(1, 5, payload);
    if (window.innerWidth < 992) {
      setVisible(false);
    }
  }

  getDayCount = () => {
    const { startDate, endDate } = this.state;
    return Math.floor((endDate - startDate) / 3600000 / 24);
  }

  handleClear = () => {
    const {
      addSelectedResortA,
      setTimeToDateA,
      changeA,
    } = this.props;
    const { languageCheck } = this.state;

    // Date restore
    const tFrom = moment();
    changeA('search', 'timeFrom', tFrom);
    const tTo = moment().add(1, 'month').set({
      hour: 23,
      minute: 59,
      second: 59,
    });

    this.changeDate(tFrom, 'timeFrom');
    this.changeDate(tTo, 'timeTo');
    changeA('search', 'timeTo', tTo);
    setTimeToDateA(tTo);

    addSelectedResortA();

    // Activity and Speciality restore (checkboxes and payload)
    this.handleSelection();

    // Language restore
    // in every language key sets value to false => { "lang1": false, "lang2": false }
    const clearLanguages = Object.keys(languageCheck).reduce((p, c) => ({ ...p, [c]: false }), {});
    this.addLanguagesToPayload();

    // Type restore
    this.addTypeToPayload('any');

    this.setState({
      languageCheck: clearLanguages,
      selectedResort: { value: null, label: i18n.t('search.resort') },
    });
  }

  handleSelection = (activity, speciality) => {
    const {
      selectedActivities,
      selectedSpecialities,
      activityCheck,
      specialityCheck,
    } = this.state;
    let updatedSelectedActivities = selectedActivities;
    let updatedSelectedSpecialities = selectedSpecialities;

    if (activity) {
      if (updatedSelectedActivities.includes(activity)) {
        this.setState({
          activityCheck: {
            ...activityCheck,
            [activity]: false,
          },
        });
        remove(updatedSelectedActivities, (updatedActivity) => updatedActivity === activity);
      } else {
        this.setState({
          activityCheck: {
            ...activityCheck,
            [activity]: true,
          },
        });
        updatedSelectedActivities.push(activity);
      }
    }
    if (speciality) {
      if (updatedSelectedSpecialities.includes(speciality)) {
        this.setState({
          specialityCheck: {
            ...specialityCheck,
            [speciality]: false,
          },
        });
        remove(updatedSelectedSpecialities,
          (updatedSpeciality) => updatedSpeciality === speciality);
      } else {
        this.setState({
          specialityCheck: {
            ...specialityCheck,
            [speciality]: true,
          },
        });
        updatedSelectedSpecialities.push(speciality);
      }
      if (updatedSelectedSpecialities.length > 1 && includes(updatedSelectedSpecialities, 'Any')) {
        remove(updatedSelectedSpecialities, (updatedSpeciality) => updatedSpeciality === 'Any');
      } else if (updatedSelectedSpecialities.length === 0) {
        updatedSelectedSpecialities.push('Any');
      }
    }

    if (!speciality && !activity) { // both undefined are only onClick clear button
      updatedSelectedActivities = [];
      updatedSelectedSpecialities = ['Any'];
      const clearActivities = mapValues(activityCheck, () => false);
      const clearSpecialities = mapValues(activityCheck, () => false);

      this.setState({
        activityCheck: clearActivities,
        specialityCheck: clearSpecialities,
      });
    }

    this.formatSpecialitiesPayload(updatedSelectedActivities, updatedSelectedSpecialities);
    this.setState({
      selectedActivities: updatedSelectedActivities,
      selectedSpecialities: updatedSelectedSpecialities,
    });
  }

  addLanguagesToPayload = (selectedLanguage) => {
    const { addSelectedLanguagesA } = this.props;
    if (selectedLanguage) {
      const { selectedLanguages, languageCheck } = this.state;
      const updatedSelectedLanguages = selectedLanguages;
      if (updatedSelectedLanguages.includes(selectedLanguage)) {
        this.setState({
          languageCheck: {
            ...languageCheck,
            [selectedLanguage]: false,
          },
        });
        remove(updatedSelectedLanguages, (updatedLanguage) => updatedLanguage === selectedLanguage);
      } else {
        this.setState({
          languageCheck: {
            ...languageCheck,
            [selectedLanguage]: true,
          },
        });
        updatedSelectedLanguages.push(selectedLanguage);
      }
      this.setState({
        selectedLanguages: updatedSelectedLanguages,
      });
      addSelectedLanguagesA(updatedSelectedLanguages);
    } else {
      this.setState({
        selectedLanguages: [],
      });
      addSelectedLanguagesA([]);
    }
  }

  addTypeToPayload = (selectedType) => {
    const {
      addSelectedTypeA,
      addSelectedAgeA,
      changeA,
    } = this.props;
    let { selectedType: tempType } = this.state;

    if (tempType === selectedType || !selectedType) {
      tempType = 'any';
      this.setState({ selectedType: 'any' });
    } else {
      tempType = selectedType;
      this.setState({ selectedType: tempType });
    }
    if (tempType !== 'group') {
      addSelectedAgeA(null);
      changeA('search', 'minAge', '');
    }
    addSelectedTypeA(tempType);
  }

  handleSelectedResort = (resort) => {
    const { addSelectedResortA } = this.props;
    this.setState({
      selectedResort: {
        value: resort ? resort.value : null,
        label: resort ? resort.label : i18n.t('search.button'),
      },
    });
    if (resort !== null) {
      addSelectedResortA([resort.value]);
    } else addSelectedResortA();

    return resort ? resort.label : '';
  }

  changeDate = (value, type) => {
    const { changeA } = this.props;
    const { startDate, endDate } = this.state;
    if (type === 'timeFrom') {
      if (moment(value).isAfter(endDate)) {
        changeA('search', 'timeFrom', value);
        changeA('search', 'timeTo', value);
        this.setState({
          startDate: value,
          endDate: value,
        });
      } else {
        this.setState({
          startDate: value,
        });
      }
    } else if (moment(value).isBefore(startDate)) {
      changeA('search', 'timeFrom', value);
      changeA('search', 'timeTo', value);
      this.setState({
        startDate: value,
        endDate: value,
      });
    } else this.setState({ endDate: value });
  }

  handleExpand = () => {
    this.setState((prevState) => ({
      expanded: !prevState.expanded,
    }));
  }

  classNameGenerator = (expanded) => {
    const expandableColumnClassName = classNames(
      'skic-search__column',
      'skic-search__column__expandable',
      {
        'skic-search__column__expandable--expanded': expanded,
      },
    );

    const expandableButtonClassName = classNames(
      'skic-search__submit',
      {
        'skic-search__submit--expanded': expanded,
      },
    );

    const iconClassName = classNames(
      'skic-icon',
      {
        'skic-icon__minus-square': expanded,
        'skic-icon__plus-square': !expanded,
      },
    );

    return {
      expandableColumnClassName,
      expandableButtonClassName,
      iconClassName,
    };
  }

  expandedFiltersCounter = () => {
    let counter = 0;
    const { selectedLanguages, selectedSpecialities, selectedType } = this.state;
    const selectedSpecialitiesLength = selectedSpecialities.filter((specialty) => specialty !== 'Any').length;
    if (selectedSpecialitiesLength > 0) counter += 1;
    if (selectedLanguages.length > 0) counter += 1;
    if (selectedType !== 'any') counter += 1;
    return counter;
  }

  filterCounter = () => {
    const {
      startDate,
      endDate,
      selectedType,
      selectedSpecialities,
      selectedActivities,
      selectedResort,
      selectedLanguages,
    } = this.state;

    const { filterSelectedCount } = this.props;

    let counter = 0;
    if ((Object.keys(selectedResort).length > 0 || selectedResort.length > 0)
      && selectedResort.id !== null) counter += 1;
    if (selectedLanguages.length > 0) counter += 1;
    if (selectedActivities.length > 0) counter += 1;
    if (selectedSpecialities[0] !== 'Any') counter += 1;
    if (selectedType !== 'any') counter += 1;
    const formattedStartDate = startDate.format('YYYY-MM-DD');
    const formattedEndDate = endDate.format('YYYY-MM-DD');

    if (formattedStartDate !== this.initialStartDate
      || formattedEndDate !== this.initialEndDate) counter += 1;
    if (filterSelectedCount) filterSelectedCount(counter);
    return counter;
  }

  formatSpecialitiesPayload(selectedActivities, selectedSpecialities) {
    const {
      activities,
      specialities,
      addSelectedSpecialitiesA,
    } = this.props;
    const selectedIds = [];
    if (selectedActivities.length > 0) {
      forEach(selectedActivities, (selectedActivity) => {
        if (selectedSpecialities.length === 1 && selectedSpecialities[0] === 'Any') {
          selectedIds.push(...activities[selectedActivity].AllAny);
        } else {
          forEach(selectedSpecialities, (selectedSpeciality) => {
            selectedIds.push(activities[selectedActivity][selectedSpeciality]);
            if (!selectedIds.includes(activities.Any[selectedSpeciality])) {
              selectedIds.push(activities.Any[selectedSpeciality]);
            }
          });
          selectedIds.push(specialities.AllAny[selectedActivity]);
        }
      });
      if (selectedSpecialities.length === 1 && selectedSpecialities[0] === 'Any') {
        selectedIds.push(...activities.Any.AllAny);
      } else {
        selectedIds.push(activities.Any.Any);
      }
    } else if (!selectedSpecialities.includes('Any')) {
      forEach(selectedSpecialities, (selectedSpeciality) => {
        selectedIds.push(...specialities[selectedSpeciality]);
      });
      selectedIds.push(...specialities.Any);
    }
    addSelectedSpecialitiesA(selectedIds);
  }

  addAgeToPayload(age) {
    const { addSelectedAgeA } = this.props;
    addSelectedAgeA(age);
  }

  checkSpecialitiesDisablement(name) {
    const { activities } = this.props;
    const { selectedActivities } = this.state;
    let isDisabled = false;
    forEach(selectedActivities, (selectedActivity) => {
      if (!Object.prototype.hasOwnProperty.call(activities[selectedActivity], name)) {
        isDisabled = true;
      }
    });
    return isDisabled;
  }

  checkActivitiesDisablement(name) {
    const { activities } = this.props;
    const { selectedSpecialities } = this.state;
    let isDisabled = false;
    forEach(selectedSpecialities, (selectedSpeciality) => {
      if (!Object.prototype.hasOwnProperty.call(activities[name], selectedSpeciality)) {
        isDisabled = true;
      }
    });
    return isDisabled;
  }

  render() {
    const {
      handleSubmit,
      languagesArray,
      activitiesNames,
      specialitiesNames,
      filtersSelected,
      handleOpen,
      handleClose,
    } = this.props;

    const {
      expanded,
      startDate,
      endDate,
      selectedResort,
      activityCheck,
      specialityCheck,
      languageCheck,
      selectedType,
      selectedActivities,
      selectedSpecialities,
      selectedLanguages,
    } = this.state;

    const {
      expandableColumnClassName,
      expandableButtonClassName,
      iconClassName,
    } = this.classNameGenerator(expanded);
    const expandedFiltersActive = this.expandedFiltersCounter();
    const selectedSpecialitiesLength = selectedSpecialities.filter((specialty) => specialty !== 'Any').length;
    const daysCount = this.getDayCount();
    return (
      <Form onSubmit={handleSubmit(this.onSearchButtonClick.bind(this))} className="search-wrapper">
        <div className="skic-search">
          <div className="skic-search__section">
            <div className="skic-search__column skic-search__column__datepickers">
              <div className="skic-icon skic-icon__date" />
              <div className="skic-search__column__datepickers__datefields">
                <Field
                  name="timeFrom"
                  component={DateField}
                  placeholder={i18n.t('search.start_date')}
                  value={startDate}
                  initialDate={moment()}
                  minDate={moment()}
                  maxDate={moment().add(365, 'days')}
                  changeDate={this.changeDate}
                  daysCount={daysCount}
                  startDate={startDate}
                  endDate={endDate}
                />
                <div>-</div>
                <Field
                  name="timeTo"
                  component={DateField}
                  placeholder={i18n.t('search.end_date')}
                  value={endDate}
                  initialDate={moment().add(1, 'month').set({
                    hour: 0,
                    minute: 0,
                    second: 0,
                  })}
                  minDate={moment()}
                  maxDate={moment().add(365, 'days')}
                  changeDate={this.changeDate}
                  daysCount={daysCount}
                  startDate={startDate}
                  endDate={endDate}
                />
              </div>
            </div>
            <div className="skic-search__column skic-search__column__resort">
              <div className="skic-icon skic-icon__location" />
              <ResortsSelection
                searchable
                name="resorts"
                selectedResort={selectedResort}
                handleSelectedResort={this.handleSelectedResort}
              />
            </div>
            <div className="skic-search__column">
              {!(selectedActivities.length === 0)
                ? (
                  <div className="filter-item-indicator">
                    <div className="filter-item-indicator__number">
                      {selectedActivities.length}
                    </div>
                  </div>
                )
                : <div className="skic-icon skic-icon__activity" />}
              <Dropdown
                text={i18n.t('search.activity')}
                multiple
                closeOnBlur
                onOpen={handleOpen}
                onClose={handleClose}
                upward={false}
              >
                <Dropdown.Menu>
                  {map(activitiesNames, (name) => (
                    <Dropdown.Item
                      onClick={(e) => e.stopPropagation()}
                      key={`activity-${name}`}
                    >
                      <Checkbox
                        name={`activity-${name}`}
                        label={{ children: i18n.t(`api_activity.${name}`) }}
                        onChange={() => this.handleSelection(name)}
                        disabled={this.checkActivitiesDisablement(name)}
                        checked={activityCheck[name]}
                      />
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className={expandableColumnClassName}>
              {!(selectedSpecialitiesLength === 0)
                ? (
                  <div className="filter-item-indicator">
                    <div className="filter-item-indicator__number">
                      {selectedSpecialitiesLength}
                    </div>
                  </div>
                )
                : <div className="skic-icon skic-icon__flag" />}
              <Dropdown
                text={i18n.t('search.speciality')}
                multiple
                closeOnBlur
                onOpen={handleOpen}
                onClose={handleClose}
                upward={false}
              >
                <Dropdown.Menu>
                  {map(specialitiesNames, (name) => (
                    <Dropdown.Item
                      key={`uniqSpeciality-${name}`}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <Checkbox
                        label={{ children: i18n.t(`api_speciality.${name}`) }}
                        onChange={() => this.handleSelection(undefined, name)}
                        disabled={this.checkSpecialitiesDisablement(name)}
                        checked={specialityCheck[name]}
                      />
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className={expandableColumnClassName}>
              {!(selectedLanguages.length === 0)
                ? (
                  <div className="filter-item-indicator">
                    <div className="filter-item-indicator__number">
                      {selectedLanguages.length}
                    </div>
                  </div>
                )
                : <div className="skic-icon skic-icon__language" />}
              <Dropdown
                text={i18n.t('search.language')}
                multiple
                closeOnBlur
                onOpen={handleOpen}
                onClose={handleClose}
                upward={false}
              >
                <Dropdown.Menu>
                  {map(languagesArray, (language) => (
                    <Dropdown.Item
                      key={`language-${language}`}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <Checkbox
                        label={{ children: languagesList.getLanguageName(language) }}
                        onChange={() => this.addLanguagesToPayload(language)}
                        checked={languageCheck[language]}
                      />
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className={expandableColumnClassName}>
              {selectedType !== 'any'
                ? (
                  <div className="filter-item-indicator">
                    <div className="filter-item-indicator__number">
                      1
                    </div>
                  </div>
                )
                : <div className="skic-icon skic-icon__type" />}
              <Dropdown
                text={i18n.t('search.type')}
                multiple
                closeOnBlur
                onOpen={handleOpen}
                onClose={handleClose}
                upward={false}
              >
                <Dropdown.Menu>
                  {map(typeOptions, (option) => (
                    <Dropdown.Item
                      key={`type-${option.value}`}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <Checkbox
                        label={{ children: i18n.t(`api_type.${option.label}`) }}
                        onChange={() => this.addTypeToPayload(option.value)}
                        checked={option.value === selectedType}
                      />
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
              {selectedType === 'group'
                ? (
                  <div className="search-age">
                    <Field
                      component={TextField}
                      label={i18n.t('search.age')}
                      labelClass="search-age__label"
                      name="minAge"
                      type="number"
                      maxLength={2}
                      onChange={(e, val) => this.addAgeToPayload(val || null)}
                    />
                  </div>
                )
                : null}
            </div>
          </div>
          <button
            type="submit"
            className={`${expandableButtonClassName} submit-desktop`}
          >
            <div className="filter-search-button__mobile">
              {filtersSelected > 0
                ? (
                  <div className="filter-search-indicator">
                    {filtersSelected}
                  </div>
                )
                : <div className="skic-icon skic-icon__search" />}
            </div>
            <div className="filter-search-button__desktop skic-icon skic-icon__search" />
            {i18n.t('search.button')}
          </button>
        </div>
        <div
          className="skic-search__additional"
          onClick={this.handleExpand}
        >
          <div className={iconClassName} />
          {i18n.t('search.add_filters')}
          {!expanded && expandedFiltersActive > 0
            ? (
              <div className="expanded-indicator">
                {expandedFiltersActive}
              </div>
            )
            : null}
        </div>
        <div
          className="skic-search__clear"
          onClick={this.handleClear.bind(this)}
        >
          <div className="skic-icon skic-icon__remove" />
          {i18n.t('search.clear_filters')}
        </div>
        <button
          type="submit"
          className={`${expandableButtonClassName} submit-mobile`}
        >
          <div className="filter-search-button__mobile">
            {filtersSelected > 0
              ? (
                <div className="filter-search-indicator">
                  {filtersSelected}
                </div>
              )
              : <div className="skic-icon skic-icon__search" />}
          </div>
          <div className="filter-search-button__desktop skic-icon skic-icon__search" />
          {i18n.t('search.button')}
        </button>
      </Form>
    );
  }
}

const formConfig = {
  form: 'search',
  destroyOnUnmount: false,
};

const mapStateToProps = (state) => ({
  formValues: getFormValues('search')(state),
  filter: state.filter,
  languagesArray: state.languages,
  activities: state.entities.activities,
  specialities: state.entities.specialities,
  activitiesNames: state.entities.activitiesNames,
  specialitiesNames: state.entities.specialitiesNames,
});

const mapDispatchToProps = (dispatch) => ({
  requestCustomLessonsPageAction: bindActionCreators(requestCustomLessonsPage, dispatch),
  setTimeFromDateA: bindActionCreators(setTimeFromDate, dispatch),
  setTimeToDateA: bindActionCreators(setTimeToDate, dispatch),
  fetchSpecialitiesIfNeededA: bindActionCreators(fetchSpecialitiesIfNeeded, dispatch),
  fetchLanguagesIfNeededA: bindActionCreators(fetchLanguagesIfNeeded, dispatch),
  addSelectedSpecialitiesA: bindActionCreators(addSelectedSpecialities, dispatch),
  addSelectedLanguagesA: bindActionCreators(addSelectedLanguages, dispatch),
  addSelectedTypeA: bindActionCreators(addSelectedType, dispatch),
  addSelectedAgeA: bindActionCreators(addSelectedAge, dispatch),
  changeA: bindActionCreators(change, dispatch),
  addSelectedResortA: bindActionCreators(addSelectedResort, dispatch),
});

const speciality = PropTypes.objectOf({
  id: PropTypes.number,
});

const activity = PropTypes.objectOf({
  id: PropTypes.number,
  specialities: PropTypes.arrayOf(speciality),
});

Search.propTypes = {
  formValues: PropTypes.shape({
    timeFrom: PropTypes.oneOfType([
      PropTypes.instanceOf(moment),
      PropTypes.string,
    ]),
    timeTo: PropTypes.oneOfType([
      PropTypes.instanceOf(moment),
      PropTypes.string,
    ]),
    minAge: PropTypes.number,
  }),
  filter: PropTypes.shape({
    activities: PropTypes.arrayOf(activity),
    specialities: PropTypes.arrayOf(PropTypes.number),
    languages: PropTypes.arrayOf(PropTypes.string),
    resort: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    timeFrom: PropTypes.oneOfType([
      PropTypes.instanceOf(moment),
      PropTypes.string,
    ]),
    timeTo: PropTypes.oneOfType([
      PropTypes.instanceOf(moment),
      PropTypes.string,
    ]),
    // eslint-disable-next-line react/forbid-prop-types
    payload: PropTypes.any,
    type: PropTypes.string,
    resorts: PropTypes.arrayOf(PropTypes.number),
  }),
  setVisible: PropTypes.func,
  handleSubmit: PropTypes.func,
  requestCustomLessonsPageAction: PropTypes.func,
  setTimeFromDateA: PropTypes.func,
  setTimeToDateA: PropTypes.func,
  addSelectedLanguagesA: PropTypes.func,
  addSelectedSpecialitiesA: PropTypes.func,
  fetchLanguagesIfNeededA: PropTypes.func,
  fetchSpecialitiesIfNeededA: PropTypes.func,
  addSelectedResortA: PropTypes.func,
  languagesArray: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.string]),
  activities: PropTypes.objectOf(PropTypes.object),
  specialities: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ])),
  activitiesNames: PropTypes.arrayOf(PropTypes.string),
  specialitiesNames: PropTypes.arrayOf(PropTypes.string),
  addSelectedTypeA: PropTypes.func,
  addSelectedAgeA: PropTypes.func,
  changeA: PropTypes.func,
  filterSelectedCount: PropTypes.func,
  handleClose: PropTypes.func,
  handleOpen: PropTypes.func,
  filtersSelected: PropTypes.number,
  account: PropTypes.object, // eslint-disable-line
};

export default connect(mapStateToProps,
  mapDispatchToProps)(reduxForm(formConfig)(Search));
