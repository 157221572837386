import React from 'react';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { clearCheckoutStatus } from '../../redux/actions/checkoutStatusActions';

const CheckoutStatus = ({
  success,
  error,
  history,
  clearCheckoutStatusAction,
}) => {
  const handleButtonClick = () => {
    clearCheckoutStatusAction();
    history.push(`/${error ? 'checkout' : ''}`);
  };

  return (
    <div className="checkout-content">
      <div className="order-review">
        <div className="order-review-status">
          {success
          && (
          <div className="order-review-status__success">
            <div className="skic-icon skic-icon__status-success" />
            <div className="order-review-status__success__title">
              {i18n.t('checkout_status.thank')}
            </div>
          </div>
          )}
          {error
          && (
          <div className="order-review-status__failure">
            <div className="skic-icon skic-icon__status-failure" />
            <div className="order-review-status__failure__title">
              {i18n.t('checkout_status.error')}
            </div>
          </div>
          )}
          {error
          && (
          <div className="order-review-status__message">
            {error}
          </div>
          )}
          {success
          && (
          <div className="order-review-status__message">
            {success}
          </div>
          )}
          <button
            className="order-review-status__redirect-button"
            onClick={() => handleButtonClick()}
            type="button"
          >
            {error ? i18n.t('checkout_status.back') : i18n.t('checkout_status.continue')}
          </button>
        </div>
      </div>
    </div>
  );
};

CheckoutStatus.propTypes = {
  error: PropTypes.string,
  success: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  clearCheckoutStatusAction: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  clearCheckoutStatusAction: bindActionCreators(clearCheckoutStatus, dispatch),
});

export default withRouter(connect(null, mapDispatchToProps)(CheckoutStatus));
