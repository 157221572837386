const krTranslations = {
  buyer: {
    participant: '참가자 #',
    participant_info: '활동 및 전문 분야를 먼저 선택하십시오.',
    select: '고르다...',
    name: '이름*',
    firstname: '이름*',
    enter_name: '당신의 이름을 입력',
    surname: '성',
    enter_surname: '당신의 성을 입력',
    age: '나이*',
  },
  daypicker: {
    no_available_dates: '*강의 선호도와 일치하는 날짜가 없습니다.',
    select_activity: '* 먼저 활동 선택',
    select_speciality: '*전공을 먼저 선택',
    fill_participants: '* 먼저 참가자를 작성하십시오',
  },
  footer: {
    get_in_touch: '연락하기',
    address: '주소',
    phone: '핸드폰',
    email: '이메일 주소',
    map: '위치지도',
  },
  header: {
    checkout: '점검',
  },
  item_extra_content: {
    add_to_cart: '장바구니에 담기',
    product_removed: '제거된 제품',
    product_added: '추가된 제품',
    remove: '제거하다',
    day: '낮',
  },
  lesson: {
    remove: '제거하다',
    prefered_language: '선호하는 언어*',
    activity: '활동*',
    speciality: '전문*',
    participants_info: '참가자 정보:',
    number_of_participants: '참가자 수:',
    max_no_participants: '*최대 참가자 수:',
    calculating: '계산 중...',
  },
  not_found: {
    title: '이런',
    description: '찾고 있는 페이지를 찾을 수 없습니다',
    button: '돌아가기',
  },
  search: {
    resort: '의지',
    add_filters: '추가 필터',
    clear_filters: '필터 지우기',
    button: '검색',
    activity: '활동',
    speciality: '전문',
    start_date: '시작일',
    end_date: '종료일',
    language: '언어',
    type: '유형',
    age: '나이',
  },
  validators: {
    required: '이 필드는 필수 항목입니다.',
    phone: '잘못된 전화',
    email: '잘못된 이메일',
    less: '10보다 작거나 같아야 합니다.',
    more: '10 이상이어야 합니다.',
  },
  schoolinfo: {
    no_info: '정보 없음',
  },
  price: {
    admin_fee: '관리비',
    total: '총:',
  },
  header_desktop: {
    loading: '로드 중...',
  },
  spacees: {
    available_spaces: '사용 가능한 공간:',
  },
  no_participant: {
    disabled: '장애가 있는',
    max: '최대 참가자 수에 도달했습니다.',
    no: '참가자 수',
  },
  checkout_status: {
    thank: '감사합니다',
    error: '문제가 발생했습니다',
    back: '체크아웃으로 돌아가기',
    continue: '계속하다',
  },
  client: {
    details: '세부 정보:',
    title: '제목*',
    mrs: '부인',
    mr: '씨',
    name: '이름*',
    firstname: '이름*',
    enter_name: '당신의 이름을 입력',
    surname: '성*',
    enter_surname: '당신의 성을 입력',
    tel: '전화 번호*',
    enter_tel: '전화번호를 입력하세요',
    next: '다음',
  },
  preview: {
    review: '주문 확인하기',
    participants: '참가자들',
    name: '이름',
    firstname: '이름',
    surname: '성',
    age: '나이',
    exp: '경험치',
    details: '세부 정보:',
    email: '이메일:',
    tel: '전화 번호:',
    back: '뒤',
    pay: '지금 지불하세요',
  },
  error: {
    try_again: '문제가 발생했습니다... 나중에 다시 시도해 주세요.',
  },
  resort_select: {
    resort: '의지',
  },
  item_list: {
    no_results: '결과 없음',
  },
  success: {
    placed: '주문이 성공적으로 완료되었습니다.',
  },
  levels: {
    first_steps: '첫 번째 단계',
    beginner: '초보자',
    intermediate: '중간',
    advanced: '고급의',
  },
  cart: {
    empty: '장바구니가 비어 있습니다.',
  },
  api_activity: {
    Snowboard: '스노보드',
    'Cross-country': '크로스컨트리',
    'Ice Climbing': '빙벽 타기',
    Snowshoes: '눈신',
    Telemark: '텔레마크',
    'Ice Skating': '아이스 스케이팅',
    SKI: '스키',
    Any: '어느',
  },
  api_speciality: {
    Racing: '경마',
    'Ice Hockey': '아이스 하키',
    'Cat skiing': 'Cat skiing',
    Standard: '기준',
    Heliskiing: '헬리스키',
    'Figure skating': '피겨 스케이팅',
    Skating: '스케이트',
    Any: '어느',
    Handicap: '핸디캡',
    Freeride: '무임 승차',
    Guiding: '안내',
    Freestyle: '자유형',
    Classic: '권위 있는',
    Touring: '여행',
  },
  api_type: {
    Any: '어느',
    Private: '개인',
    Group: '그룹',
  },
};

export default krTranslations;
