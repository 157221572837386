import React from 'react';
import PropTypes from 'prop-types';

// Components
import Lesson, { LessonShimmer } from './Lesson';
import Client from './Client';
import Price from '../../../components/Price';

// TODO: Remove
// Actions
import { removeFromCart } from '../../../redux/actions/cartActions';

const Form = ({
  administrationFee,
  account,
  client,
  isLoading,
  isValid,
  lessons,
  setClient,
  setLessons,
  totalPrice,
}) => {
  const onLessonChange = (id, updatedData) => {
    const updated = lessons.map((el) => ({
      ...el,
      ...el.id === id ? { ...updatedData } : {},
    }));
    setLessons(updated);
  };

  const onClientChange = (updatedData) => {
    setClient({ ...client, ...updatedData });
  };

  const onRemove = (id) => {
    window.store.dispatch(removeFromCart(id)); // TODO: Hacked dispatch
    setLessons(lessons.filter((el) => el.id !== id));
  };

  return (
    <div className="skic-checkout">
      <div className="skic-checkout__lesson-block">
        {isLoading ? (
          <LessonShimmer />
        ) : (
          lessons.map((el) => (
            <Lesson
              currency={account.currency}
              key={el.id}
              lesson={el}
              onChange={(updatedData) => onLessonChange(el.id, updatedData)}
              onRemove={() => onRemove(el.id)}
            />
          ))
        )}
      </div>
      <Price
        administrationFee={administrationFee}
        currency={account.currency}
        isLoading={isLoading}
        price={totalPrice}
      />
      <Client
        client={client}
        isValid={isValid}
        onChange={onClientChange}
      />
    </div>
  );
};

Form.propTypes = {
  administrationFee: PropTypes.string,
  account: PropTypes.object, // eslint-disable-line
  client: PropTypes.object, // eslint-disable-line
  isLoading: PropTypes.bool,
  isValid: PropTypes.bool,
  lessons: PropTypes.array, // eslint-disable-line
  setClient: PropTypes.func,
  setLessons: PropTypes.func,
  totalPrice: PropTypes.string,
};

export default Form;
