import moment from 'moment';
import { forEach, find, get } from 'lodash';
import { languagesOptions } from '../options/Languages';
import { levelsOptions } from '../options/Levels';

export const formatTime = (time) => (
  moment.utc(time).format('HH:mm')
);

export const formatDate = (date) => (
  moment(date).format('DD/MMM/YYYY')
);

export const formatLanguages = (languages) => {
  const aLanguages = languages;
  if (languages[0] === null) aLanguages[0] = 'en';
  const uniqLanguages = [...new Set(aLanguages)];
  let languagesReturn = '';

  forEach(uniqLanguages, (lang, key) => {
    const option = find(languagesOptions, { value: lang });
    if (key < uniqLanguages.length - 1) {
      languagesReturn = languagesReturn.concat(option.label, ', ');
    } else {
      languagesReturn = languagesReturn.concat(option.label, '');
    }
  });

  return languagesReturn;
};

export const formatLevel = (level) => {
  const option = find(levelsOptions(), { value: level });
  return get(option, 'label', 'Any');
};

export const formatDateQuery = (date) => {
  if (!date) {
    return '';
  }
  return moment.utc(date).toISOString();
};

export const formatAddress = (address) => {
  const street = get(address, 'street', '');
  const city = get(address, 'city', '');
  const country = get(address, 'country', '');
  const returnVal = street.concat(' ', city);
  if (!street && !city && !country) {
    return 'N/A';
  }
  return returnVal.concat(', ', country);
};

export const formatOption = (option, property) => {
  const formattedOption = get(option, property, option);
  return formattedOption;
};

export const formatLocationAddress = (locationAddress) => (
  locationAddress.replace(' ', '+')
);
