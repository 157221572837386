import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';

// Components
import TextField from './TextField';
import SelectField from './SelectField';

// Utils
import { LESSON_TYPE_INDIVIDUAL, mapItemsToLevelOptions } from '../utils';
import {
  level,
  maxValue,
  minValue,
  required,
} from '../../../utils/validators';

const Buyer = ({
  idx,
  buyer,
  lesson,
  onChange,
  disabled,
}) => (
  <>
    {lesson.type === LESSON_TYPE_INDIVIDUAL ? (
      <SelectField
        onChange={(value) => onChange({ level: value, dates: [] })}
        label={`${i18n.t('buyer.participant')}${idx + 1}*`}
        options={mapItemsToLevelOptions(lesson.items, lesson.activity, lesson.speciality)}
        clearable={false}
        validate={[level]}
        value={buyer.level}
        disabled={!lesson.activity || !lesson.speciality || disabled}
        placeholder={!lesson.speciality ? i18n.t('buyer.participant_info') : i18n.t('buyer.select')}
      />
    ) : (
      <div className="group-client">
        <div className="group-client__input-field-name">
          <TextField
            onChange={(value) => onChange({ name: value })}
            label={i18n.t('buyer.firstname')}
            placeholder={i18n.t('buyer.enter_name')}
            type="text"
            width={7}
            labelClass="checkout-lesson-label"
            validate={[required]}
            value={buyer.name}
            disabled={disabled}
          />
        </div>
        <div className="group-client__input-field-surname">
          <TextField
            onChange={(value) => onChange({ surname: value })}
            label={i18n.t('buyer.surname')}
            placeholder={i18n.t('buyer.enter_surname')}
            type="text"
            width={7}
            labelClass="checkout-lesson-label"
            value={buyer.surname}
            disabled={disabled}
          />
        </div>
        <div className="group-client__input-field-age-narrow">
          <TextField
            onChange={(value) => onChange({ age: value })}
            label={i18n.t('buyer.age')}
            type="number"
            width={2}
            labelClass="checkout-lesson-label"
            validate={[
              required,
              (val) => maxValue(lesson.maxAge)(val),
              (val) => minValue(lesson.minAge)(val),
            ]}
            value={buyer.age}
            disabled={disabled}
          />
        </div>
        <div className="group-client__input-field-age-wide">
          <TextField
            onChange={(value) => onChange({ age: value })}
            label={i18n.t('buyer.age')}
            type="number"
            width={7}
            labelClass="checkout-lesson-label"
            validate={[
              required,
              (val) => maxValue(lesson.maxAge)(val),
              (val) => minValue(lesson.minAge)(val),
            ]}
            value={buyer.age}
            disabled={disabled}
          />
        </div>
      </div>
    )}
  </>
);

Buyer.propTypes = {
  buyer: PropTypes.any, // eslint-disable-line
  idx: PropTypes.number,
  lesson: PropTypes.object, // eslint-disable-line
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

export default Buyer;
