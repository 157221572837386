import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import i18n from 'i18next';

// hooks
import useField from '../../../hooks/field';

const SelectField = ({
  label,
  options,
  className = '',
  clearable,
  disabled,
  onChange,
  placeholder,
  validate,
  value,
}) => {
  const field = useField({
    initialValue: value,
    validate,
    onSelect: onChange,
  });
  const dirty = field.dirty();
  const error = field.error();

  return (
    <div className={`field field__select ${className || 'column'}`}>
      <div
        className={dirty && error
          ? 'checkout-lesson-label checkout-lesson-label--error'
          : 'checkout-lesson-label'}
      >
        {label}
      </div>
      <Select
        {...field.attributes}
        isDisabled={disabled}
        value={options.find((el) => el.value === value) || ''}
        options={options}
        className="Select"
        classNamePrefix="Select"
        isClearable={clearable}
        placeholder={placeholder || i18n.t('buyer.select')}
      />
      {dirty && error && <small className="error">{error}</small>}
    </div>
  );
};

SelectField.propTypes = {
  value: PropTypes.any, // eslint-disable-line
  className: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.object),
  placeholder: PropTypes.string,
  clearable: PropTypes.bool,
  validate: PropTypes.array, // eslint-disable-line
};

export default SelectField;
