import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import csTranslations from '../translations/cs';
import cnTranslations from '../translations/cn';
import deTranslations from '../translations/de';
import esTranslations from '../translations/es';
import fiTranslations from '../translations/fi';
import frTranslations from '../translations/fr';
import grTranslations from '../translations/gr';
import hrTranslations from '../translations/hr';
import huTranslations from '../translations/hu';
import itTranslations from '../translations/it';
import jpTranslations from '../translations/jp';
import krTranslations from '../translations/kr';
import nlTranslations from '../translations/nl';
import noTranslations from '../translations/no';
import plTranslations from '../translations/pl';
import roTranslations from '../translations/ro';
import enTranslations from '../translations/en';
import trTranslations from '../translations/tr';
import skTranslations from '../translations/sk';
import siTranslations from '../translations/si';
import seTranslations from '../translations/se';
import ruTranslations from '../translations/ru';
import rsTranslations from '../translations/rs';
import bgTranslations from '../translations/bg';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    order: ['localStorage', 'querystring', 'cookie', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
    resources: {
      en: { translation: enTranslations },
      de: { translation: deTranslations },
      fr: { translation: frTranslations },
      it: { translation: itTranslations },
      cs: { translation: csTranslations },
      sk: { translation: skTranslations },
      bg: { translation: bgTranslations },
      pl: { translation: plTranslations },
      ru: { translation: ruTranslations },
      ro: { translation: roTranslations },
      sl: { translation: siTranslations },
      es: { translation: esTranslations },
      hr: { translation: hrTranslations },
      nl: { translation: nlTranslations },
      fi: { translation: fiTranslations },
      sv: { translation: seTranslations },
      el: { translation: grTranslations },
      hu: { translation: huTranslations },
      zh: { translation: cnTranslations },
      ja: { translation: jpTranslations },
      sr: { translation: rsTranslations },
      ko: { translation: krTranslations },
      no: { translation: noTranslations },
      tr: { translation: trTranslations },
    },
  });

export default i18n;
