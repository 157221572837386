import React from 'react';
import 'semantic-ui-css/semantic.min.css';
import ItemList from '../Item/ItemList';
import '../../assets/styles/main.scss';

const HomePage = () => (
  <div className="homepage-container">
    <ItemList />
  </div>
);

export default HomePage;
