import React from 'react';
import i18n from 'i18next';
import LanguageSwitcherSelector from './LanguageSwitcherSelector';

class LanguageSwitcher extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lang: i18n.resolvedLanguage,
    };
    // const { lang } = this.state;
    // const x = i18n.changeLanguage(lang);
    // console.log(x);
  }

  // changeLanguageHandler = () => {
  // this.setState({ lang });
  // console.log(i18n.resolvedLanguage);
  // }

  render() {
    const { lang } = this.state;
    return (
      <div className="language-selector">
        <LanguageSwitcherSelector
          lang={lang}
          handleChangeLanguage={() => (
            this.setState({ lang })
          )}
        />
      </div>
    );
  }
}

export default LanguageSwitcher;
