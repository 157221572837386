const ruTranslations = {
  buyer: {
    participant: 'Участник #',
    participant_info: 'Сначала выбирите вид активности и специальность',
    select: 'Выбирите…',
    name: 'Имя*',
    firstname: 'имя*',
    enter_name: 'Введите своё имя',
    surname: 'Фамилия',
    enter_surname: 'Введите свою фамилию',
    age: 'Возраст*',
  },
  daypicker: {
    no_available_dates: '*Нет доступных дат, совпадающих с настройками урока',
    select_activity: '*Сначала выберите вид активности',
    select_speciality: '*Сначала выберите специальность',
    fill_participants: '*Сначала запоните участников',
  },
  footer: {
    get_in_touch: 'СВЯЗАТЬСЯ С НАМИ',
    address: 'Адресс',
    phone: 'Телефон',
    email: 'Электронная почта',
    map: 'Локация',
  },
  header: {
    checkout: 'Оформление заказа',
  },
  item_extra_content: {
    add_to_cart: 'ДОБАВИТЬ В КОРЗИНУ',
    product_removed: 'Продукт убран',
    product_added: 'Продукт добавлен',
    remove: 'УБРАТЬ',
    day: 'день',
  },
  lesson: {
    remove: 'Убрать',
    prefered_language: 'Предпочитаемый язык*',
    activity: 'Вид активности*',
    speciality: 'Специальность*',
    participants_info: 'Информация об участниках',
    number_of_participants: 'Число участников',
    max_no_participants: '*Максимальное число участников',
    calculating: 'Вычисление…',
  },
  not_found: {
    title: 'Упс',
    description: 'Страница, которую вы ищете, не найдена',
    button: 'НАЗАД',
  },
  search: {
    resort: 'Курорт',
    add_filters: 'Дополнительные фильтры',
    clear_filters: 'Очистить фильтры',
    button: 'ПОИСК',
    activity: 'Вид активности',
    speciality: 'Специальность',
    start_date: 'Дата начала',
    end_date: 'Дата окончания',
    language: 'Язык',
    type: 'Тип',
    age: 'Возраст',
  },
  validators: {
    required: 'Это поле обязательно',
    phone: 'Недействительный номер телефона',
    email: 'Недействительный адрес эл. Почты',
    less: 'Должно быть не болше либо равно 10',
    more: 'Должно быть больше либо равно 10',
  },
  schoolinfo: {
    no_info: 'Нет информации',
  },
  price: {
    admin_fee: 'Административный взнос',
    total: 'Всего:',
  },
  header_desktop: {
    loading: 'Загрузка…',
  },
  spacees: {
    available_spaces: 'Доступные помещения',
  },
  no_participant: {
    disabled: 'неактивно',
    max: 'Достигнуто максимальное число участников',
    no: 'Число участников',
  },
  checkout_status: {
    thank: 'СПАСИБО',
    error: 'ЧТО-ТО ПОШЛО НЕ ТАК',
    back: 'НАЗАД К ОФОРМЛЕНИЮ',
    continue: 'ПРОДОЛЖИТЬ',
  },
  client: {
    details: 'Ваши детали:',
    title: 'Обращение*',
    mrs: 'Миссис',
    mr: 'Мистер',
    name: 'Имя*',
    firstname: 'имя*',
    enter_name: 'Введите своё имя',
    surname: 'Фамилия*',
    enter_surname: 'Введите свою фамилию',
    tel: 'Номер телефона*',
    enter_tel: 'Введите свой номер телефона',
    next: 'ДАЛЬШЕ',
  },
  preview: {
    review: 'Просмотреть ваш заказ',
    participants: 'Участники',
    name: 'Имя',
    firstname: 'имя',
    surname: 'Фамилия',
    age: 'Возраст',
    exp: 'Опыт',
    details: 'Ваши детали:',
    email: 'Электронная почта:',
    tel: 'Номер телефона:',
    back: 'НАЗАД',
    pay: 'ОПЛАТИТЬ СЕЙЧАС',
  },
  error: {
    try_again: 'Что-то пошло не так. Попробуйте позже',
  },
  resort_select: {
    resort: 'Курорт',
  },
  item_list: {
    no_results: 'Нет результатов',
  },
  success: {
    placed: 'Ваш заказ успешно размещён',
  },
  levels: {
    first_steps: 'первые шаги',
    beginner: 'начинающий',
    intermediate: 'средний',
    advanced: 'продвинутый',
  },
  cart: {
    empty: 'Корзина пуста',
  },
  api_activity: {
    Snowboard: 'Сноуборд',
    'Cross-country': 'Кросс-кантри',
    'Ice Climbing': 'Ледяной альпинизм',
    Snowshoes: 'Снегоступы',
    Telemark: 'Телемарк',
    'Ice Skating': 'Коньки',
    SKI: 'ЛЫЖИ',
    Any: 'Любой',
  },
  api_speciality: {
    Racing: 'Гонки',
    'Ice Hockey': 'Хоккей',
    'Cat skiing': 'Cat skiing',
    Standard: 'Стандартный',
    Heliskiing: 'Хелиски',
    'Figure skating': 'Фигурное катание',
    Skating: 'Катание на коньках',
    Any: 'Любой',
    Handicap: 'Сидячие лыжи',
    Freeride: 'Фрирайд',
    Guiding: 'Руководство по катанию',
    Freestyle: 'Фристайл',
    Classic: 'Классический',
    Touring: 'Туринг',
  },
  api_type: {
    Any: 'Любой',
    Private: 'индивидуальное',
    Group: 'групповое',
  },
};

export default ruTranslations;
