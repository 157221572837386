const chTranslations = {
  buyer: {
    participant: '参与者 #',
    participant_info: '先选择活动和特长',
    select: '选择',
    name: '名字*',
    firstname: '名*',
    enter_name: '输入你的名字',
    surname: '姓*',
    enter_surname: '输入你的姓',
    age: '岁数',
  },
  daypicker: {
    no_available_dates: '没有与课程偏好匹配的可用日期',
    select_activity: '先选择活动',
    select_speciality: '先选择特长',
    fill_participants: '先输入参与者',
  },
  footer: {
    get_in_touch: '取得联系',
    address: '地址',
    phone: '电话号码',
    email: '电子邮电地址',
    map: '位置图',
  },
  header: {
    checkout: '结帐',
  },
  item_extra_content: {
    add_to_cart: '加入购物车',
    product_removed: '已移除产品',
    product_added: '添加产品',
    remove: '删除',
    day: '日子',
  },
  lesson: {
    remove: '删除',
    prefered_language: '首先语言',
    activity: '活动',
    speciality: '特张',
    participants_info: '参与者信息',
    number_of_participants: '参加人数',
    max_no_participants: '参加人数上限',
    calculating: '计算…',
  },
  not_found: {
    title: '哎呀',
    description: '找不到您要查找的网页',
    button: '退回',
  },
  search: {
    resort: '度假胜地',
    add_filters: '附加过滤器',
    clear_filters: '删除过滤器',
    button: '搜索',
    activity: '活动',
    speciality: '特张',
    start_date: '开始日期',
    end_date: '结束日期',
    language: '语言',
    type: '类型',
    age: '岁数',
  },
  validators: {
    required: '这是必填栏',
    phone: '无效的电话号码',
    email: '无效的电子邮件',
    less: '必须等于或小于 10',
    more: '必须等于或大于 10',
  },
  schoolinfo: {
    no_info: '没有信息',
  },
  price: {
    admin_fee: '管理费',
    total: '总和',
  },
  header_desktop: {
    loading: '载入中...',
  },
  spacees: {
    available_spaces: '可用空间',
  },
  no_participant: {
    disabled: '残疾人',
    max: '参加人数上限低到了',
    no: '参加人数',
  },
  checkout_status: {
    thank: '谢谢您',
    error: '发现错误',
    back: '退回查看',
    continue: '继续',
  },
  client: {
    details: '您的信息',
    title: '称号',
    mrs: '女士',
    mr: '先生',
    name: '名字',
    firstname: '名*',
    enter_name: '输入您的名字',
    surname: '姓',
    enter_surname: '输入您的名字',
    tel: '电话号码',
    enter_tel: '输入您的电话号码',
    next: '下',
  },
  preview: {
    review: '查看您的订单',
    participants: '参与者',
    firstname: '名',
    name: '名字',
    surname: '姓',
    age: '岁数',
    exp: '经验水平',
    details: '您的信息',
    email: '电子邮电地址',
    tel: '电话号码',
    back: '退回',
    pay: '付账',
  },
  error: {
    try_again: '发生错误。。。请稍后再试',
  },
  resort_select: {
    resort: '度假胜地',
  },
  item_list: {
    no_results: '没有结果',
  },
  success: {
    placed: '您的订单已成功下单',
  },
  levels: {
    first_steps: '第一个',
    beginner: '低级',
    intermediate: '中级',
    advanced: '高级',
  },
  cart: {
    empty: '购物车是空的',
  },
  api_activity: {
    Snowboard: '滑雪板',
    'Cross-country': '越野',
    'Ice Climbing': '冰攀',
    Snowshoes: '雪鞋',
    Telemark: '电话营销',
    'Ice Skating': '溜冰',
    SKI: '滑雪板',
    Any: '任何',
  },
  api_speciality: {
    Racing: '赛车跑',
    'Ice Hockey': '冰球',
    'Cat skiing': 'Cat skiing',
    Standard: '标准',
    Heliskiing: '直升机滑雪',
    'Figure skating': '花样滑冰',
    Skating: '溜冰',
    Any: '任何',
    Handicap: '障碍',
    Freeride: 'Freeride',
    Guiding: '指导',
    Freestyle: '自由泳',
    Classic: '经典',
    Touring: '游览',
  },
  api_type: {
    Any: '任何',
    Private: '个人',
    Group: '团体',
  },
};

export default chTranslations;
