import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import i18n from 'i18next';
import Linkify from 'react-linkify';

import Search from '../../containers/Filter/Search';
import LanguageSwitcher from '../Field/LanguageSelector';

const Header = ({
  account,
  cart,
  handleOpen,
  handleClose,
}) => {
  const { push } = useHistory();
  const { pathname } = useLocation();

  return (
    <div className="app-header">
      <div className="app-header-desktop">
        <div className="app-header-desktop__options">
          <div
            className="app-header-desktop__options__title"
            onClick={() => push('/')}
          >
            {account.name}
          </div>

          <LanguageSwitcher />

          {!(pathname === '/checkout' || pathname === '/success')
          && (
            <button
              className="app-header-desktop__options__checkout-button"
              onClick={() => push('/checkout')}
              disabled={!cart.cartItems.length}
              type="button"
            >
              {cart.cartItems.length
                ? (
                  <div className="app-header-desktop__options__checkout-button__quantity">
                    {cart.cartItems.length}
                  </div>
                )
                : <div className="skic-icon skic-icon__basket" />}
              {i18n.t('header.checkout')}
            </button>
          )}
        </div>
        <div className="app-header-desktop__description">
          <div className="app-header-desktop__description__text-container">
            <div className="app-header-desktop__description__text-container__text">
              <Linkify>{account.description}</Linkify>
            </div>
            <div className="ski-picture" />
            <div className="ski-picture-small" />
          </div>
        </div>
      </div>
      <div className="app-header-mobile">
        <div className="app-header-mobile__content">
          <div
            className="app-header-mobile__content__title"
            onClick={() => push('/')}
          >
            {account.name || 'Loading...'}
          </div>
          <div className="ski-picture" />
          <div className="ski-picture-small" />
        </div>
        <div className="app-header-mobile__description">
          {account.description}
        </div>
      </div>
      {pathname === '/'
        ? (
          <div className="filter-container">
            <Search
              handleClose={handleClose}
              handleOpen={handleOpen}
            />
          </div>
        )
        : null}
    </div>
  );
};

const mapStateToProps = (state) => ({
  cart: state.cart,
});

Header.propTypes = {
  account: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
  }),
  cart: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    cartItems: PropTypes.array,
  }),
  handleClose: PropTypes.func,
  handleOpen: PropTypes.func,
};

export default connect(mapStateToProps)(Header);
