import React from 'react';
import PropTypes from 'prop-types';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import i18n from 'i18next';

// Utils
import { LESSON_TYPE_INDIVIDUAL } from '../utils';

const Picker = ({
  onDayClick,
  disabledDays,
  lesson,
  availableDates,
  disabled,
}) => {
  const disabledAll = disabled
    || (lesson.type === LESSON_TYPE_INDIVIDUAL
      && (!lesson.activity || !lesson.speciality || lesson.buyers.find((el) => !el.level)));

  return (
    <div className="skic-checkout__lesson-block__item__callendar-container">
      <DayPicker
        selectedDays={lesson.dates.map((el) => new Date(el))}
        onDayClick={onDayClick}
        disabledDays={disabledAll ? [new Date(), { before: new Date(), after: new Date() }] : disabledDays} // eslint-disable-line
      />

      {lesson.type === LESSON_TYPE_INDIVIDUAL && (
        <div className="calendar-container-bullets">
          <div className={!disabledAll && !availableDates.length ? 'bullet' : 'bullet bullet--hidden'}>
            {i18n.t('daypicker.no_available_dates')}
          </div>
          <div className={lesson.activity ? 'bullet bullet--hidden' : 'bullet'}>
            {i18n.t('daypicker.select_activity')}
          </div>
          <div className={lesson.speciality ? 'bullet bullet--hidden' : 'bullet'}>
            {i18n.t('daypicker.select_speciality')}
          </div>
          <div className={lesson.buyers.find((el) => !el.level) ? 'bullet' : 'bullet bullet--hidden'}>
            {i18n.t('daypicker.fill_participants')}
          </div>
        </div>
      )}
    </div>
  );
};

Picker.propTypes = {
  disabled: PropTypes.bool,
  availableDates: PropTypes.array, // eslint-disable-line
  onDayClick: PropTypes.func,
  lesson: PropTypes.object, // eslint-disable-line
  disabledDays: PropTypes.arrayOf(PropTypes.object),
};

export default Picker;
