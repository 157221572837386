import React from 'react';
import PropTypes from 'prop-types';
import IconLabel from './IconLabel';

const ResortDisplay = ({ resortName }) => (
  <div>
    <IconLabel icon="location">
      <span>
        {resortName}
      </span>
    </IconLabel>
  </div>
);

ResortDisplay.propTypes = {
  resortName: PropTypes.string.isRequired,
};

export default ResortDisplay;
