import React from 'react';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

// Components
import SelectField from './SelectField';
import TextField from './TextField';
import PhoneField from './PhoneField';

// Utils
import { email, required } from '../../../utils/validators';

const Client = ({ isValid, onChange, client }) => (
  <div className="checkout-form-personal-info">
    <div className="checkout-form-personal-info__title">
      {i18n.t('client.details')}
    </div>
    <div className="checkout-form-personal-info__form">
      <div className="form-row">
        <div className="form-row__form-field">
          <SelectField
            label={i18n.t('client.title')}
            options={[
              { value: 'female', label: i18n.t('client.mrs') },
              { value: 'male', label: i18n.t('client.mr') },
            ]}
            onChange={(value) => onChange({ gender: value })}
            validate={[required]}
            value={client.gender}
          />
        </div>
        <div className="empty-div" />
      </div>
      <div className="form-row">
        <div className="form-row__form-field">
          <TextField
            label={i18n.t('client.firstname')}
            placeholder={i18n.t('client.enter_name')}
            type="text"
            onChange={(value) => onChange({ name: value })}
            labelClass="checkout-lesson-label"
            validate={[required]}
            value={client.name}
          />
        </div>
        <div className="form-row__form-field">
          <TextField
            label={i18n.t('client.surname')}
            placeholder={i18n.t('client.enter_surname')}
            type="text"
            onChange={(value) => onChange({ surname: value })}
            labelClass="checkout-lesson-label"
            validate={[required]}
            value={client.surname}
          />
        </div>
      </div>
      <div className="form-row">
        <div className="form-row__form-field">
          <TextField
            label="Email*"
            placeholder="Enter your email"
            type="text"
            onChange={(value) => onChange({ email: value })}
            labelClass="checkout-lesson-label"
            validate={[required, email]}
            value={client.email}
          />
        </div>
        <div className="form-row__form-field">
          <PhoneField
            label={i18n.t('client.tel')}
            labelClassName="client-manager-form__label"
            placeholder={i18n.t('client.enter_tel')}
            onChange={(value) => onChange({ phoneNumber: value })}
            validate={[required]}
            value={client.phoneNumber}
          />
        </div>
      </div>
      <div className="button-container">
        <NavLink
          className={isValid ? 'button-container__button' : 'button-container__button button-container__button--disabled'}
          onClick={(e) => !isValid && e.preventDefault()}
          to="/checkout/preview"
        >
          {i18n.t('client.next')}
        </NavLink>
      </div>
    </div>
  </div>
);

Client.propTypes = {
  client: PropTypes.object, // eslint-disable-line
  isValid: PropTypes.bool,
  onChange: PropTypes.func,
};

export default Client;
