import React from 'react';
import { PropTypes } from 'prop-types';
import { get } from 'lodash';
import IconLabel from './IconLabel';

const ActivitySpeciality = ({ activityName, speciality }) => (
  <div>
    <IconLabel
      icon="activity"
    >
      <span>
        {activityName}
        {' '}
        -
        {' '}
        {get(speciality, 'name', 'Any')}
      </span>
    </IconLabel>
  </div>
);

ActivitySpeciality.propTypes = {
  activityName: PropTypes.string,
  speciality: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
  }),
};

export default ActivitySpeciality;
