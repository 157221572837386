import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import classNames from 'classnames';
import Notifications, { removeAll } from 'react-notification-system-redux';

class Notification extends Component {
  componentWillUnmount() {
    const { removeAllA } = this.props;
    removeAllA();
  }

  render() {
    const { notifications } = this.props;

    const className = classNames(
      'notifications-container',
      {
        'visible-container': !(notifications.length === 0),
      },
    );

    return (
      <div className={className}>
        <Notifications notifications={notifications} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  notifications: state.notifications,
});

const mapDispatchToProps = (dispatch) => ({
  removeAllA: bindActionCreators(removeAll, dispatch),
});

Notification.propTypes = {
  notifications: PropTypes.arrayOf(PropTypes.object),
  removeAllA: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
