import React, { Component } from 'react';
import i18n from 'i18next';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import Select from 'react-select';
import { map } from 'lodash';
import { fetchResorts } from '../../redux/actions/resortsActions';

const DropdownIndicator = () => (
  <i aria-hidden="true" className="dropdown icon" />
);

class ResortsSelection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resorts: [],
      options: [],
    };
  }

  componentDidMount() {
    this.getResorts();
  }

  getResorts() {
    const { fetchResortsA } = this.props;
    return fetchResortsA().then((response) => {
      const { payload: resorts } = response;
      const fixedResorts = map(resorts, (resort) => ({ value: resort.id, label: resort.name }));
      const options = fixedResorts.length > 10 ? fixedResorts.slice(0, 10) : [...fixedResorts];
      this.setState({ resorts: fixedResorts, options });
    });
  }

  handleResortsFilterChange = (input) => {
    const { resorts } = this.state;
    const options = [];
    for (let i = 0; i < resorts.length; i += 1) {
      const inputValue = input === undefined ? '' : input;
      if (resorts[i].label.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase())) {
        const { value, label } = resorts[i];
        options.push({ value, label });
        if (options.length === 10) {
          break;
        }
      }
    }
    this.setState({ options });
  };

  handleFocus = () => {
    const { selectedResort: { value, label } } = this.props;
    if (value !== null) {
      this.handleResortsFilterChange(label);
    }
  };

  handleChange = (resort) => {
    const { handleSelectedResort } = this.props;
    if (resort !== null) {
      this.handleResortsFilterChange(resort.label);
    }
    handleSelectedResort(resort);
  };

  render() {
    const { selectedResort } = this.props;
    const { options } = this.state;
    return (
      <Select
        placeholder={i18n.t('resort_select.resort')}
        value={selectedResort}
        onChange={(resort) => this.handleChange(resort)}
        options={options}
        onInputChange={(input) => this.handleResortsFilterChange(input)}
        onFocus={() => this.handleFocus()}
        className="Select"
        classNamePrefix="Select"
        components={{ DropdownIndicator }}
        isClearable
      />
    );
  }
}

ResortsSelection.propTypes = {
  fetchResortsA: PropTypes.func,
  handleSelectedResort: PropTypes.func,
  selectedResort: PropTypes.shape({
    value: PropTypes.number,
    label: PropTypes.string,
  }),
};

const mapDispatchToProps = (dispatch) => ({
  fetchResortsA: bindActionCreators(fetchResorts, dispatch),
});

export default connect(null, mapDispatchToProps)(ResortsSelection);
