import { combineReducers } from 'redux';
import { reducer as notifications } from 'react-notification-system-redux';
import { lessonsPaginator } from '../../paginators/paginators';
import cart from './cartReducer';
import lesson from './lessonReducer';
import account from './accountReducer';
import order from './orderReducer';
import resorts from './resortsReducer';
import activities from './activitiesReducer';
import specialities from './specialitiesRecuder';
import entities from './entitiesReducer';
import filter from './filterReducer';
import languages from './languagesReducer';
import buyers from './checkoutReducer';
import formReducer from './formReducer';
import checkoutStatus from './checkoutStatusReducer';

const reducers = combineReducers({
  account,
  activities,
  specialities,
  buyers,
  cart,
  filter,
  form: formReducer,
  languages,
  lesson,
  notifications,
  order,
  pagination: lessonsPaginator.reducers,
  resorts,
  entities,
  checkoutStatus,
});

export default reducers;
