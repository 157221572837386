import i18n from 'i18next';

// eslint-disable-next-line import/prefer-default-export
export const levelsOptions = () => ([
  {
    value: 'first_steps',
    label: i18n.t('levels.first_steps'),
    weight: 1,
  },
  {
    value: 'beginner',
    label: i18n.t('levels.beginner'),
    weight: 2,
  },
  {
    value: 'intermediate',
    label: i18n.t('levels.intermediate'),
    weight: 3,
  },
  {
    value: 'advanced',
    label: i18n.t('levels.advanced'),
    weight: 4,
  },
]);
