import React from 'react';
import i18n from 'i18next';
import PropTypes from 'prop-types';

import { formatAddress, formatLocationAddress } from '../../utils/format';

const { API_KEY } = process.env;

const Footer = ({ account }) => {
  const {
    description,
    address,
    phone,
    email,
  } = account;
  const locationUrl = address ? `https://www.google.com/maps/embed/v1/search?q=${formatLocationAddress(address.street)},+${formatLocationAddress(address.city)},+${formatLocationAddress(address.country)}&key=${API_KEY}` : null;

  return (
    <div className="app-footer">
      <div className="app-footer__content-container">
        <div className="app-footer__content-container__title">{i18n.t('footer.get_in_touch')}</div>
        {description
          && (
          <div className="app-footer__content-container__desc">
            {account.description}
          </div>
          )}
        <div className="app-footer__content-container__items">
          {address
            && (
            <div className="app-footer__content-container__items__item">
              <div className="app-footer__content-container__items__item__title">{i18n.t('footer.address')}</div>
              <div className="app-footer__content-container__items__item__content">
                {formatAddress(account.address)}
              </div>
            </div>
            )}
          {phone
            && (
            <div className="app-footer__content-container__items__item">
              <div className="app-footer__content-container__items__item__title">{i18n.t('footer.phone')}</div>
              <div className="app-footer__content-container__items__item__content">
                <a href={`tel:${account.phone}`}>{account.phone}</a>
              </div>
            </div>
            )}
          {email
            && (
            <div className="app-footer__content-container__items__item">
              <div className="app-footer__content-container__items__item__title">{i18n.t('footer.email')}</div>
              <div className="app-footer__content-container__items__item__content">
                <a href={`mailto:${account.email}`}>{account.email}</a>
              </div>
            </div>
            )}
        </div>
      </div>
      <div className="app-footer__map-container">
        {locationUrl
          && (
          <iframe
            allowFullScreen
            frameBorder="0"
            title={i18n.t('footer.map')}
            src={locationUrl}
          />
          )}
      </div>
    </div>
  );
};

Footer.propTypes = {
  account: PropTypes.shape({
    phone: PropTypes.string,
    email: PropTypes.string,
    address: PropTypes.shape({
      city: PropTypes.string,
      country: PropTypes.string,
      street: PropTypes.string,
    }),
    description: PropTypes.string,
  }),
};

export default Footer;
