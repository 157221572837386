import React from 'react';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CheckoutStatus from '../../components/CheckoutStatus/CheckoutStatus';

const Error = ({ error }) => (
  <CheckoutStatus error={error || i18n.t('error.try_again')} />
);

Error.propTypes = {
  error: PropTypes.string,
};

const mapStateToProps = (state) => ({
  error: state.checkoutStatus.error,
});

export default connect(mapStateToProps)(Error);
