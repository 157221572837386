import fetch from 'cross-fetch';
import { get } from 'lodash';
import * as c from '../../constants/Api';
import { api, subdomain } from '../../config';

const receiveLanguages = (json) => ({
  type: c.API_LANGUAGES_SUCCESS,
  payload: json,
});

export const addSelectedLanguages = (json) => ({
  type: c.SELECT_LANGUAGES_SUCCESS,
  payload: json,
});

export const fetchLanguages = (endpoint) => (
  (dispatch) => (
    fetch(endpoint)
      .then((res) => res.json())
      .then((json) => dispatch(receiveLanguages(json)))
  )
);

const shouldFetchLanguages = (state) => (
  get(state, 'languages', true)
);

export const fetchLanguagesIfNeeded = () => {
  const endpoint = `${api}/languages/${subdomain}`;
  return (dispatch, getState) => {
    if (shouldFetchLanguages(getState())) {
      return dispatch(fetchLanguages(endpoint));
    }
    return Promise.resolve();
  };
};
