import React from 'react';
import { PropTypes } from 'prop-types';
import { formatTime } from '../../utils/format';
import IconLabel from './IconLabel';

const TimeDisplay = ({ timeFrom, timeTo }) => (
  <div>
    <IconLabel icon="time">
      <span>
        {formatTime(timeFrom)}
        {' '}
        -
        {formatTime(timeTo)}
      </span>
    </IconLabel>
  </div>
);

TimeDisplay.propTypes = {
  timeFrom: PropTypes.string.isRequired,
  timeTo: PropTypes.string.isRequired,
};

export default TimeDisplay;
