import * as c from '../../constants/Api';

const languages = (state = {}, action) => {
  switch (action.type) {
    case c.API_LANGUAGES_SUCCESS: {
      return action.payload.languages;
    }
    default:
      return state;
  }
};

export default languages;
