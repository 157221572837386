const grTranslations = {
  buyer: {
    participant: 'Συμμετέχοντας #',
    participant_info: 'Επιλέξτε πρώτα δραστηριότητα και ειδικότητα',
    select: 'Επιλέξτε…',
    name: 'Όνομα*',
    firstname: 'Ονομα*',
    enter_name: 'Εισάγετε το όνομά σας',
    surname: 'Επώνυμο',
    enter_surname: 'Εισάγετε το επώνυμό σας',
    age: 'Ηλικία*',
  },
  daypicker: {
    no_available_dates: '*Δεν υπάρχουν διαθέσιμες ημερομηνίες που να ταιριάζουν με τις προτιμήσεις των μαθημάτων',
    select_activity: '*Επιλέξτε πρώτα δραστηριότητα',
    select_speciality: '*Επιλέξτε πρώτα ειδικότητα',
    fill_participants: '*Συμπληρώστε πρώτα τους συμμετέχοντες',
  },
  footer: {
    get_in_touch: 'ΕΠΙΚΟΙΝΩΝΙΑ',
    address: 'Διεύθυνση',
    phone: 'Τηλέφωνο',
    email: 'Διεύθυνση ηλεκτρονικού ταχυδρομείου',
    map: 'Χάρτης τοποθεσίας',
  },
  header: {
    checkout: 'Ολοκλήρωση αγοράς',
  },
  item_extra_content: {
    add_to_cart: 'Προσθήκη στο καλάθι',
    product_removed: 'Το προϊόν αφαιρέθηκε',
    product_added: 'Το προϊόν προστέθηκε',
    remove: 'ΑΦΑΙΡΕΣΤΕ',
    day: 'ημέρα',
  },
  lesson: {
    remove: 'Αφαιρέστε',
    prefered_language: 'Προτιμώμενη γλώσσα*',
    activity: 'Δραστηριότητα*',
    speciality: 'Ειδικότητα*',
    participants_info: 'Πληροφορίες συμμετεχόντων:',
    number_of_participants: 'Αριθμός συμμετεχόντων:',
    max_no_participants: '*Μέγιστος αριθμός συμμετεχόντων:',
    calculating: 'Υπολογισμός…',
  },
  not_found: {
    title: 'Ουπς',
    description: 'Η σελίδα που ψάχνετε δε μπορεί να βρεθεί',
    button: 'ΠΗΓΑΙΝΕΤΕ ΠΙΣΩ',
  },
  search: {
    resort: 'Ταξινόμηση εκ νέου',
    add_filters: 'Πρόσθετα φίλτρα',
    clear_filters: 'Καθαρισμός φίλτρων',
    button: 'ΑΝΑΖΗΤΗΣΗ',
    activity: 'Δραστηριότητα',
    speciality: 'Ειδικότητα',
    start_date: 'Ημερομηνία έναρξης',
    end_date: 'Ημερομηνία λήξης',
    language: 'Γλώσσα',
    type: 'Τύπος',
    age: 'Ηλικία',
  },
  validators: {
    required: 'Αυτό το πεδίο είναι υποχρεωτικό',
    phone: 'Μη έγκυρο τηλέφωνο',
    email: 'Μη έγκυρο email',
    less: 'Πρέπει να είναι ίσα ή λιγότερα από 10',
    more: 'Πρέπει να είναι ίσα ή περισσότερα από 10',
  },
  schoolinfo: {
    no_info: 'Καμία πληροφορία',
  },
  price: {
    admin_fee: 'Διοικητικό τέλος',
    total: 'Σύνολο:',
  },
  header_desktop: {
    loading: 'Φόρτωση…',
  },
  spacees: {
    available_spaces: 'Διαθέσιμοι χώροι:',
  },
  no_participant: {
    disabled: 'ανίκανο',
    max: 'Ο μέγιστος αριθμός συμμετεχόντων επιτεύχθηκε',
    no: 'Αριθμός συμμετεχόντων',
  },
  checkout_status: {
    thank: 'ΕΥΧΑΡΙΣΤΟΥΜΕ',
    error: 'ΚΑΤΙ ΠΗΓΕ ΛΑΘΟΣ',
    back: 'ΠΙΣΩ ΣΤΗΝ ΟΛΟΚΛΗΡΩΣΗ ΑΓΟΡΑΣ',
    continue: 'ΣΥΝΕΧΙΣΤΕ',
  },
  client: {
    details: 'Οι λεπτομέρειές σας:',
    title: 'Τίτλος*',
    mrs: 'Κυρία',
    mr: 'Κύριος',
    name: 'Όνομα*',
    firstname: 'Ονομα*',
    enter_name: 'Εισάγετε το όνομά σας',
    surname: 'Επώνυμο*',
    enter_surname: 'Εισάγετε το επώνυμό σας',
    tel: 'Αριθμός τηλεφώνου*',
    enter_tel: 'Εισάγετε τον αριθμό τηλεφώνου σας',
    next: 'ΕΠΟΜΕΝΟ',
  },
  preview: {
    review: 'Ελέγξτε την παραγγελία σας',
    participants: 'Συμμετέχοντες',
    name: 'Όνομα',
    firstname: 'Ονομα',
    surname: 'Επώνυμο',
    age: 'Ηλικία',
    exp: 'Επίπεδο εμπειρίας',
    details: 'Οι λεπτομέρειές σας:',
    email: 'Διεύθυνση ηλεκτρονικού ταχυδρομείου:',
    tel: 'Αριθμός τηλεφώνου:',
    back: 'ΠΙΣΩ',
    pay: 'ΠΛΗΡΩΣΤΕ ΤΩΡΑ',
  },
  error: {
    try_again: 'Κάτι πήγε λάθος… Παρακαλώ προσπαθήστε πάλι αργότερα',
  },
  resort_select: {
    resort: 'Ταξινόμηση εκ νέου',
  },
  item_list: {
    no_results: 'Κανένα αποτέλεσμα',
  },
  success: {
    placed: 'Η παραγγελία σας έγινε επιτυχώς',
  },
  levels: {
    first_steps: 'πρώτα βήματα',
    beginner: 'αρχάριος',
    intermediate: 'ενδιάμεσος',
    advanced: 'προχωρημένος',
  },
  cart: {
    empty: 'Το καλάθι είναι άδειο',
  },
  api_activity: {
    Snowboard: 'Snowboard',
    'Cross-country': 'Cross-country',
    'Ice Climbing': 'Αναρρίχηση σε πάγο',
    Snowshoes: 'Snowshoes',
    Telemark: 'Τέλεμαρκ σκι',
    'Ice Skating': 'Παγοδρομία',
    SKI: 'ΣΚΙ',
    Any: 'Οποιοδήποτε',
  },
  api_speciality: {
    Racing: 'Αγώνας',
    'Ice Hockey': 'Χόκεϊ επί πάγου',
    'Cat skiing': 'Cat skiing',
    Standard: 'Σταθερό',
    Heliskiing: 'Heliskiing',
    'Figure skating': 'Καλλιτεχνική παγοδρομία',
    Skating: 'Skating',
    Any: 'Οποιοδήποτε',
    Handicap: 'Handicap',
    Freeride: 'Freeride',
    Guiding: 'Guiding',
    Freestyle: 'Ελεύθερο',
    Classic: 'Κλασικό',
    Touring: 'Touring',
  },
  api_type: {
    Any: 'Οποιοδήποτε',
    Private: 'ατομικό',
    Group: 'ομαδικό',
  },
};

export default grTranslations;
