const itTranslations = {
  buyer: {
    participant: 'Partecipante n. ',
    participant_info: 'Prima seleziona l\'attività e la specialità',
    select: 'Seleziona…',
    name: 'Nome*',
    firstname: 'Nome di battesimo*',
    enter_name: 'Scrivi il tuo nome',
    surname: 'Cognome*',
    enter_surname: 'Scrivi il tuo cognome',
    age: 'Età*',
  },
  daypicker: {
    no_available_dates: '*Nessuna data disponibile corrispondente alle preferenze per la lezione',
    select_activity: '*Prima seleziona l\'attività',
    select_speciality: '*Prima seleziona la specialità',
    fill_participants: '*Prima scrivi i partecipanti',
  },
  footer: {
    get_in_touch: 'Contattaci',
    address: 'Indirizzo',
    phone: 'Telefono',
    email: 'Indirizzo email',
    map: 'Posizione sulla mappa',
  },
  header: {
    checkout: 'Verifica',
  },
  item_extra_content: {
    add_to_cart: 'Aggiungi al carrello',
    product_removed: 'Prodotto rimosso',
    product_added: 'Prodotto aggiunto',
    remove: 'Rimuovi',
    day: 'Giorno',
  },
  lesson: {
    remove: 'Rimuovi',
    prefered_language: 'Lingua di preferenza',
    activity: 'Attività*',
    speciality: 'Specialità*',
    participants_info: 'Informazioni sui partecipanti:',
    number_of_participants: 'Numero dei partecipanti:',
    max_no_participants: '*Numero massimo di partecipanti:',
    calculating: 'Stiamo calcolando…',
  },
  not_found: {
    title: 'Ops',
    description: 'La pagina che stai cercando non è disponibile',
    button: 'Torna indietro',
  },
  search: {
    resort: 'Ripristina',
    add_filters: 'Filtri aggiuntivi',
    clear_filters: 'Rimuovi filtri',
    button: 'Cerca',
    activity: 'Attività',
    speciality: 'Specialità',
    start_date: 'Data d\'inizio',
    end_date: 'Data di fine',
    language: 'Lingua',
    type: 'Tipo',
    age: 'Età',
  },
  validators: {
    required: 'Questo campo è obbligatorio',
    phone: 'Telefono non valido',
    email: 'Email non valida',
    less: 'Deve essere minore o uguale a 10',
    more: 'Deve essere maggiore o uguale a 10',
  },
  schoolinfo: {
    no_info: 'Nessuna informazione',
  },
  price: {
    admin_fee: 'Quota amministrativa',
    total: 'Totale:',
  },
  header_desktop: {
    loading: 'Caricamento…',
  },
  spacees: {
    available_spaces: 'Posti disponibili:',
  },
  no_participant: {
    disabled: 'disattivato',
    max: 'Numero massimo di partecipanti raggiunto',
    no: 'Numero dei partecipanti:',
  },
  checkout_status: {
    thank: 'Grazie',
    error: 'Qualcosa è andato storto',
    back: 'Torna al checkout',
    continue: 'Continua',
  },
  client: {
    details: 'Le tue informazioni',
    title: 'Denominazione',
    mrs: 'Signora',
    mr: 'Signor',
    name: 'Nome*',
    firstname: 'Nome di battesimo*',
    enter_name: 'Scrivi il tuo nome',
    surname: 'Cognome*',
    enter_surname: 'Scrivi il tuo cognome',
    tel: 'Numero di telefono*',
    enter_tel: 'Scrivi il tuo numero di telefono',
    next: 'Successivo',
  },
  preview: {
    review: 'Verifica il tuo ordine',
    participants: 'Partecipanti',
    name: 'Nome',
    firstname: 'Nome di battesimo',
    surname: 'Cognome',
    age: 'Età',
    exp: 'Livello di esperienza',
    details: 'Le tue informazioni:',
    email: 'Email:',
    tel: 'Numero di telefono:',
    back: 'INDIETRO',
    pay: 'Paga ora',
  },
  error: {
    try_again: 'Qualcosa è andato storto… Riprova più tardi',
  },
  resort_select: {
    resort: 'Ripristina',
  },
  item_list: {
    no_results: 'Nessun risultato',
  },
  success: {
    placed: 'Il tuo ordine è stato effettuato con successo',
  },
  levels: {
    first_steps: 'Primi passi',
    beginner: 'Principiante',
    intermediate: 'Intermedio',
    advanced: 'Avanzato',
  },
  cart: {
    empty: 'Il carrello è vuoto',
  },
  api_activity: {
    Snowboard: 'Snowboard',
    'Cross-country': 'Cross-country',
    'Ice Climbing': 'Arrampicata su ghiaccio',
    Snowshoes: 'Scarponi da neve',
    Telemark: 'Telemark',
    'Ice Skating': 'Pattinaggio sul ghiaccio',
    SKI: 'Sci',
    Any: 'Qualsiasi',
  },
  api_speciality: {
    Racing: 'Corsa',
    'Ice Hockey': 'Hockey su ghiaccio',
    'Cat skiing': 'Cat skiing',
    Standard: 'Standard',
    Heliskiing: 'Elisci',
    'Figure skating': 'Pattinaggio artistico',
    Skating: 'Pattinaggio',
    Any: 'Qualsiasi',
    Handicap: 'Handicap',
    Freeride: 'Fuoripista',
    Guiding: 'Direzione',
    Freestyle: 'Freestyle',
    Classic: 'Classico',
    Touring: 'Escursionismo',
  },
  api_type: {
    Any: 'Qualsiasi',
    Private: 'individuale',
    Group: 'gruppo',
  },
};

export default itTranslations;
