import * as c from '../../constants/Redux';

const initialState = {
  client: {},
  lessons: [],
  error: '',
  link: '',
};

const order = (state = initialState, action) => {
  switch (action.type) {
    case c.REDUX_ORDER_PREPARATION: {
      return action.payload;
    }
    case c.REDUX_ORDER_CANCEL: {
      return {
        client: {},
        lessons: [],
        error: '',
        link: '',
      };
    }
    case c.REDUX_ORDER_ERROR: {
      const returnState = {
        ...state,
        error: action.err,
      };
      return returnState;
    }
    case c.REDUX_ORDER_SUCCESS: {
      const returnState = {
        ...state,
        link: action.res,
      };
      return returnState;
    }
    default:
      return state;
  }
};

export default order;
