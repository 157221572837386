const rsTranslations = {
  buyer: {
    participant: 'Učesnik #1',
    participant_info: 'Prvo zaberite aktivnost i specijalnost',
    select: 'Izaberite...',
    name: 'Ime*',
    firstname: 'Ime*',
    enter_name: 'Unesite ime',
    surname: 'Prezime',
    enter_surname: 'Unesite prezime',
    age: 'Godine*',
  },
  daypicker: {
    no_available_dates: '*Nema dostupnih datuma koji se poklapaju sa željenim časovima',
    select_activity: '*Prvo izaberite aktivnost',
    select_speciality: '*Prvo izaberite specijalnost',
    fill_participants: '*Prvo upišite učesnike',
  },
  footer: {
    get_in_touch: 'STUPITE U KONTAKT',
    address: 'Adresa',
    phone: 'Telefon',
    email: 'Email adresa',
    map: 'Lokacija',
  },
  header: {
    checkout: 'Kasa',
  },
  item_extra_content: {
    add_to_cart: 'DODAJTE U KOLICA',
    product_removed: 'Proizvod je uklonjen',
    product_added: 'Proizvod je dodat',
    remove: 'UKLONI',
    day: 'Dan',
  },
  lesson: {
    remove: 'Ukloni',
    prefered_language: 'Željeni jezik*',
    activity: 'Aktivnost*',
    speciality: 'Specijalnost*',
    participants_info: 'Informacije o učesniku:',
    number_of_participants: 'Broj učesnika:',
    max_no_participants: '*Maksimalni broj učesnika:',
    calculating: 'Računam...',
  },
  not_found: {
    title: 'Ups',
    description: 'Stranica koju tražite nije pronadjena',
    button: 'NAZAD',
  },
  search: {
    resort: 'Pribeći',
    add_filters: 'Dodatni filteri',
    clear_filters: 'Uklonite filtere',
    button: 'PRETRAŽI',
    activity: 'Aktivnost',
    speciality: 'Specijalnost',
    start_date: 'Datum početka',
    end_date: 'Datum kraja',
    language: 'Jezik',
    type: 'Tip',
    age: 'Godine',
  },
  validators: {
    required: 'Ovo polje je obavezno',
    phone: 'Nevažeći broj telefona',
    email: 'Nevažeći email',
    less: 'Mora biti jednako ili manje od 10',
    more: 'Mora biti jednako ili veće od 10',
  },
  schoolinfo: {
    no_info: 'Nema informacija',
  },
  price: {
    admin_fee: 'Administracijska taksa',
    total: 'Ukupno:',
  },
  header_desktop: {
    loading: 'Učitava se...',
  },
  spacees: {
    available_spaces: 'Dostupno mesta:',
  },
  no_participant: {
    disabled: 'Onemogućeno',
    max: 'Maksimalni broj učesnika je dostignut',
    no: 'Broj učesnika',
  },
  checkout_status: {
    thank: 'HVALA VAM',
    error: 'NEŠTO JE POŠLO NAOPAKO',
    back: 'NAZAD U KORPU',
    continue: 'NASTAVI',
  },
  client: {
    details: 'Vaši podaci',
    title: 'Naslov*',
    mrs: 'Gospodja',
    mr: 'Gospodin',
    name: 'Ime*',
    firstname: 'Ime*',
    enter_name: 'Unesite ime',
    surname: 'Prezime*',
    enter_surname: 'Unesite prezime',
    tel: 'Broj telefona*',
    enter_tel: 'Unesite broj telefona',
    next: 'SLEDEĆE',
  },
  preview: {
    review: 'Pregledajte porudžbinu',
    participants: 'Učesnici',
    name: 'Ime',
    firstname: 'Ime',
    surname: 'Prezime',
    age: 'Godine',
    exp: 'Iskustvo',
    details: 'Vaši podaci:',
    email: 'Email:',
    tel: 'Broj telefona:',
    back: 'NAZAD',
    pay: 'PLATITE',
  },
  error: {
    try_again: 'Nešto je pošlo naopako... Molimo pokušajte kasnije',
  },
  resort_select: {
    resort: 'Pribeći',
  },
  item_list: {
    no_results: 'Nema rezultata',
  },
  success: {
    placed: 'Vaša narudžbina je primljena',
  },
  levels: {
    first_steps: 'Prvi koraci',
    beginner: 'Početnik',
    intermediate: 'Srednji',
    advanced: 'Napredni',
  },
  cart: {
    empty: 'Корпа је празна',
  },
  api_activity: {
    Snowboard: 'Snoubord',
    'Cross-country': 'Kros',
    'Ice Climbing': 'Penjanje po ledu',
    Snowshoes: 'Cipele za sneg',
    Telemark: 'Telemark',
    'Ice Skating': 'Klizanje',
    SKI: 'SKIJANJE',
    Any: 'Bilo koji',
  },
  api_speciality: {
    Racing: 'Trkanje',
    'Ice Hockey': 'Hokej',
    'Cat skiing': 'Cat skiing',
    Standard: 'Standardno',
    Heliskiing: 'Skijanje van staze',
    'Figure skating': 'Umetničko klizanje',
    Skating: 'Klizanje',
    Any: 'Bilo koji',
    Handicap: 'Hendikep',
    Freeride: 'Slobodna vožnja',
    Guiding: 'Voditi',
    Freestyle: 'Slobodan stil',
    Classic: 'Klasično',
    Touring: 'Obilazak',
  },
  api_type: {
    Any: 'Bilo koji',
    Private: 'pojedinačno',
    Group: 'grupa',
  },
};

export default rsTranslations;
