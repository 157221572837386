import * as c from '../../constants/Redux';

export const addCheckoutStatusError = (error) => ({
  type: c.REDUX_CHECKOUT_ERROR,
  error,
});

export const clearCheckoutStatus = () => ({
  type: c.REDUX_CHECKOUT_CLEAR,
});
