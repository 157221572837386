export const REDUX_ORDER_PREPARATION = 'ORDER_DATA_PREPARE';
export const REDUX_ORDER_CANCEL = 'ORDER_DATA_CANCEL';
export const REDUX_ORDER_ERROR = 'ORDER_LINK_ERROR';
export const REDUX_ORDER_SUCCESS = 'ORDER_LINK_SUCCESS';

export const REDUX_CART_ADD = 'ADD_TO_CART';
export const REDUX_CART_REMOVE = 'REMOVE_FROM_CART';
export const REDUX_CART_LOCAL_STORAGE = 'ADD_TO_CART_FROM_LOCAL_STORAGE';
export const REDUX_CART_CLEAR = 'CART_CLEAR';

export const REDUX_CLIENT_LOCAL_STORAGE = 'ADD_TO_CLIENT_FROM_LOCAL_STORAGE';

export const REDUX_CHECKOUT_ERROR = 'CHECKOUT_STATUS_ERROR';
export const REDUX_CHECKOUT_CLEAR = 'CHECKOUT_STATUS_CLEAR';
