const skTranslations = {
  buyer: {
    participant: 'Účastník #',
    participant_info: 'Najprv vyberte aktivitu a špecializáciu',
    select: 'Vybrať...',
    name: 'Názov*',
    firstname: 'Krstné meno*',
    enter_name: 'Zadajte svoje meno',
    surname: 'Priezvisko',
    enter_surname: 'Zadajte svoje priezvisko',
    age: 'Vek*',
  },
  daypicker: {
    no_available_dates: '*Žiadne dostupné dátumy zodpovedajúce preferenciám lekcií',
    select_activity: '*Najprv vyberte aktivitu',
    select_speciality: '*Najprv vyberte špecialitu',
    fill_participants: '*Najprv vyplňte účastníkov',
  },
  footer: {
    get_in_touch: 'KONTAKTUJTE NÁS',
    address: 'Adresa',
    phone: 'Telefón',
    email: 'Emailová adresa',
    map: 'Mapa polohy',
  },
  header: {
    checkout: 'Pokladňa',
  },
  item_extra_content: {
    add_to_cart: 'PRIDAŤ DO KOŠÍKA',
    product_removed: 'Produkt bol odstránený',
    product_added: 'Produkt bol pridaný',
    remove: 'ODSTRÁNIŤ',
    day: 'Deň',
  },
  lesson: {
    remove: 'Odstrániť',
    prefered_language: 'Preferovaný jazyk*',
    activity: 'Aktivita*',
    speciality: 'Špecialita*',
    participants_info: 'Informácie o účastníkoch:',
    number_of_participants: 'Počet účastníkov:',
    max_no_participants: '*Maximálny počet účastníkov:',
    calculating: 'Prebieha výpočet...',
  },
  not_found: {
    title: 'Hops',
    description: 'Stránka, ktorú hľadáte, sa nedá nájsť',
    button: 'VRÁŤ SA',
  },
  search: {
    resort: 'Stredisko',
    add_filters: 'Dodatočné filtre',
    clear_filters: 'Vymazať filtre',
    button: 'VYHĽADÁVANIE',
    activity: 'Aktivita',
    speciality: 'Špecialita',
    start_date: 'Dátum začiatku',
    end_date: 'Dátum ukončenia',
    language: 'Jazyk',
    type: 'Typ',
    age: 'Vek',
  },
  validators: {
    required: 'Toto pole je povinné',
    phone: 'Neplatný telefón',
    email: 'Neplatný email',
    less: 'Musí byť rovné alebo menšie ako 10',
    more: 'Musí byť rovné alebo väčšie ako 10',
  },
  schoolinfo: {
    no_info: 'Žiadna informácia',
  },
  price: {
    admin_fee: 'Administratívny poplatok',
    total: 'Celkom:',
  },
  header_desktop: {
    loading: 'Načítava...',
  },
  spacees: {
    available_spaces: 'Voľné miesta:',
  },
  no_participant: {
    disabled: 'zdravotne postihnutých',
    max: 'Dosiahnutý maximálny počet účastníkov',
    no: 'Počet účastníkov',
  },
  checkout_status: {
    thank: 'ĎAKUJEM',
    error: 'NIEČO SA POKAZILO',
    back: 'SPÄŤ NA POKLADNU',
    continue: 'ĎALEJ',
  },
  client: {
    details: 'Tvoje detaily:',
    title: 'Názov*',
    mrs: 'Pani',
    mr: 'Pán',
    name: 'Názov*',
    firstname: 'Krstné meno*',
    enter_name: 'Zadajte svoje meno',
    surname: 'Priezvisko*',
    enter_surname: 'Zadajte svoje priezvisko',
    tel: 'Telefónne číslo*',
    enter_tel: 'Zadajte svoje telefónne číslo',
    next: 'ĎALŠIE',
  },
  preview: {
    review: 'Ohodnoť svoju objednávku',
    participants: 'Účastníci',
    name: 'Názov',
    firstname: 'Krstné meno',
    surname: 'Priezvisko',
    age: 'Vek',
    exp: 'Úroveň skúseností',
    details: 'Tvoje detaily:',
    email: 'Email:',
    tel: 'Telefónne číslo:',
    back: 'SPÄŤ',
    pay: 'ZAPLATIŤ TERAZ',
  },
  error: {
    try_again: 'Niečo sa pokazilo. Prosím skús to znovu neskôr',
  },
  resort_select: {
    resort: 'Stredisko',
  },
  item_list: {
    no_results: 'Žiadne výsledky',
  },
  success: {
    placed: 'Vaša objednávka bola úspešne odoslaná',
  },
  levels: {
    first_steps: 'Prvé kroky',
    beginner: 'Začiatočník',
    intermediate: 'Medziprodukt',
    advanced: 'Pokročilé',
  },
  cart: {
    empty: 'Košík je prázdny',
  },
  api_activity: {
    Snowboard: 'Snowboard',
    'Cross-country': 'Bežecké lyžovanie',
    'Ice Climbing': 'Lezenie po ľade',
    Snowshoes: 'Snežnice',
    Telemark: 'Telemark',
    'Ice Skating': 'Korčuľovanie na ľade',
    SKI: 'LYŽOVAŤ',
    Any: 'Akýkoľvek',
  },
  api_speciality: {
    Racing: 'Preteky',
    'Ice Hockey': 'Ľadový hokej',
    'Cat skiing': 'Cat skiing',
    Standard: 'Štandardné',
    Heliskiing: 'Heliskiing',
    'Figure skating': 'Krasokorčuľovanie',
    Skating: 'Korčuľovanie',
    Any: 'Akýkoľvek',
    Handicap: 'Handicap',
    Freeride: 'Jazda zadarmo',
    Guiding: 'Vedenie',
    Freestyle: 'Voľný štýl',
    Classic: 'klasické',
    Touring: 'Touring',
  },
  api_type: {
    Any: 'Akýkoľvek',
    Private: 'individuálne',
    Group: 'skupina',
  },
};

export default skTranslations;
