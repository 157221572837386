import { filter } from 'lodash';
import * as c from '../../constants/Api';

const activities = (state = {}, action) => {
  switch (action.type) {
    case c.API_ACTIVITIES_SUCCESS: {
      return filter(action.payload, (activity) => activity.name !== 'Any');
    }
    default:
      return state;
  }
};

export default activities;
