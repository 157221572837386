const plTranslations = {
  buyer: {
    participant: 'Uczestnik #',
    participant_info: 'Wybierz rodzaj aktywnośći fizycznej i specjalizację',
    select: 'Wybiersz',
    name: 'Imię*',
    firstname: 'Imię*',
    enter_name: 'Woisz swoje imię',
    surname: 'Nazwisko*',
    enter_surname: 'Wpisz swoje nazwisko',
    age: 'Wiek',
  },
  daypicker: {
    no_available_dates: 'Brak dostępnych terminów na preferowane lekcje',
    select_activity: 'Najpierw wybierz aktywność',
    select_speciality: 'Najpierw wybierzspecjalność',
    fill_participants: 'Najpierew wpisz uvczestników',
  },
  footer: {
    get_in_touch: 'Skontaktuj się z nami',
    address: 'Adres',
    phone: 'Numer telefonu',
    email: 'Adres email',
    map: 'mapa lokalizacji',
  },
  header: {
    checkout: 'Kasa',
  },
  item_extra_content: {
    add_to_cart: 'Dodaj do koszyka',
    product_removed: 'Produkt usunięty',
    product_added: 'Produkt dodany',
    remove: 'Usuń',
    day: 'Dzień',
  },
  lesson: {
    remove: 'Usuń',
    prefered_language: 'Preferowany język',
    activity: 'Aktywność',
    speciality: 'Specjalizacja',
    participants_info: 'Informacje o uczestnikach',
    number_of_participants: 'liczba uczestników',
    max_no_participants: 'Maksymalna liczba uczestników',
    calculating: 'Obliczanie….',
  },
  not_found: {
    title: 'Ups',
    description: 'Strona której szukasz nie może być znaleziona',
    button: 'Wróć/zawróć',
  },
  search: {
    resort: 'Kurort',
    add_filters: 'Dodatkowe filtry',
    clear_filters: 'Oczyść/wyczyść filtry',
    button: 'Szukaj',
    activity: 'Aktywność',
    speciality: 'Specjalność/specjalizacja',
    start_date: 'Początek',
    end_date: 'Koniec',
    language: 'Język',
    type: 'Typ',
    age: 'Wek',
  },
  validators: {
    required: 'To pole jest wymagane',
    phone: 'Nieprawidłowy numer telefonu',
    email: 'Nieprawidłowy adres email',
    less: 'Musi być równe lub mniejsze niż 10',
    more: 'Musi byćrówne lub większe niż 10',
  },
  schoolinfo: {
    no_info: 'Brak informacji',
  },
  price: {
    admin_fee: 'Opłata administracyjna',
    total: 'W sumie',
  },
  header_desktop: {
    loading: 'ładuje',
  },
  spacees: {
    available_spaces: 'Dostępne miejsca',
  },
  no_participant: {
    disabled: 'Niepełnosprawny',
    max: 'Maksymalna liczba uczestników osiągnięta',
    no: 'liczba uczestników',
  },
  checkout_status: {
    thank: 'Dziękuję Ci/ Dziękujemy',
    error: 'Coś poszło nie tak',
    back: 'Powrót do kasy',
    continue: 'Kontynuuj',
  },
  client: {
    details: 'Twoje dane',
    title: 'Tytuł',
    mrs: 'Pani',
    mr: 'Pan',
    name: 'Imię*',
    firstname: 'Imię*',
    enter_name: 'Wpisz swoje imię',
    surname: 'Nazwisko*',
    enter_surname: 'Wpisz swoje nazwisko',
    tel: 'Numer telefonu*',
    enter_tel: 'Wpisz swój numer telefonu',
    next: 'Następny',
  },
  preview: {
    review: 'Sprawdź swoje zamówienie',
    participants: 'Uczestnicy',
    name: 'Imię',
    firstname: 'Imię',
    surname: 'Nazwisko',
    age: 'Wiek',
    exp: 'Poziom doświadczenia',
    details: 'Twoje dane:',
    email: 'Email:',
    tel: 'Numer telefon:',
    back: 'powrót do kasy',
    pay: 'Zapłać teraz',
  },
  error: {
    try_again: 'Coś poszło nie tak… Proszę spróbuj później',
  },
  resort_select: {
    resort: 'Kurort',
  },
  item_list: {
    no_results: 'Brak wyników',
  },
  success: {
    placed: 'Twoje zamówienie zostało złożone pomyślnie',
  },
  levels: {
    first_steps: 'Pierwsze kroki',
    beginner: 'Początkujący',
    intermediate: 'średnio zaawansowany',
    advanced: 'Zaawansowany',
  },
  cart: {
    empty: 'wózek jest pusty',
  },
  api_activity: {
    Snowboard: 'Snowboard',
    'Cross-country': 'Biegi przełajowe',
    'Ice Climbing': 'Wspinaczka lodowa/Ice climbing',
    Snowshoes: 'Buty śnieżne',
    Telemark: 'Telemark',
    'Ice Skating': 'Jazda figurowa na lodzie',
    SKI: 'Jazda na nartach',
    Any: 'Jakikowlwiek',
  },
  api_speciality: {
    Racing: 'Wyścigi',
    'Ice Hockey': 'Hokej',
    'Cat skiing': 'Cat skiing',
    Standard: 'Standardowa/e',
    Heliskiing: 'Heliskiing',
    'Figure skating': 'Jazda figurowa na lodzie',
    Skating: 'Łyżwiarstwo',
    Any: 'Jakikolwiek',
    Handicap: 'Upośledzenie',
    Freeride: 'Darmowa jazda',
    Guiding: 'Przewodnik',
    Freestyle: 'Freestyle/styl wolny',
    Classic: 'Klasyczne/nie/a',
    Touring: 'Tusrytyka',
  },
  api_type: {
    Any: 'Jakikowlwiek',
    Private: 'Indywidualnie',
    Group: 'grupa/grupowo',
  },
};

export default plTranslations;
