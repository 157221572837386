import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import 'semantic-ui-css/semantic.min.css';
import LanguageSwitcher from '../Field/LanguageSelector';

const MobileButtons = ({
  searchHandler,
  history,
  cart,
  filtersSelected,
  mobileButtonsShow,
}) => (
  <div className={`skic-mobile-buttons ${mobileButtonsShow ? 'skic-mobile-buttons__appear' : 'skic-mobile-buttons__hidden'}`}>
    <button
      onClick={() => searchHandler(true)}
      className="skic-mobile-buttons__search"
      type="button"
    >
      {filtersSelected > 0
        ? (
          <div className="filter-search-indicator">
            {filtersSelected}
          </div>
        )
        : <div className="skic-icon skic-icon__search" />}
      Search
    </button>
    <button
      className="skic-mobile-buttons__checkout"
      onClick={() => history.push('/checkout')}
      disabled={!cart.cartItems.length}
      type="button"
    >
      {cart.cartItems.length
        ? (
          <div className="skic-mobile-buttons__checkout__quantity">
            {cart.cartItems.length}
          </div>
        )
        : <div className="skic-icon skic-icon__basket" />}
      Checkout
    </button>
    <LanguageSwitcher />
  </div>
);

const mapStateToProps = (state) => ({
  cart: state.cart,
});

MobileButtons.propTypes = {
  searchHandler: PropTypes.func,
  history: PropTypes.shape({
    push: PropTypes.func,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  }),
  cart: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    cartItems: PropTypes.array,
  }),
  filtersSelected: PropTypes.number,
  mobileButtonsShow: PropTypes.bool,
};

export default withRouter(connect(mapStateToProps)(MobileButtons));
