const nlTranslations = {
  buyer: {
    participant: 'Deelnemer #',
    participant_info: 'Selecteer eerst activiteit en specialiteit',
    select: 'Selecteer...',
    name: 'Naam*',
    firstname: 'Voornaam*',
    enter_name: 'Vul uw naam in',
    surname: 'Achternaam*',
    enter_surname: 'Vul je achternaam in',
    age: 'Leeftijd*',
  },
  daypicker: {
    no_available_dates: '*Geen beschikbare data die overeenkomen met lesvoorkeuren',
    select_activity: '*Selecteer eerst activiteit',
    select_speciality: '*Selecteer eerst specialiteit',
    fill_participants: '*Eerst deelnemers invullen',
  },
  footer: {
    get_in_touch: 'NEEM CONTACT OP',
    address: 'Adres',
    phone: 'Telefoon',
    email: 'E-mailadres',
    map: 'Locatie kaart',
  },
  header: {
    checkout: 'Uitchecken',
  },
  item_extra_content: {
    add_to_cart: 'VOEG TOE AAN WINKELKAR',
    product_removed: 'Product verwijderd',
    product_added: 'Product toegevoegd',
    remove: 'VERWIJDEREN',
    day: 'Dag',
  },
  lesson: {
    remove: 'Verwijderen',
    prefered_language: 'Voorkeurstaal*',
    activity: 'Werkzaamheid*',
    speciality: 'Specialiteit*',
    participants_info: 'Info deelnemers:',
    number_of_participants: 'Aantal deelnemers:',
    max_no_participants: '*Maximaal aantal deelnemers:',
    calculating: 'Berekenen...',
  },
  not_found: {
    title: 'Oeps',
    description: 'De pagina die u zoekt kan niet worden gevonden',
    button: 'GA TERUG',
  },
  search: {
    resort: 'Toevlucht',
    add_filters: 'Extra filters',
    clear_filters: 'Wis filters',
    button: 'ZOEKEN',
    activity: 'Werkzaamheid',
    speciality: 'Specialiteit',
    start_date: 'Startdatum',
    end_date: 'Einddatum',
    language: 'Taal',
    type: 'Type',
    age: 'Leeftijd',
  },
  validators: {
    required: 'Dit veld is verplicht',
    phone: 'Ongeldige telefoon',
    email: 'Ongeldig e-mail',
    less: 'Moet gelijk zijn aan of kleiner zijn dan 10',
    more: 'Moet gelijk zijn aan of groter zijn dan 10',
  },
  schoolinfo: {
    no_info: 'Geen informatie',
  },
  price: {
    admin_fee: 'Administratiekosten',
    total: 'Totaal:',
  },
  header_desktop: {
    loading: 'Bezig met laden...',
  },
  spacees: {
    available_spaces: 'Beschikbare ruimtes:',
  },
  no_participant: {
    disabled: 'Gehandicapt',
    max: 'Maximaal aantal deelnemers bereikt',
    no: 'Aantal deelnemers',
  },
  checkout_status: {
    thank: 'BEDANKT',
    error: 'ER IS IETS FOUT GEGAAN',
    back: 'TERUG NAAR AFREKENEN',
    continue: 'DOORGAAN',
  },
  client: {
    details: 'Jouw details:',
    title: 'Titel*',
    mrs: 'Mvr',
    mr: 'Dhr',
    name: 'Naam*',
    firstname: 'Voornaam*',
    enter_name: 'Vul uw naam in',
    surname: 'Achternaam*',
    enter_surname: 'Vul je achternaam in',
    tel: 'Telefoon nummer*',
    enter_tel: 'Vul je telefoonnummer in',
    next: 'DE VOLGENDE',
  },
  preview: {
    review: 'Controleer uw bestelling',
    participants: 'Deelnemers',
    name: 'Naam',
    firstname: 'Voornaam',
    surname: 'Achternaam',
    age: 'Leeftijd',
    exp: 'Ervaringsniveau',
    details: 'Jouw details:',
    email: 'E-mail:',
    tel: 'Telefoon nummer:',
    back: 'RUG',
    pay: 'NU BETALEN',
  },
  error: {
    try_again: 'Er is iets misgegaan... Probeer het later opnieuw',
  },
  resort_select: {
    resort: 'Toevlucht',
  },
  item_list: {
    no_results: 'Geen resultaten',
  },
  success: {
    placed: 'Uw bestelling is succesvol geplaatst',
  },
  levels: {
    first_steps: 'Eerste stappen',
    beginner: 'Beginner',
    intermediate: 'Tussenliggend',
    advanced: 'Geavanceerd',
  },
  cart: {
    empty: 'Winkelwagen is leeg',
  },
  api_activity: {
    Snowboard: 'Snowboarden',
    'Cross-country': 'Langlaufen',
    'Ice Climbing': 'Ijs klimmen',
    Snowshoes: 'Sneeuwschoenen',
    Telemark: 'Telemark',
    'Ice Skating': 'Schaatsen',
    SKI: 'SKI',
    Any: 'Elk',
  },
  api_speciality: {
    Racing: 'Racen',
    'Ice Hockey': 'Ijshockey',
    'Cat skiing': 'Cat skiing',
    Standard: 'Standaard',
    Heliskiing: 'Heli skiën',
    'Figure skating': 'Figuurschaatsen',
    Skating: 'Het schaatsen',
    Any: 'Elk',
    Handicap: 'Handicap',
    Freeride: 'Gratis rit',
    Guiding: 'Begeleiden',
    Freestyle: 'Vrije stijl',
    Classic: 'Klassiek',
    Touring: 'Toeren',
  },
  api_type: {
    Any: 'Elk',
    Private: 'individueel',
    Group: 'groep',
  },
};

export default nlTranslations;
