import { reducer as formReducer } from 'redux-form';
import * as c from '../../constants/Api';

const form = formReducer.plugin({
  lesson: (state, action) => {
    switch (action.type) {
      case c.SET_DATES: {
        const newState = state;
        newState.values.lessons[action.meta].dates = action.payload;
        return newState;
      }

      default:
        return state;
    }
  },
});
export default form;
