import { token } from '../../config';
import { lessonsPaginator } from '../../paginators/paginators';
import * as c from '../../constants/Api';

export const requestCustomLessonsPage = (pageNo, pageSize, payload) => lessonsPaginator
  .lessons.requestPageCustom(pageNo, pageSize, payload);

export const filterOutLessonsWithinDate = (pageNo, pageSize, timeFrom, timeTo) => {
  let filter = `filter=${token}&size=${pageSize}`;
  if (timeFrom) {
    filter = filter.concat(`&timeFrom=${timeFrom}`);
  }
  if (timeTo) {
    filter = filter.concat(`&timeTo=${timeTo}`);
  }

  return (
    lessonsPaginator.lessons.requestPage(pageNo, filter)
  );
};

export const setLessons = (json) => ({
  type: c.API_LESSONS_SUCCESS,
  payload: json,
});

export const setDates = (json, index) => ({
  type: c.SET_DATES,
  payload: json,
  meta: index,
});

export const updatePrice = (json) => ({
  type: c.UPDATE_PRICE_SUCCESS,
  payload: json,
});

export const errorPrice = (json) => ({
  type: c.UPDATE_PRICE_FAIL,
  payload: json,
});

export const setTimeFromDate = (json) => ({
  type: c.SELECT_TIME_FROM_DATE_SUCCESS,
  payload: json,
});

export const setTimeToDate = (json) => ({
  type: c.SELECT_TIME_TO_DATE_SUCCESS,
  payload: json,
});
