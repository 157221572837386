import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Sidebar, Segment } from 'semantic-ui-react';
import 'moment-timezone';
import { get, map } from 'lodash';
import 'semantic-ui-css/semantic.min.css';
import Header from '../../components/Layouts/Header';
import Footer from '../../components/Layouts/Footer';
import Notification from '../../components/Notification/Notification';
import HomePage from '../HomePage/HomePage';
import { addToCartFromLocalStorage } from '../../redux/actions/cartActions';
import { prepareOrder } from '../../redux/actions/orderActions';
import MobileButtons from '../../components/Layouts/MobileButtons';
import '../../assets/styles/main.scss';
import HeaderFixedDesktop from '../../components/Layouts/HeaderFixedDesktop';
import Search from '../Filter/Search';
import { fetchAccountInfoIfNeeded } from '../../redux/actions/accountActions';

class DefaultLayout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      initialised: false,
      visible: false,
      isDropdownOpen: false,
      filtersSelected: 0,
      latestYOffset: window.pageYOffset,
      mobileButtonsShow: true,
    };
  }

  componentDidMount() {
    const {
      addToCartFromLocalStorageAction,
      cart,
      name,
      order,
      prepareOrderAction,
      fetchAccountInfoIfNeededAction,
    } = this.props;

    fetchAccountInfoIfNeededAction();
    let cartLocalStorage = JSON.parse(localStorage.getItem('cart')) || [];
    const orderLocalStorage = JSON.parse(localStorage.getItem('order')) || {};
    if (typeof cartLocalStorage === 'number') {
      const cartLocal = [];
      cartLocal.push(cartLocalStorage);
      cartLocalStorage = cartLocal;
    }
    if (cart.cartItems.length === 0 && cartLocalStorage.length) {
      addToCartFromLocalStorageAction(map(cartLocalStorage, (item) => item.id));
    }
    if ((name === 'review' || name === 'checkout') && !Object.keys(order.client).length && Object.keys(get(orderLocalStorage, 'client', {})).length) {
      prepareOrderAction(orderLocalStorage);
    }
    if (name !== 'checkout') window.addEventListener('scroll', this.handleScroll);
  }

  static getDerivedStateFromProps(props, state) {
    const { account } = props;
    const { initialized } = state;
    if (!initialized && account && account.timezone) {
      const TZ = account.timezone;

      moment.tz.setDefault(TZ);
      return {
        ...state,
        initialized: true,
      };
    }
    return state;
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  setVisible = (value) => {
    this.setState({ visible: value });
  }

  handleScroll = () => {
    const { latestYOffset } = this.state;
    this.setState({
      mobileButtonsShow: window.pageYOffset < latestYOffset || window.pageYOffset < 60,
      latestYOffset: window.pageYOffset,
    });
  };

  handleOpen = () => {
    this.setState({ isDropdownOpen: true });
  }

  handleClose = () => {
    this.setState({ isDropdownOpen: false });
  }

  filterSelectedCount = (value) => this.setState({ filtersSelected: value });

  render() {
    const {
      RenderComponent,
      routeProps,
      account,
      cart,
      name,
    } = this.props;

    const {
      visible,
      isDropdownOpen,
      filtersSelected,
      mobileButtonsShow,
    } = this.state;

    if (name === 'checkout') {
      return (
        <div className="app-main">
          <Notification />
          <Header
            account={account}
            handleClose={this.handleClose}
            handleOpen={this.handleOpen}
          />
          <RenderComponent {...routeProps} account={account} />
          <Footer account={account} />
        </div>
      );
    }

    return (
      <Sidebar.Pushable as={Segment}>
        <Sidebar
          animation="overlay"
          direction="top"
          visible={visible}
          className={isDropdownOpen ? 'dropdown-open' : null}
        >
          <Search
            setVisible={this.setVisible}
            filterSelectedCount={this.filterSelectedCount}
            filtersSelected={filtersSelected}
            handleClose={this.handleClose}
            handleOpen={this.handleOpen}
          />
        </Sidebar>
        <Sidebar.Pusher
          dimmed={visible}
          onClick={visible ? () => this.setVisible(false) : null}
        >
          <div className="app-main">
            <Notification />
            {RenderComponent === HomePage
              ? (
                <MobileButtons
                  filtersSelected={filtersSelected}
                  searchHandler={this.setVisible}
                  mobileButtonsShow={mobileButtonsShow}
                />
              )
              : null}
            {RenderComponent === HomePage
              ? (
                <HeaderFixedDesktop
                  cart={cart}
                  account={account}
                />
              )
              : null}
            <Header
              account={account}
              handleClose={this.handleClose}
              handleOpen={this.handleOpen}
            />
            <RenderComponent {...routeProps} account={account} />
            <Footer account={account} />
          </div>
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    );
  }
}

const mapStateToProps = (state) => ({
  account: state.account,
  cart: state.cart,
  order: state.order,
});

const mapDispatchToProps = (dispatch) => ({
  addToCartFromLocalStorageAction: bindActionCreators(addToCartFromLocalStorage, dispatch),
  prepareOrderAction: bindActionCreators(prepareOrder, dispatch),
  fetchAccountInfoIfNeededAction: bindActionCreators(fetchAccountInfoIfNeeded, dispatch),
});

DefaultLayout.propTypes = {
  RenderComponent: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.element,
  ]).isRequired,
  name: PropTypes.string,
  account: PropTypes.shape({
    name: PropTypes.string,
    timezone: PropTypes.string,
  }),
  addToCartFromLocalStorageAction: PropTypes.func,
  cart: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.object,
  ]),
  order: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    client: PropTypes.object,
    // eslint-disable-next-line react/forbid-prop-types
    lessons: PropTypes.array,
  }),
  prepareOrderAction: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  routeProps: PropTypes.object,
  fetchAccountInfoIfNeededAction: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(DefaultLayout);
