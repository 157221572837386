import { lessonsPaginator } from '../../paginators/paginators';
import * as c from '../../constants/Api';

const lessons = (state = {}, action) => {
  switch (action.type) {
    case c.UPDATE_PRICE_FAIL: {
      return {
        ...state,
        error: action.payload,
      };
    }

    case c.UPDATE_PRICE_SUCCESS: {
      const id = action.payload.lesson;
      const lessonToEdit = state[id];
      lessonToEdit.price = Number(action.payload.res.body.price);
      return {
        ...state,
        [id]: lessonToEdit,
      };
    }
    case c.API_LESSONS_SUCCESS: {
      const { payload } = action;
      const newLessons = {};
      for (let i = 0; i < payload.body.length; i += 1) {
        newLessons[payload.body[i].id] = payload.body[i];
      }
      return {
        ...state,
        ...newLessons,
      };
    }

    default:
      return lessonsPaginator.itemsReducer(state, action);
  }
};

export default lessons;
