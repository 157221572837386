import React from 'react';
import { PropTypes } from 'prop-types';
import { formatLevel } from '../../utils/format';

const LevelDisplay = ({ level }) => (
  <span className="level-display">
    {formatLevel(level)}
  </span>
);

LevelDisplay.propTypes = {
  level: PropTypes.string,
};

export default LevelDisplay;
