import { filter, remove, isEmpty } from 'lodash';

import * as c from '../../constants/Redux';

const initialState = {
  cartItems: [],
};

export const removeItemFromLocalStorage = (id) => {
  const payloadToLocalStorage = JSON.parse(localStorage.getItem('cart')) || [];
  const initialValuesToLocalStorage = JSON.parse(localStorage.getItem('savedCheckoutValues')) || [];
  if (!isEmpty(initialValuesToLocalStorage)) {
    const updatedLessons = {
      lessons: filter(initialValuesToLocalStorage.lessons, (item) => item.id !== id),
    };
    localStorage.setItem('savedCheckoutValues', JSON.stringify(updatedLessons));
  }
  const updatedPayload = filter(payloadToLocalStorage, (item) => item.id !== id);
  localStorage.setItem('cart', JSON.stringify(updatedPayload));
};

const cart = (state = initialState, action) => {
  switch (action.type) {
    case c.REDUX_CART_ADD: {
      const {
        id,
        resorts,
        timeFrom,
        timeTo,
        type,
      } = action.payload;
      const updatedPayloadToLocalStorage = {
        id,
        resorts,
        timeFrom,
        timeTo,
        type,
      };
      if (action.payload.productId) {
        updatedPayloadToLocalStorage.productId = action.payload.productId;
      }
      const payloadToLocalStorage = JSON.parse(localStorage.getItem('cart')) || [];
      payloadToLocalStorage.push(updatedPayloadToLocalStorage);
      const items = state.cartItems;
      items.push(id);
      const newState = {
        cartItems: items,
      };
      localStorage.setItem('cart', JSON.stringify(payloadToLocalStorage));
      return newState;
    }
    case c.REDUX_CART_REMOVE: {
      const items = state.cartItems;
      remove(items, (item) => item === action.id);
      const newState = {
        cartItems: items,
      };
      removeItemFromLocalStorage(action.id);
      return newState;
    }
    case c.REDUX_CART_LOCAL_STORAGE: {
      const items = action.ids;
      const newState = {
        cartItems: items,
      };
      return newState;
    }
    case c.REDUX_CART_CLEAR:
      return {
        cartItems: [],
      };
    default:
      return state;
  }
};

export default cart;
