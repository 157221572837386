import React from 'react';
import i18n from 'i18next';
import { PropTypes } from 'prop-types';

const SpacesDisplay = ({ item }) => (
  <div className="spaces-display">
    <span>
      {i18n.t('spacees.available_spaces')}
    </span>
    &nbsp;
    <span>
      {item && (item.capacity - item.takenSpaces)}
    </span>
  </div>
);

SpacesDisplay.propTypes = {
  item: PropTypes.shape({
    capacity: PropTypes.number.isRequired,
    takenSpaces: PropTypes.number.isRequired,
  }),
};

export default SpacesDisplay;
