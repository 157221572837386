import * as c from '../../constants/Redux';

/* TODO check if this action and local
    storage object "order" is still needed */
export const prepareOrder = (payload) => ({
  type: c.REDUX_ORDER_PREPARATION,
  payload,
});

export const removeOrder = () => ({
  type: c.REDUX_ORDER_CANCEL,
});
