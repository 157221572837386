import request from 'superagent';
import {
  min,
  uniqBy,
  forEach,
  maxBy,
  map,
  find,
  filter,
  reduce,
} from 'lodash';
import moment from 'moment';
import i18n from 'i18next';

// Api
import { api, subdomain } from '../../config';

// Utils
import { levelsOptions } from '../../options/Levels';

// Constants
export const LESSON_TYPE_GROUP = 'group';
export const LESSON_TYPE_INDIVIDUAL = 'individual';

export const formatPrice = (price) => Number.parseFloat(price).toFixed(2);

export const debounce = (func, delay, trailing) => {
  let timeout;
  return (...args) => {
    const later = () => {
      timeout = null;
      if (!trailing) func.apply(this, args);
    };
    const callNow = trailing && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, delay);
    if (callNow) func.apply(this, args);
  };
};

export const invertDates = (dates) => {
  if (!dates || (dates && !dates.length)) return [];
  const dateArray = [];
  let date;
  let runDateObj = moment(dates[0]);
  const stopDateObj = moment(dates[dates.length - 1]);

  while (runDateObj <= stopDateObj) {
    date = runDateObj.format('YYYY-MM-DD');

    if (!dates.includes(date)) {
      dateArray.push(new Date(date));
    }

    runDateObj = moment(runDateObj).add(1, 'days');
  }
  return dateArray;
};

export const getCartFromLocalStorage = () => {
  try {
    return JSON.parse(localStorage.getItem('cart'));
  } catch (e) {
    return [];
  }
};

export const getInitialLessonPrice = (lesson) => {
  if (!lesson) return formatPrice(0);
  if (lesson.type === LESSON_TYPE_INDIVIDUAL) return formatPrice(lesson.price);
  return formatPrice(min(lesson.prices, (el) => el.days).pricePerPerson);
};

// Map
export const mapCartItemsToGetLessonsPayload = (cart) => (
  cart.map((el) => el.type === LESSON_TYPE_GROUP ? el.id : el.productId) // eslint-disable-line
);

export const mapLessonToGetPricePayload = (lesson) => ({
  client: {},
  lessons: lesson.dates.map((date) => ({
    buyers: lesson.buyers.map((buyer) => ({
      // Assigning fake values in order to make validation
      // working on backend (price is not influenced by that)
      ...buyer,
      ...lesson.type === LESSON_TYPE_GROUP && { name: 'placeholder', age: lesson.minAge },
    })),
    resort: lesson.resort,
    product: lesson.product,
    clientAmount: lesson.buyers.length,
    // Format lesson date + product time
    timeFrom: date.split('T')[0] + 'T' + lesson.timeFrom.split('T')[1], // eslint-disable-line
    timeTo: date.split('T')[0] + 'T' + lesson.timeTo.split('T')[1], // eslint-disable-line
    // id
    ...lesson.type === LESSON_TYPE_GROUP ? { group: lesson.id } : { product: lesson.product },
  })),
});

export const mapApiLessonsToFormValues = (lessons, cart) => reduce(lessons, (array, el) => {
  if (find(cart, (cartItem) => cartItem.id === el.id)) {
    array.push({
      ...el,
      buyers: [{ ...el.type === LESSON_TYPE_INDIVIDUAL ? { name: '', level: '' } : { name: '', surname: '', age: '' } }],
      price: getInitialLessonPrice(el),
      dates: [],
      language: '',
      activity: el.activity.name !== 'Any' ? el.activity.id : undefined,
      speciality: el.speciality.name !== 'Any' ? el.speciality.id : undefined,
    });
  }
  return array;
}, []);

export const mapLessonsToCreateOrderPayload = (lessons, client) => {
  const formatted = [];
  lessons.forEach((lesson) => {
    lesson.dates.forEach((date) => {
      formatted.push({
        buyers: lesson.buyers,
        resort: lesson.resort,
        clientAmount: lesson.buyers.length,
        activity: lesson.activity,
        speciality: lesson.speciality,
        // Format lesson date + product time
        timeFrom: date.split('T')[0] + 'T' + lesson.timeFrom.split('T')[1], // eslint-disable-line
        timeTo: date.split('T')[0] + 'T' + lesson.timeTo.split('T')[1], // eslint-disable-line
        // id
        ...lesson.type === LESSON_TYPE_GROUP ? { group: lesson.id } : { product: lesson.product },
      });
    });
  });

  return {
    client: {
      ...client,
      phoneNumbers: [{ phoneNumber: '+'.concat(client.phoneNumber) }],
    },
    lessons: formatted,
  };
};

export const mapItemsToActivityOptions = (items) => {
  const options = [];
  forEach(items, (item) => {
    forEach(item.activities, (activity) => {
      options.push({ value: activity.id, label: i18n.t(`api_activity.${activity.name}`) });
    });
  });
  return uniqBy(options, 'value');
};

export const mapItemsToSpecialityOptions = (items, activity) => {
  const options = [];
  forEach(items, (item) => {
    forEach(item.activities, (itemActivity) => {
      if (itemActivity.id === activity) {
        forEach(itemActivity.specialities, (speciality) => {
          options.push(({ value: speciality.id, label: i18n.t(`api_speciality.${speciality.name}`) }));
        });
      }
    });
  });
  return uniqBy(options, 'value');
};

export const getSpecialityId = (items, activity, specialityName) => {
  const options = mapItemsToSpecialityOptions(items, activity);
  // NENI label přeložen?
  return find(options, (option) => option.label === specialityName)?.value;
};

export const mapItemsToLevelOptions = (items, activity, speciality) => {
  const levels = levelsOptions();
  const available = [];
  forEach(items, (item) => {
    forEach(item.activities, (itemActivity) => {
      if (itemActivity.id === activity) {
        forEach(itemActivity.specialities, (itemSpeciality) => {
          if (itemSpeciality.id === speciality) {
            const opt = levels.find((el) => el.value === itemSpeciality.level);
            available.push(opt);
          }
        });
      }
    });
  });
  const maxLevel = maxBy(available, 'weight');
  if (!maxLevel) return [];
  const options = [];
  let includeIndicator = true;
  forEach(levels, (option) => {
    if (includeIndicator) {
      options.push(option);
    }

    if (option.value === maxLevel.value) {
      includeIndicator = false;
    }
  });
  return options;
};

export const mapItemsToDates = (items, activity, speciality, buyers, type) => {
  if (!activity || !speciality) return [];

  // Group (Filter by group capacity)
  if (type === LESSON_TYPE_GROUP) {
    const filtered = filter(items, (item) => item.capacity >= buyers.length);
    return map(filtered, (item) => item.timeFrom.split('T')[0]); // YYYY-MM-DD
  }

  // Individual (Filter by participant level)
  if (find(buyers, (buyer) => !buyer.level)) return []; // If participants are missing info

  const levels = levelsOptions();
  const buyersWithLevelWeight = map(buyers, (buyer) => ({
    ...buyer,
    weight: levels.find((el) => el.value === buyer.level),
  }));
  const maxBuyerLevelWeight = maxBy(buyersWithLevelWeight, 'weight').weight;
  const dates = [];
  forEach(items, (item) => {
    forEach(item.activities, (itemActivity) => {
      if (itemActivity.id === activity) {
        forEach(itemActivity.specialities, (itemSpeciality) => {
          if (itemSpeciality.id === speciality) {
            const itemSpecialityWeight = levels.find((el) => el.value === itemSpeciality.level);
            if (itemSpecialityWeight <= maxBuyerLevelWeight) {
              dates.push(item.date); // YYYY-MM-DD
            }
          }
        });
      }
    });
  });

  return dates;
};

// Validators
export const isLessonValid = (ob) => {
  // Check if date is selected
  if (!ob.dates.length) return false;
  // Check if language is selected
  if (!ob.language) return false;
  // Validate buyers
  return !ob.buyers.find((el) => {
    // Search for buyer without level assigned
    if (ob.type === LESSON_TYPE_INDIVIDUAL) return !el.level;
    // Search for buyer without either name or age assigned
    return !el.name || !el.age || el.age > ob.maxAge || el.age < ob.minAge;
  });
};

export const isClientValid = (ob) => (
  !!ob.gender && !!ob.name && !!ob.surname && !!ob.email && !!ob.phoneNumber
);

// API
export const fetchPrice = (json) => request
  .post(`${api}/booking/order/price`)
  .set('Content-Type', 'application/json')
  .send(json).then((res) => res, (err) => err);

export const createOrder = (json) => request
  .post(`${api}/booking/order`)
  .set('Content-Type', 'application/json')
  .send(json);

export const fetchLessonsForCart = (payload) => request
  .post(`${api}/shop/lessons/${subdomain}`)
  .set('Content-Type', 'application/json')
  .send(payload);
