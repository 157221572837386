const roTranslations = {
  buyer: {
    participant: 'Participant #',
    participant_info: 'Selectați prima dată activitatea și specialitatea',
    select: 'Selectați...',
    name: 'Nume*',
    firstname: 'Prenume',
    enter_name: 'Introdu numele tau',
    surname: 'Nume',
    enter_surname: 'Introdu numele de familie',
    age: 'Vârstă',
  },
  daypicker: {
    no_available_dates: 'Nu sînt date disponibile care să corespundă preferințelor lecției',
    select_activity: 'Mai întâi selectați activitatea',
    select_speciality: 'Mai întâi selectați specialitatea',
    fill_participants: 'Mai întâi completați participanții',
  },
  footer: {
    get_in_touch: 'Luați legătura',
    address: 'Adresa',
    phone: 'Telefon',
    email: 'Adresa de e-mail',
    map: 'Localizare',
  },
  header: {
    checkout: 'Verifică',
  },
  item_extra_content: {
    add_to_cart: 'Adaugă in cos',
    product_removed: 'Produs renunțat',
    product_added: 'Produs adăugat',
    remove: 'Șterge',
    day: 'Zi',
  },
  lesson: {
    remove: 'Șterge',
    prefered_language: 'Limba preferată',
    activity: 'Activitate',
    speciality: 'Specialitate',
    participants_info: 'Informatii participanti',
    number_of_participants: 'Numărul de participanți',
    max_no_participants: 'Numar maxim de participanti',
    calculating: 'Calculez…',
  },
  not_found: {
    title: 'Whoooops',
    description: 'Pagina pe care o cauți nu poate fi găsită',
    button: 'Înapoi',
  },
  search: {
    resort: 'Stațiune',
    add_filters: 'Filtre suplimentare',
    clear_filters: 'Ștergeți filtrele',
    button: 'Căutare',
    activity: 'Activitate',
    speciality: 'Specialitate',
    start_date: 'Data începerii',
    end_date: 'Data încheieri',
    language: 'Limba',
    type: 'Model',
    age: 'Vârstă',
  },
  validators: {
    required: 'Acest câmp este obligatoriu',
    phone: 'Telefon invalid',
    email: 'E-mail invalid',
    less: 'Să fie egal sau mai mic de 10',
    more: 'Să fie egal sau mai mare de 10',
  },
  schoolinfo: {
    no_info: 'Fara informatii',
  },
  price: {
    admin_fee: 'Taxa de administrare',
    total: 'Total',
  },
  header_desktop: {
    loading: 'Încarcare',
  },
  spacees: {
    available_spaces: 'Spatii disponibile:',
  },
  no_participant: {
    disabled: 'Invalid',
    max: 'Numărul maxim de participanți atinsi',
    no: 'Numărul de participanți',
  },
  checkout_status: {
    thank: 'Mulțumesc',
    error: 'Ceva n-a mers bine',
    back: 'Înapoi la verificare',
    continue: 'Continua',
  },
  client: {
    details: 'Detaliile tale',
    title: 'Titlu',
    mrs: 'Doamnă',
    mr: 'Domnu',
    name: 'Numele',
    firstname: 'Prenume',
    enter_name: 'Introdu numele tau',
    surname: 'Nume',
    enter_surname: 'Introdu numele de familie',
    tel: 'Număr de telefon',
    enter_tel: 'Introdu numarul tau de telefon',
    next: 'Următorul',
  },
  preview: {
    review: 'Verifica-ti comanda',
    participants: 'Participanții',
    name: 'Nume',
    firstname: 'Prenume',
    surname: 'Nume',
    age: 'Vârstă',
    exp: 'Nivel de experienta',
    details: 'Datele tale:',
    email: 'E-mail:',
    tel: 'Număr de telefon:',
    back: 'Înapoi',
    pay: 'Plătește Acum',
  },
  error: {
    try_again: 'Vă rugăm să încercați din nou mai târziu',
  },
  resort_select: {
    resort: 'Stațiune',
  },
  item_list: {
    no_results: 'Fără rezultate',
  },
  success: {
    placed: 'Comanda dvs. a fost plasată cu succes',
  },
  levels: {
    first_steps: 'Primul pas',
    beginner: 'Începător',
    intermediate: 'Intermediar',
    advanced: 'Avansat',
  },
  cart: {
    empty: 'Coșul este gol',
  },
  api_activity: {
    Snowboard: 'Snowboard',
    'Cross-country': 'Cross-country',
    'Ice Climbing': 'Escaladare pe gheață',
    Snowshoes: 'Ghete de zăpadă',
    Telemark: 'Telemark',
    'Ice Skating': 'Patinaj',
    SKI: 'Schi',
    Any: 'Oricare',
  },
  api_speciality: {
    Racing: 'Curse',
    'Ice Hockey': 'Hochei pe gheata',
    'Cat skiing': 'Cat skiing',
    Standard: 'Standard',
    Heliskiing: 'Heli-skiing',
    'Figure skating': 'Patinaj artistic',
    Skating: 'Patinaj',
    Any: 'Oricare',
    Handicap: 'Handicap',
    Freeride: 'Freeride',
    Guiding: 'Indrumarea',
    Freestyle: 'Freestyle',
    Classic: 'Clasic',
    Touring: 'Turism',
  },
  api_type: {
    Any: 'Oricare',
    Private: 'individual',
    Group: 'grup',
  },
};

export default roTranslations;
