import { forEach, sortBy } from 'lodash';
import { formatDate } from './format';
import { getPricePerDay } from '../containers/Item/ItemExtraContent';

export const getTotalPagesCount = (totalRecords, recordsPerPage) => (
  Math.ceil(totalRecords / recordsPerPage)
);

export const getTotalPrice = (items, cartItems) => {
  let totalPrice = 0;
  forEach(cartItems, (id) => {
    totalPrice += Number(items[id].price
      || (items[id].prices && getPricePerDay(items[id].prices)));
  });
  return totalPrice;
};

export const sortDates = (dates) => sortBy(dates, (date) => new Date(date));

export const findItem = (items, date) => (
  items.filter((item) => (
    (formatDate(item.timeFrom) === formatDate(date))
  ))
);

export const removeIntersecting = (arr, IDs) => arr.filter((item) => IDs.indexOf(item) < 0);
