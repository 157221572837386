const frTranslations = {
  buyer: {
    participant: 'Participant #',
    participant_info: 'Selectionnez',
    select: 'Selectionnez…',
    name: 'Prénom',
    firstname: 'Prénom*',
    enter_name: 'Entrez votre prénom',
    surname: 'Nom de famille*',
    enter_surname: 'Entrez votre nom',
    age: 'Age',
  },
  daypicker: {
    no_available_dates: '*Aucune date disponible correspondant aux préférences des cours',
    select_activity: '*Selectionnez la première activité',
    select_speciality: '*Selectionnez la première spécialité',
    fill_participants: 'Remplissez d\'abord les participants',
  },
  footer: {
    get_in_touch: 'ENTRER EN CONTACT',
    address: 'Adresse',
    phone: 'Téléphone',
    email: 'Adresse mail',
    map: 'Emplacement',
  },
  header: {
    checkout: 'Vérifier',
  },
  item_extra_content: {
    add_to_cart: 'Ajouter une carte',
    product_removed: 'Produit retiré',
    product_added: 'Produit ajouté',
    remove: 'Retirer',
    day: 'Jour',
  },
  lesson: {
    remove: 'Retirer',
    prefered_language: 'Langue préférée',
    activity: 'Activité',
    speciality: 'Spécialité',
    participants_info: 'info des participants:',
    number_of_participants: 'Nombre de participants:',
    max_no_participants: 'Maximun de participants:',
    calculating: 'Calculating…',
  },
  not_found: {
    title: 'Oups',
    description: 'La page que vous cherchez n\'est pas trouvable',
    button: 'Retour',
  },
  search: {
    resort: 'Resort*',
    add_filters: 'Filtres supplémentaires',
    clear_filters: 'Effacer le filtre',
    button: 'RECHERCHER',
    activity: 'Activité',
    speciality: 'Spécialité',
    start_date: 'Date de départ',
    end_date: 'Date de fin',
    language: 'Langue',
    type: 'Genre',
    age: 'Age',
  },
  validators: {
    required: 'Ce champ est requis',
    phone: 'Téléphone invalide',
    email: 'Mail invalide',
    less: 'Doit être égal ou inférieur à 10',
    more: 'Doit être égal ou supérieur à 10',
  },
  schoolinfo: {
    no_info: 'Aucune information',
  },
  price: {
    admin_fee: 'Frais d\'administration',
    total: 'Total:',
  },
  header_desktop: {
    loading: 'Chargement…',
  },
  spacees: {
    available_spaces: 'Espace disponible:',
  },
  no_participant: {
    disabled: 'désactivé',
    max: 'Nombre de participants atteints',
    no: 'Nombre de participants:',
  },
  checkout_status: {
    thank: 'MERCI',
    error: 'une errreur est arrivée',
    back: 'RETOURNER A LA COMMANDE',
    continue: 'CONTINUER',
  },
  client: {
    details: 'Vos coordonnées:',
    title: 'Titre',
    mrs: 'Madame',
    mr: 'Monsieur',
    name: 'Nom',
    firstname: 'Prénom*',
    enter_name: 'Entrez votre nom',
    surname: 'Nom de famille*',
    enter_surname: 'Entrez votre prénom',
    tel: 'Numéro de téléphone',
    enter_tel: 'Entrez votre numéro de téléphone',
    next: 'PROCHAIN',
  },
  preview: {
    review: 'Résumé de ta commande',
    participants: 'Participants',
    name: 'Nom',
    firstname: 'Prénom',
    surname: 'Nom de famille',
    age: 'âge',
    exp: 'niveau d\'expérience',
    details: 'Vos coordonnées:',
    email: 'Adresse mail:',
    tel: 'Numéro de téléphone',
    back: 'RETOUR',
    pay: 'Payer maintenant',
  },
  error: {
    try_again: 'Quelque chose c\'est mal passé… Merci de ressayer plus tard',
  },
  resort_select: {
    resort: 'Resort*',
  },
  item_list: {
    no_results: 'Aucun résultat',
  },
  success: {
    placed: 'Ta commande a été passée avec succès',
  },
  levels: {
    first_steps: 'première étape',
    beginner: 'Débutant',
    intermediate: 'Intermédiare',
    advanced: 'Avancée',
  },
  cart: {
    empty: 'Le panier est vide',
  },
  api_activity: {
    Snowboard: 'Snowboard',
    'Cross-country': 'Cross-country',
    'Ice Climbing': 'Escalade sur glace',
    Snowshoes: 'Raquette à neige',
    Telemark: 'Télémark',
    'Ice Skating': 'Patinage sur glace',
    SKI: 'SKI',
    Any: 'N\'importe lequel',
  },
  api_speciality: {
    Racing: 'Course',
    'Ice Hockey': 'Hockey sur glace',
    'Cat skiing': 'Cat skiing',
    Standard: 'Standard',
    Heliskiing: 'Héliski',
    'Figure skating': 'Patinage artistique',
    Skating: 'Patinage',
    Any: 'N\'importe lequel',
    Handicap: 'Handicap',
    Freeride: 'Freeride*',
    Guiding: 'Guiding*',
    Freestyle: 'Freestyle',
    Classic: 'Classique',
    Touring: 'Tourisme',
  },
  api_type: {
    Any: 'N\'importe lequel',
    Private: 'individuelle',
    Group: 'groupe',
  },
};

export default frTranslations;
