import fetch from 'cross-fetch';
import { api, subdomain } from '../../config';
import * as c from '../../constants/Api';

const receiveResorts = (json) => ({
  type: c.API_RESORTS_SUCCESS,
  payload: json,
});

export const addSelectedResort = (json) => ({
  type: c.SELECT_RESORT_SUCCESS,
  payload: json,
});

export const fetchResorts = () => {
  const endpoint = `${api}/resort/active/${subdomain}`;
  return (
    (dispatch) => (
      fetch(endpoint)
        .then((res) => res.json())
        .then((json) => dispatch(receiveResorts(json)))
    )
  );
};
