const trTranslations = {
  buyer: {
    participant: 'Katılımcı #',
    participant_info: 'Aktivite ve uzmanlık seçiniz',
    select: 'Seçiniz…',
    name: 'Ad*',
    firstname: 'ilk adı*',
    enter_name: 'Adınızı giriniz',
    surname: 'Soyad',
    enter_surname: 'Soyadınızı giriniz',
    age: 'Yaş',
  },
  daypicker: {
    no_available_dates: 'Ders tercihlerine uyan uygun tarih yok',
    select_activity: 'ilk önce aktivite seçiniz',
    select_speciality: 'ilk önce uzmanlık seçiniz',
    fill_participants: 'ilk önce katılımcı kısmını doldurunuz',
  },
  footer: {
    get_in_touch: 'iletişime geçmek',
    address: 'Adres',
    phone: 'Telefon',
    email: 'Email adres',
    map: 'Konum',
  },
  header: {
    checkout: 'ödeme',
  },
  item_extra_content: {
    add_to_cart: 'Sepete eklemek',
    product_removed: 'ürün çıkarma',
    product_added: 'ürün ekleme',
    remove: 'çıkarmak',
    day: 'Gün',
  },
  lesson: {
    remove: 'çıkarmak',
    prefered_language: 'Tercih edilen dil',
    activity: 'Aktivite',
    speciality: 'Uzmanlık',
    participants_info: 'Katılımcı bilgisi',
    number_of_participants: 'Katılımcı sayısı',
    max_no_participants: 'Maksimum katılımcı sayısı',
    calculating: 'Hesaplanıyor..',
  },
  not_found: {
    title: 'Olamaz !',
    description: 'Aradığınız sayfa bulunamadı',
    button: 'Geri dön !',
  },
  search: {
    resort: 'Tatil köyü',
    add_filters: 'Ek filtreler',
    clear_filters: 'Filtreleri temizle',
    button: 'Ara',
    activity: 'Aktivite',
    speciality: 'Uzmanlık',
    start_date: 'Başlangıç tarihi',
    end_date: 'Bitiş tarihi',
    language: 'Dil',
    type: 'Tip',
    age: 'Yaş',
  },
  validators: {
    required: 'Bu alan gereklidir',
    phone: 'Geçersiz telefon numarası',
    email: 'geçersiz posta adresi',
    less: '10\'a eşit veya daha küçük olmalıdır',
    more: '10\'a eşit veya daha fazla olmalıdır',
  },
  schoolinfo: {
    no_info: 'Bilgi yok',
  },
  price: {
    admin_fee: 'idari ücret',
    total: 'Toplam',
  },
  header_desktop: {
    loading: 'yükleniyor…',
  },
  spacees: {
    available_spaces: 'Müsait alanlar:',
  },
  no_participant: {
    disabled: 'Engelli',
    max: 'Maksimum katılımcı sayısına ulaşıldı',
    no: 'Katılımcı sayısı',
  },
  checkout_status: {
    thank: 'teşekkürler',
    error: 'Bir şey ters gitti',
    back: 'ödeme sayfasına geri dön',
    continue: 'Devam et',
  },
  client: {
    details: 'Bilgileriniz',
    title: 'Unvan',
    mrs: 'Bayan',
    mr: 'Bay',
    name: 'Ad',
    firstname: 'ilk adı',
    enter_name: 'Adınızı giriniz',
    surname: 'Soyad',
    enter_surname: 'Soyadınızı giriniz',
    tel: 'Telefon numarası',
    enter_tel: 'Telefon numaranızı giriniz',
    next: 'Sonraki',
  },
  preview: {
    review: 'Siparişinizi gözden geçirin',
    participants: 'Katılımcılar',
    name: 'Ad',
    firstname: 'ilk adı',
    surname: 'Soyad',
    age: 'Yaş',
    exp: 'Tecrübe seviyeniz',
    details: 'Bilgileriniz',
    email: 'E posta',
    tel: 'Telefon numarası',
    back: 'geri dön !',
    pay: 'şimdi öde !',
  },
  error: {
    try_again: 'Bir şey ters gitti, lütfen sonra bir daha deneyin',
  },
  resort_select: {
    resort: 'Tatil köyü',
  },
  item_list: {
    no_results: 'Sonuç bulunamadı',
  },
  success: {
    placed: 'Siparişiniz başarıyla verildi',
  },
  levels: {
    first_steps: 'Ilk adımlar',
    beginner: 'Acemi',
    intermediate: 'Orta düzey',
    advanced: 'Ieri düzey',
  },
  cart: {
    empty: 'Kart boş',
  },
  api_activity: {
    Snowboard: 'Kayak',
    'Cross-country': 'Cross country',
    'Ice Climbing': 'Buz tırmanışı',
    Snowshoes: 'Kar ayakkabısı',
    Telemark: 'Telemark',
    'Ice Skating': 'Buz pateni',
    SKI: 'Kayak takımı',
    Any: 'Herhangi',
  },
  api_speciality: {
    Racing: 'Yarış',
    'Ice Hockey': 'Buz hokeyi',
    'Cat skiing': 'Cat skiing',
    Standard: 'Standart',
    Heliskiing: 'Heliskiing',
    'Figure skating': 'Artistik buz pateni',
    Skating: 'Paten kaymak',
    Any: 'Herhangi',
    Handicap: 'Engel',
    Freeride: 'Bedava sürüş',
    Guiding: 'Rehberlik',
    Freestyle: 'Serbest stil',
    Classic: 'Klasik',
    Touring: 'Turlamak',
  },
  api_type: {
    Any: 'Herhangi',
    Private: 'birey',
    Group: 'grup',
  },
};

export default trTranslations;
