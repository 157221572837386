// Lessons
export const API_LESSONS_REQUEST = 'LESSONS_GET_REQUEST';
export const API_LESSONS_SUCCESS = 'LESSONS_GET_SUCCESS';
export const API_LESSONS_FAILURE = 'LESSONS_GET_FAILURE';

export const SET_DATES = 'SET_DATES';
export const UPDATE_PRICE_SUCCESS = 'UPDATE_PRICE_SUCCESS';
export const UPDATE_PRICE_FAIL = 'UPDATE_PRICE_FAIL';

export const SAVE_BUYERS_SUCCESS = 'BUYERS_SAVE_SUCCESS';

export const API_ACCOUNT_REQUEST = 'ACCOUNT_GET_REQUEST';
export const API_ACCOUNT_SUCCESS = 'ACCOUNT_GET_SUCCESS';
export const API_ACCOUNT_FAILURE = 'ACCOUNT_GET_FAILURE';

// Resorts
export const API_RESORTS_REQUEST = 'RESORTS_GET_REQUEST';
export const API_RESORTS_SUCCESS = 'RESORTS_GET_SUCCESS';
export const API_RESORTS_FAILURE = 'RESORTS_GET_FAILURE';

export const SELECT_RESORT_SUCCESS = 'RESORTS_SELECT_SUCCESS';

// Activities
export const API_ACTIVITIES_REQUEST = 'ACTIVITIES_GET_REQUEST';
export const API_ACTIVITIES_SUCCESS = 'ACTIVITIES_GET_SUCCESS';
export const API_ACTIVITIES_FAILURE = 'ACTIVITIES_GET_FAILURE';

// Specialities
export const API_SPECIALITIES_REQUEST = 'SPECIALITIES_GET_REQUEST';
export const API_SPECIALITIES_SUCCESS = 'SPECIALITIES_GET_SUCCESS';
export const API_SPECIALITIES_FAILURE = 'SPECIALITIES_GET_FAILURE';

export const SELECT_SPECIALITIES_SUCCESS = 'SPECIALITIES_SELECT_SUCCESS';

// Languages
export const API_LANGUAGES_REQUEST = 'LANGUAGES_GET_REQUEST';
export const API_LANGUAGES_SUCCESS = 'LANGUAGES_GET_SUCCESS';
export const API_LANGUAGES_FAILURE = 'LANGUAGES_GET_FAILURE';

export const SELECT_LANGUAGES_SUCCESS = 'LANGUAGES_SELECT_SUCCESS';

// Type
export const SELECT_TYPE_SUCCESS = 'TYPE_SELECT_SUCCESS';

// Age
export const SELECT_AGE_SUCCESS = 'AGE_SELECT_SUCCESS';

// Dates
export const SELECT_TIME_FROM_DATE_SUCCESS = 'TIME_FROM_DATE_SELECT_SUCCESS';
export const SELECT_TIME_TO_DATE_SUCCESS = 'TIME_TO_DATE_SELECT_SUCCESS';

// Filter
export const SET_FILTER_SUCCESS = 'FILTER_SET_SUCCESS';

// Checkout
export const SET_LESSON_PRICE = 'LESSON_PRICE_SUCCESS';
export const RESIZE = 'RESIZE';
