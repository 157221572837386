const deTranslations = {
  buyer: {
    participant: 'Teilnehmer #',
    participant_info: 'Wählen Sie zunächst Tätigkeit und Fachgebiet',
    select: 'Wählen...',
    name: 'Name*',
    firstname: 'Vorname*',
    enter_name: 'Geben Sie Ihren Namen ein',
    surname: 'Nachname',
    enter_surname: 'Geben Sie IhrenNachnamen ein',
    age: 'Alter*',
  },
  daypicker: {
    no_available_dates: '*Keine verfügbaren Termine für die gewünschte Lektionen',
    select_activity: '*Wählen Sie zunächst eine Aktivität',
    select_speciality: '*Wählen Sie zunächst ein Fachgebiet',
    fill_participants: '*Füllen Sie zunächst die Teilnehmer aus',
  },
  footer: {
    get_in_touch: 'KONTAKT AUFNEHMEN',
    address: 'Adresse',
    phone: 'Telefon',
    email: 'Emailadresse',
    map: 'Lageplan',
  },
  header: {
    checkout: 'Kasse',
  },
  item_extra_content: {
    add_to_cart: 'ZUM EINKAUFSWAGEN HINZUFÜGEN',
    product_removed: 'Artikel entfernt',
    product_added: 'Artikel hinzugefügt',
    remove: 'ENTFERNEN',
    day: 'Tag',
  },
  lesson: {
    remove: 'Entfernen',
    prefered_language: 'Bevorzugte Sprache',
    activity: 'Aktivität*',
    speciality: 'Fachgebiet',
    participants_info: 'Teilnehmerinformation:',
    number_of_participants: 'Teilnehmeranzahl',
    max_no_participants: '*Maximale Anzahl an Teilnehmern',
    calculating: 'Berechne...',
  },
  not_found: {
    title: 'Hoppla',
    description: 'Die von Ihnen gesuchte Seite konnte nicht gefunden werden',
    button: 'GEHE ZURÜCK',
  },
  search: {
    resort: 'Ferienort',
    add_filters: 'Zusätzliche Filter',
    clear_filters: 'Filter löschen',
    button: 'SUCHEN',
    activity: 'Aktivität',
    speciality: 'Fachgebiet',
    start_date: 'Startdatum',
    end_date: 'Enddatum',
    language: 'Sprache',
    type: 'Typ',
    age: 'Alter*',
  },
  validators: {
    required: 'Dieses Feld ist erforderlich',
    phone: 'Ungültige Telefonnummer',
    email: 'Ungültige Emailadresse',
    less: 'Muss kleiner gleich 10 sein',
    more: 'Muss größer gleich 10 sein',
  },
  schoolinfo: {
    no_info: 'Keine Information',
  },
  price: {
    admin_fee: 'Verwaltungsgebühr',
    total: 'Gesamt:',
  },
  header_desktop: {
    loading: 'Lade...',
  },
  spacees: {
    available_spaces: 'Freie Plätze:',
  },
  no_participant: {
    disabled: 'Behinderte',
    max: 'Maximale Teilnehmerzahl erreicht',
    no: 'Teilnehmeranzahl',
  },
  checkout_status: {
    thank: 'DANKE',
    error: 'ETWAS IST SCHIEF GEGANGEN',
    back: 'ZURÜCK ZUR KASSE',
    continue: 'WEITER',
  },
  client: {
    details: 'Ihre Angaben:',
    title: 'Titel*',
    mrs: 'Frau',
    mr: 'Herr',
    name: 'Name*',
    firstname: 'Vorname*',
    enter_name: 'Geben Sie Ihren Namen ein',
    surname: 'Nachname',
    enter_surname: 'Geben Sie Ihren Nachnamen ein',
    tel: 'Telefonnummer*',
    enter_tel: 'Geben Sie Ihre Telefonnummer ein',
    next: 'NÄCHSTES',
  },
  preview: {
    review: 'Überprüfen Sie Ihre Bestellung',
    participants: 'Teilnehmer',
    name: 'Name',
    firstname: 'Vorname',
    surname: 'Nachname',
    age: 'Alter',
    exp: 'Erfahrungsstufe',
    details: 'Ihre Angaben:',
    email: 'Emailadresse:',
    tel: 'Telefonnummer',
    back: 'ZURÜCK',
    pay: 'JETZT BEZAHLEN',
  },
  error: {
    try_again: 'Ein Fehler ist aufgetreten... Bitte versuchen Sie es später noch einmal',
  },
  resort_select: {
    resort: 'Urlaubsort',
  },
  item_list: {
    no_results: 'Keine Ergebnisse',
  },
  success: {
    placed: 'Ihre Bestellung wurde erfolgreich aufgegeben',
  },
  levels: {
    first_steps: 'erste Schritte',
    beginner: 'Anfänger',
    intermediate: 'Mittelstufe',
    advanced: 'Fortgeschrittene',
  },
  cart: {
    empty: 'Einkaufswagen ist leer',
  },
  api_activity: {
    Snowboard: 'Snowboard',
    'Cross-country': 'Langlauf',
    'Ice Climbing': 'Eisklettern',
    Snowshoes: 'Schneeschuhe',
    Telemark: 'Telemark',
    'Ice Skating': 'Schlittschuhlaufen',
    SKI: 'SKI',
    Any: 'Alles',
  },
  api_speciality: {
    Racing: 'Rennsport',
    'Ice Hockey': 'Eishocky',
    'Cat skiing': 'Cat skiing',
    Standard: 'Standard',
    Heliskiing: 'Heli-Skifahren',
    'Figure skating': 'Eiskunstlauf',
    Skating: 'Schlittschuhlaufen',
    Any: 'Alles',
    Handicap: 'Handicap',
    Freeride: 'Freeride',
    Guiding: 'Führen',
    Freestyle: 'Freistil',
    Classic: 'Klassisch',
    Touring: 'Tourenfahren',
  },
  api_type: {
    Any: 'Alles',
    Private: 'einzelperson',
    Group: 'gruppe',
  },
};

export default deTranslations;
