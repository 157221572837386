import React from 'react';
import i18n from 'i18next';
import PropTypes from 'prop-types';

const Price = ({
  administrationFee,
  currency,
  price,
}) => (
  <div className="checkout-footer">
    <div className="checkout-footer__title">
      {i18n.t('price.total')}
    </div>
    <div className="checkout-footer__price">
      <div className="checkout-footer__price__container">
        {price}
        &nbsp;
        {currency}
      </div>
      {!!administrationFee && (
        <div>
          {i18n.t('price.admin_fee')}
          &nbsp;
          {administrationFee}
          &nbsp;
          {currency}
        </div>
      )}
    </div>
  </div>
);

Price.propTypes = {
  administrationFee: PropTypes.string,
  currency: PropTypes.string,
  price: PropTypes.string,
};

export default Price;
