const esTranslations = {
  buyer: {
    participant: 'Partcicipante #',
    participant_info: 'Seleccionar una actividad y especialidad primero',
    select: 'Seleccionar una actividad y especialidad primero',
    name: 'Nombre*',
    firstname: 'Nombre de pila*',
    enter_name: 'Ingrese su nombre',
    surname: 'Apellido',
    enter_surname: 'Ingrese su apellido',
    age: 'Edad*',
  },
  daypicker: {
    no_available_dates: 'No hay fechas disponibles para los dias de leccion elegidos',
    select_activity: 'Seleccionar actividad primero',
    select_speciality: 'Seleccionar especialidad primero',
    fill_participants: 'Completar los participantes primero',
  },
  footer: {
    get_in_touch: 'Mantenerse en contacto',
    address: 'Direccion',
    phone: 'Telefono',
    email: 'Direccion de Email',
    map: 'Ubicacion en el mapa',
  },
  header: {
    checkout: 'Checkout',
  },
  item_extra_content: {
    add_to_cart: 'Añadir al carrito',
    product_removed: 'Producto removido',
    product_added: 'Producto agregado',
    remove: 'Quitar',
    day: 'Dia',
  },
  lesson: {
    remove: 'Quitar',
    prefered_language: 'Idioma preferido',
    activity: 'Actividad',
    speciality: 'Especialidad',
    participants_info: 'Informacion de los participantes',
    number_of_participants: 'Numero de participantes',
    max_no_participants: 'Maximo numero de participantes',
    calculating: 'Calculando…',
  },
  not_found: {
    title: 'ups',
    description: 'La pagina que esta buscando, no puede ser encontrada',
    button: 'volver',
  },
  search: {
    resort: 'Resort',
    add_filters: 'Filtros adicionales',
    clear_filters: 'Limpiar Filtros',
    button: 'Buscar',
    activity: 'Actividad',
    speciality: 'Espacialidad',
    start_date: 'Fecha de comienzo',
    end_date: 'Fecha de finalizacion',
    language: 'idioma preferido',
    type: 'Tipo',
    age: 'Edad',
  },
  validators: {
    required: 'Este campo es requerido',
    phone: 'Telefono invalido',
    email: 'Email invalido',
    less: 'Debe ser igual o menor que 10',
    more: 'Bede ser igual o mas que 10',
  },
  schoolinfo: {
    no_info: 'No hay informacion',
  },
  price: {
    admin_fee: 'Libre administracion',
    total: 'Total',
  },
  header_desktop: {
    loading: 'Cargando',
  },
  spacees: {
    available_spaces: 'Espacios permitidos',
  },
  no_participant: {
    disabled: 'Desabilitado',
    max: 'Maximo numero de participantes permitidos',
    no: 'Numero de participantes',
  },
  checkout_status: {
    thank: 'Gracias',
    error: 'Algo salio mal',
    back: 'Volver al checkout',
    continue: 'Continuar',
  },
  client: {
    details: 'Sus datos',
    title: 'Titulo',
    mrs: 'Sra',
    mr: 'Sra',
    name: 'Nombre',
    firstname: 'Nombre de pila*',
    enter_name: 'Infrese su nombre',
    surname: 'Apellido',
    enter_surname: 'Ingrese su apellido',
    tel: 'Numero de telefono',
    enter_tel: 'Infresar su numero de telefono',
    next: 'Siguiente',
  },
  preview: {
    review: 'Revise su pedido',
    participants: 'Participantes',
    name: 'Nombre',
    firstname: 'Nombre de pila',
    surname: 'Apellido',
    age: 'Edad',
    exp: 'Nivel de experiencia',
    details: 'Sus datos',
    email: 'Email invalido',
    tel: 'Numero de telefono',
    back: 'Atrás',
    pay: 'Pagar ahora',
  },
  error: {
    try_again: 'Algo fue mal..por favor intente de nuevo mas tarde',
  },
  resort_select: {
    resort: 'Resort',
  },
  item_list: {
    no_results: 'No hay rsultados',
  },
  success: {
    placed: 'Su orden fue completada con éxito',
  },
  levels: {
    first_steps: 'Primer paso',
    beginner: 'Principiante',
    intermediate: 'Intermedio',
    advanced: 'Avanzado',
  },
  cart: {
    empty: 'El carrito esta vacío',
  },
  api_activity: {
    Snowboard: 'Snowboard',
    'Cross-country': 'A campo traviesa',
    'Ice Climbing': 'Escalada en hielo',
    Snowshoes: 'Zapatos para nieve',
    Telemark: 'Telemarketing',
    'Ice Skating': 'Patinaje sobre hielo',
    SKI: 'Ski',
    Any: 'Gun',
  },
  api_speciality: {
    Racing: 'Carrera',
    'Ice Hockey': 'Hockey sobre hielo',
    'Cat skiing': 'Cat skiing',
    Standard: 'Standad',
    Heliskiing: 'Heliskiing',
    'Figure skating': 'Patinaje artistico',
    Skating: 'Patinaje artistico',
    Any: 'Ningun',
    Handicap: 'Desventaja',
    Freeride: 'Paseo libre',
    Guiding: 'Guia',
    Freestyle: 'Estilo libre',
    Classic: 'Clasico',
    Touring: 'Turismo',
  },
  api_type: {
    Any: 'Gun',
    Private: 'individual',
    Group: 'grupo',
  },
};

export default esTranslations;
