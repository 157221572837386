import fetch from 'cross-fetch';
import { get } from 'lodash';
import { api, subdomain } from '../../config';
import * as c from '../../constants/Api';

const receiveSpecialities = (json) => ({
  type: c.API_SPECIALITIES_SUCCESS,
  payload: json,
});

export const addSelectedSpecialities = (json) => ({
  type: c.SELECT_SPECIALITIES_SUCCESS,
  payload: json,
});

export const fetchSpecialities = (endpoint) => (
  (dispatch) => (
    fetch(endpoint)
      .then((res) => res.json())
      .then((json) => dispatch(receiveSpecialities(json)))
  )
);

const shouldFetchSpecialities = (state) => (
  get(state, 'specialities', true)
);

export const fetchSpecialitiesIfNeeded = () => {
  const endpoint = `${api}/speciality/${subdomain}`;
  return (dispatch, getState) => {
    if (shouldFetchSpecialities(getState())) {
      return dispatch(fetchSpecialities(endpoint));
    }
    return Promise.resolve();
  };
};
