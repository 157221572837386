import * as c from '../../constants/Redux';

const checkoutStatus = (state = {}, action) => {
  switch (action.type) {
    case c.REDUX_CHECKOUT_ERROR: {
      return {
        ...state,
        error: action.error,
      };
    }
    case c.REDUX_CHECKOUT_CLEAR:
      return {};
    default:
      return state;
  }
};

export default checkoutStatus;
