const jpTranslations = {
  buyer: {
    participant: '参加者 #',
    participant_info: 'アクティビティとスペシャリティを選択してください',
    select: '選択…',
    name: 'お名前*',
    firstname: 'ファーストネーム*',
    enter_name: 'お名前を入力してください',
    surname: '姓',
    enter_surname: '姓を入力してください',
    age: '年齢*',
  },
  daypicker: {
    no_available_dates: '*ご希望に合うレッスン日程がありません',
    select_activity: '*アクティビティを選択してください',
    select_speciality: '*スペシャリティを選択してください',
    fill_participants: '*参加者情報を入力してください',
  },
  footer: {
    get_in_touch: 'お問い合わせ',
    address: '住所',
    phone: '電話番号',
    email: 'メールアドレス',
    map: 'ロケーションマップ',
  },
  header: {
    checkout: '購入する',
  },
  item_extra_content: {
    add_to_cart: 'カートに追加',
    product_removed: '削除されました',
    product_added: '追加されました',
    remove: '削除',
    day: '日',
  },
  lesson: {
    remove: '削除',
    prefered_language: '言語選択*',
    activity: 'アクティビティ*',
    speciality: 'スペシャリティ*',
    participants_info: '参加者情報',
    number_of_participants: '参加者の人数',
    max_no_participants: '参加者の最大人数',
    calculating: '計算しています…',
  },
  not_found: {
    title: 'おっと！',
    description: 'お探しのページが見つかりません',
    button: '戻る',
  },
  search: {
    resort: 'リゾート',
    add_filters: '絞り込みを追加',
    clear_filters: '絞り込みをリセット',
    button: '検索',
    activity: 'アクティビティ',
    speciality: 'スペシャリティ',
    start_date: '開始日',
    end_date: '終了日',
    language: '言語',
    type: '種類',
    age: '年齢',
  },
  validators: {
    required: 'この項目は入力が必須です',
    phone: '無効な電話番号です',
    email: '無効なメールアドレスです',
    less: '上限人数10人',
    more: '最低人数10人',
  },
  schoolinfo: {
    no_info: '情報がありません',
  },
  price: {
    admin_fee: '手数料',
    total: '合計:',
  },
  header_desktop: {
    loading: 'Loading...',
  },
  spacees: {
    available_spaces: '空き状況:',
  },
  no_participant: {
    disabled: '障害者',
    max: '上限人数に達しました',
    no: '参加者の人数',
  },
  checkout_status: {
    thank: 'ありがとうございます',
    error: 'エラー',
    back: 'チェックアウト画面に戻る',
    continue: '続ける',
  },
  client: {
    details: 'お客様の詳細情報:',
    title: '敬称*',
    mrs: 'Mrs',
    mr: 'Mr',
    name: 'お名前*',
    firstname: 'ファーストネーム*',
    enter_name: 'お名前を入力してください',
    surname: '姓*',
    enter_surname: '姓を入力してください*',
    tel: '電話番号*',
    enter_tel: '電話番号を入力してください',
    next: '次へ',
  },
  preview: {
    review: '注文内容を確認',
    participants: '参加者',
    name: 'お名前',
    firstname: 'ファーストネーム',
    surname: '姓',
    age: '年齢',
    exp: '経験レベル',
    details: 'お客様の詳細情報:',
    email: 'メールアドレス:',
    tel: '電話番号',
    back: '戻る',
    pay: '今すぐ支払う',
  },
  error: {
    try_again: 'エラー　後ほどお試しください',
  },
  resort_select: {
    resort: 'リゾート',
  },
  item_list: {
    no_results: '検索結果なし',
  },
  success: {
    placed: 'ご注文を受け付けました',
  },
  levels: {
    first_steps: '未経験者',
    beginner: '初心者',
    intermediate: '中級者',
    advanced: '上級者',
  },
  cart: {
    empty: 'カートは空です',
  },
  api_activity: {
    Snowboard: 'スノーボード',
    'Cross-country': 'クロスカントリー',
    'Ice Climbing': 'アイスクライミング',
    Snowshoes: 'スノーシューイング',
    Telemark: 'テレマークスキー',
    'Ice Skating': 'アイススケート',
    SKI: 'スキー',
    Any: 'その他',
  },
  api_speciality: {
    Racing: 'レース',
    'Ice Hockey': 'アイスホッケー',
    'Cat skiing': 'Cat skiing',
    Standard: 'スタンダードスキー',
    Heliskiing: 'ヘリスキー',
    'Figure skating': 'フィギュアスケート',
    Skating: 'スケート',
    Any: 'その他',
    Handicap: 'ハンディキャップ',
    Freeride: 'フリーライド',
    Guiding: 'ガイディング',
    Freestyle: 'フリースタイル',
    Classic: 'クラシック',
    Touring: 'ツアー',
  },
  api_type: {
    Any: 'その他',
    Private: '個人',
    Group: '団体',
  },
};

export default jpTranslations;
