import {
  uniq, remove, map, forEach, uniqBy,
} from 'lodash';
import * as c from '../../constants/Api';

const mapActivities = (results) => {
  const activitiesNames = uniq(map(results, (speciality) => speciality.activity.name));
  const mappedActivities = {};
  // Mapping all activities by speciality
  forEach(activitiesNames, (activity) => {
    forEach(results, (speciality) => {
      if (speciality.activity.name === activity) {
        mappedActivities[activity] = {
          ...mappedActivities[activity],
          [speciality.name]: speciality.id,
        };
      }
    });
  });
  // Adding all specialities to Any property
  forEach(mappedActivities, (activity) => {
    const allIds = [];
    forEach(activity, (speciality) => {
      allIds.push(speciality);
    });
    activity.AllAny = allIds; // eslint-disable-line
  });
  remove(activitiesNames, (name) => name === 'Any');
  return { mappedActivities, activitiesNames };
};

const mapSpecialities = (results) => {
  const specialitiesNames = map(uniqBy(results, 'name'), 'name');
  const specialitiesObject = { AllAny: {} };
  forEach(results, (speciality) => {
    if (speciality.name === 'Any') {
      specialitiesObject.AllAny[speciality.activity.name] = speciality.id;
    }
    if (specialitiesObject[speciality.name]) {
      specialitiesObject[speciality.name] = [
        ...specialitiesObject[speciality.name],
        speciality.id,
      ];
    } else {
      specialitiesObject[speciality.name] = [speciality.id];
    }
  });
  remove(specialitiesNames, (name) => name === 'Any');
  return { specialitiesObject, specialitiesNames };
};

const entities = (state = {}, action) => {
  switch (action.type) {
    case c.API_SPECIALITIES_SUCCESS: {
      return {
        activities: mapActivities(action.payload).mappedActivities,
        specialities: mapSpecialities(action.payload).specialitiesObject,
        activitiesNames: mapActivities(action.payload).activitiesNames,
        specialitiesNames: mapSpecialities(action.payload).specialitiesNames,
      };
    }
    default:
      return state;
  }
};

export default entities;
