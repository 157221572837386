import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import i18n from 'i18next';

// Components
import Preview from './Preview';
import Form from './Form';

// Utils
import {
  formatPrice,
  getCartFromLocalStorage,
  mapCartItemsToGetLessonsPayload,
  mapApiLessonsToFormValues,
  isLessonValid,
  isClientValid,
  createOrder,
  mapLessonsToCreateOrderPayload,
  fetchLessonsForCart,
} from './utils';

// TODO: Remove
// Actions
import { removeFromCart } from '../../redux/actions/cartActions';

const Checkout = ({ account = { currency: '' } }) => {
  const [lessons, setLessons] = useState([]);
  const [client, setClient] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const removeExpiredLessons = (cart, apiLessons) => {
    if (!cart || cart.length === apiLessons.length) return;
    const apiLessonsIds = apiLessons.map((el) => el.id);
    const cartLessonsIds = cart.map((el) => el.id);
    const idsToRemove = cartLessonsIds.filter((el) => !apiLessonsIds.includes(el));
    idsToRemove.forEach((el) => window.store.dispatch(removeFromCart(el))); // TODO: Hacked dispatch
  };

  const getLessons = async () => {
    try {
      setIsLoading(true);
      const cart = getCartFromLocalStorage();
      const res = await fetchLessonsForCart({ ids: mapCartItemsToGetLessonsPayload(cart) });
      if (res.status !== 200) throw new Error();
      removeExpiredLessons(cart, res.body);
      setLessons(mapApiLessonsToFormValues(res.body, cart));
    } catch (err) {
      // Do nothing
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getLessons();
  }, []);

  const getTotalPrice = () => {
    const totalPrice = lessons.reduce((total, lesson) => total + Number.parseFloat(lesson.price), 0); // eslint-disable-line
    const administration = account.fee ? (totalPrice / 100) * account.fee : 0;
    return {
      total: formatPrice(totalPrice + administration),
      administration: formatPrice(administration),
    };
  };

  // Form is considered to be valid when both: client is valid and there're no invalid lessons
  const isValid = () => isClientValid(client) && !lessons.find((el) => !isLessonValid(el));

  const onSubmit = async () => {
    try {
      setIsSubmitting(true);
      const res = await createOrder(mapLessonsToCreateOrderPayload(lessons, client));
      if (res.status !== 200) throw new Error();
      localStorage.clear();
      window.location.href = res.body.paymentUrl;
    } catch (err) {
      // Do nothing
    } finally {
      setIsSubmitting(false);
    }
  };

  // TODO: Implement empty state
  if (!getCartFromLocalStorage() || !getCartFromLocalStorage().length) {
    return (
      <div className="checkout-content">
        <h1 style={{ textAlign: 'center', padding: '100px 0' }}>
          {i18n.t('cart.empty')}
        </h1>
      </div>
    );
  }

  return (
    <div className="checkout-content">
      <Route
        name="checkout"
        path="/checkout"
        exact
        render={((routeProps) => (
          <Form
            {...routeProps}
            account={account}
            client={client}
            isLoading={isLoading}
            isValid={isValid()}
            lessons={lessons}
            setClient={setClient}
            setLessons={setLessons}
            totalPrice={getTotalPrice().total}
            administrationFee={getTotalPrice().administration}
          />
        ))}
      />
      <Route
        name="review"
        path="/checkout/preview"
        render={(routeProps) => (
          <Preview
            {...routeProps}
            account={account}
            client={client}
            isValid={isValid()}
            lessons={lessons}
            onSubmit={onSubmit}
            totalPrice={getTotalPrice().total}
            administrationFee={getTotalPrice().administration}
            isSubmitting={isSubmitting}
          />
        )}
      />
    </div>
  );
};

Checkout.propTypes = {
  account: PropTypes.object, // eslint-disable-line
};

export default Checkout;
