import React from 'react';
import PropTypes from 'prop-types';
import ItemExtraContent from './ItemExtraContent';
import ImageAny from '../../assets/images/any.jpg';
import ImageGroup from '../../assets/images/group.jpg';

const Item = ({ item }) => {
  let image = null;
  const type = item.activityName || item.activity.name;

  if (type === 'Any') {
    image = ImageAny;
  } else {
    image = ImageGroup;
  }

  return (
    <div className="skic-item">
      <div className="skic-item__image-container">
        <img className="skic-item__image-container__image" src={item.image || image} alt="loading..." />
      </div>
      <div className="skic-item__content">
        <div className="skic-item__content__title">
          {item.name}
        </div>
        {item.description
        && (
        <div className="skic-item__content__description">
          {item.description}
        </div>
        )}
        <ItemExtraContent
          item={item}
        />
      </div>
    </div>
  );
};

Item.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    image: PropTypes.string,
    activityName: PropTypes.string,
    activity: PropTypes.shape({
      name: PropTypes.string,
    }),
  }),
};

export default Item;
