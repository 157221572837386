import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PropTypes } from 'prop-types';
import { includes, get } from 'lodash';
import i18n from 'i18next';

import SpacesDisplay from '../../components/Item/SpacesDisplay';
import LevelDisplay from '../../components/Item/LevelDisplay';
import TimeDisplay from '../../components/Item/TimeDisplay';
import DateDisplay from '../../components/Item/DateDisplay';
import ResortDisplay from '../../components/Item/ResortDisplay';
import IconLabel from '../../components/Item/IconLabel';
import ActivitySpeciality from '../../components/Item/ActivitySpeciality';
import { addToCart, removeFromCart } from '../../redux/actions/cartActions';
import { notifySuccess } from '../../redux/actions/notificationActions';
import { formatLanguages } from '../../utils/format';
import { lessonShape } from '../../models/shapes';

/* eslint-disable */
Date.prototype.stdTimezoneOffset = function () {
  var jan = new Date(this.getFullYear(), 0, 1);
  var jul = new Date(this.getFullYear(), 6, 1);
  return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
};

export const lessonType = type => type === 'individual' ? 'private' : type;

Date.prototype.isDstObserved = function () {
  return this.getTimezoneOffset() < this.stdTimezoneOffset();
};

export const getPricePerDay = (prices) => {
  let minPrice = prices[0].pricePerPerson;
  let minDays = prices[0].days;

  prices.forEach(x => {
    if (minDays > x.days) {
      minDays = x.days;
      minPrice = x.pricePerPerson;
    }
  });
  return minPrice;
}

/* eslint-enable */

class ItemExtraContent extends Component {
  onRemoveButtonClick(id) {
    const { removeFromCartAction, notifySuccessAction } = this.props;

    setTimeout(() => removeFromCartAction(id), 300);
    notifySuccessAction({}, i18n.t('item_extra_content.product_removed'));
  }

  onAddToCartButtonClick(id, productId) {
    const {
      addToCartAction,
      notifySuccessAction,
      filter,
      item,
    } = this.props;
    const { resorts, timeFrom, timeTo } = filter;
    const payloadForAction = {
      id,
      resorts,
      timeFrom,
      timeTo,
      type: item.type,
    };
    if (productId) {
      payloadForAction.productId = productId;
    }
    addToCartAction(payloadForAction);
    notifySuccessAction({}, i18n.t('item_extra_content.product_added'));
  }

  render() {
    const {
      item,
      cart,
      currency,
    } = this.props;

    return (
      <div className="item-extra-content">
        <div className="item-extra-content__lesson-info">
          <div className="item-extra-content__lesson-info__item">
            {item.items
              && (
              <DateDisplay
                type={item.type}
                dates={item.items}
              />
              )}
          </div>
          <div className="item-extra-content__lesson-info__item">
            <TimeDisplay
              timeFrom={item.timeFrom}
              timeTo={item.timeTo}
            />
          </div>
          <div className="item-extra-content__lesson-info__item">
            <IconLabel
              icon="type"
            >
              {lessonType(item.type) && lessonType(item.type)}
            </IconLabel>
          </div>
          <div className="item-extra-content__lesson-info__item">
            <ResortDisplay
              resortName={item.resortName || (item.resort && item.resort.name)}
            />
          </div>
          <div className="item-extra-content__lesson-info__item">
            <ActivitySpeciality
              activityName={item.activityName || (item.activity && item.activity.name) || 'Any'}
              speciality={item.speciality}
            />
          </div>
          <div className="item-extra-content__lesson-info__item">
            <IconLabel
              icon="language"
            >
              <span>{formatLanguages(item.languages)}</span>
            </IconLabel>
          </div>
          <div className="item-extra-content__lesson-info__item">
            <IconLabel icon="level">
              <LevelDisplay level={item.level} />
            </IconLabel>
          </div>
          <div className="item-extra-content__lesson-info__item">
            {item.type === 'group'
              && (
              <IconLabel icon="available-space">
                <SpacesDisplay item={item.items[0]} />
              </IconLabel>
              )}
          </div>
          <div className="item-extra-content__lesson-info__item">
            {item.type === 'group'
              && (
              <IconLabel icon="age">
                {`Group age: ${item.minAge} - ${item.maxAge}`}
              </IconLabel>
              )}
          </div>
        </div>
        <div className="skic-item__price-box">
          <div className="skic-item__price-box__price-container">
            <div className="skic-item__price-box__price-container__price">
              {item.price}
              {item.prices && getPricePerDay(item.prices)}
            </div>
            <div className="skic-item__price-box__price-container__currency">
              {currency}
            </div>
            <div className="skic-item__price-box__price-container__additional-text">
              /&nbsp;
              {i18n.t('item_extra_content.day')}
            </div>
          </div>
          <div className="skic-item__price-box__button-container">
            {!includes(cart.cartItems, item.id)
              ? (
                <button
                  onClick={() => this.onAddToCartButtonClick(item.id || item.guid, item.type === 'individual' ? item.product : undefined)}
                  className="skic-item__price-box__button-container__button"
                  type="button"
                >
                  <div className="skic-icon skic-icon__basket-white" />
                  {i18n.t('item_extra_content.add_to_cart')}
                </button>
              )
              : (
                <button
                  onClick={this.onRemoveButtonClick.bind(this, item.id)}
                  className="skic-item__price-box__button-container__ordered-item"
                  type="button"
                >
                  <div className="skic-icon skic-icon__uncheck" />
                  <div className="skic-item__price-box__button-container__ordered-item__button-text">
                    {i18n.t('item_extra_content.remove')}
                  </div>
                </button>
              )}
          </div>
        </div>
      </div>
    );
  }
}

ItemExtraContent.propTypes = {
  item: lessonShape,
  notifySuccessAction: PropTypes.func,
  addToCartAction: PropTypes.func,
  removeFromCartAction: PropTypes.func,
  cart: PropTypes.objectOf(PropTypes.array),
  currency: PropTypes.string,
  filter: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.actual,
    ]),
  ),
};

const mapDispatchToProps = (dispatch) => ({
  addToCartAction: bindActionCreators(addToCart, dispatch),
  notifySuccessAction: bindActionCreators(notifySuccess, dispatch),
  removeFromCartAction: bindActionCreators(removeFromCart, dispatch),
});

const mapStateToProps = (state) => ({
  cart: state.cart,
  filter: state.filter,
  currency: get(state.account, 'currency', ''),
});

export default connect(mapStateToProps, mapDispatchToProps)(ItemExtraContent);
