import React from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, Button } from 'semantic-ui-react';

import '../../utils/i18n';
import { useTranslation } from 'react-i18next';

const NotFound = () => {
  const { t } = useTranslation();
  const { goBack } = useHistory();
  const handleRedirection = () => {
    goBack();
  };

  return (
    <Grid>
      <Grid container centered>
        <Grid.Row centered className="notfound-header">
          {t('not_found.title')}
        </Grid.Row>
        <Grid.Row centered className="notfound-subheader">
          {t('not_found.description')}
        </Grid.Row>
        <Grid.Column width={4} />
        <Grid.Column width={4} className="ui centered">
          <Button fluid className="notfound-button" onClick={handleRedirection}>
            {t('not_found.button')}
          </Button>
        </Grid.Column>
        <Grid.Column width={4} />
      </Grid>
    </Grid>
  );
};

export default NotFound;
