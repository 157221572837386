import { useState } from 'react';

function useField({
  initialValue,
  validate = [],
  onChange,
  onPhoneChange,
  onSelect,
}) {
  const [value, setValue] = useState(initialValue || '');
  const [dirty, setDirty] = useState(false);

  const error = () => {
    if (!validate.length) return undefined;
    const getError = validate.find((fn) => !!fn(value));
    if (!getError) return undefined;
    return getError(value);
  };

  const change = (ob) => {
    if (onChange) {
      onChange(ob.target.value);
      setValue(ob.target.value);
    }
    if (onSelect) {
      onSelect(ob.value);
      setValue(ob.value);
    }
    if (onPhoneChange) {
      onPhoneChange(ob);
      setValue(ob);
    }
  };

  return {
    attributes: {
      onBlur: () => setDirty(true),
      onChange: change,
      value,
    },
    clear: () => setValue(''),
    dirty: () => dirty,
    error,
    value,
  };
}

export default useField;
