import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import i18n from 'i18next';

import flagUk from '../../assets/images/flags/uk-flag.jpg';
import flagDe from '../../assets/images/flags/de-flag.jpg';
import flagFr from '../../assets/images/flags/fr-flag.jpg';
import flagIt from '../../assets/images/flags/it-flag.jpg';
import flagCs from '../../assets/images/flags/cz-flag.jpg';
import flagSk from '../../assets/images/flags/sk-flag.jpg';
import flagBg from '../../assets/images/flags/bg-flag.jpg';
import flagPl from '../../assets/images/flags/pl-flag.jpg';
import flagRu from '../../assets/images/flags/ru-flag.jpg';
import flagRo from '../../assets/images/flags/ro-flag.jpg';
import flagSi from '../../assets/images/flags/si-flag.jpg';
import flagEs from '../../assets/images/flags/es-flag.jpg';
import flagHr from '../../assets/images/flags/hr-flag.jpg';
import flagNl from '../../assets/images/flags/nl-flag.jpg';
import flagFi from '../../assets/images/flags/fi-flag.jpg';
import flagSe from '../../assets/images/flags/se-flag.jpg';
import flagGr from '../../assets/images/flags/gr-flag.jpg';
import flagHu from '../../assets/images/flags/hu-flag.jpg';
import flagCn from '../../assets/images/flags/cn-flag.jpg';
import flagJp from '../../assets/images/flags/jp-flag.jpg';
import flagRs from '../../assets/images/flags/rs-flag.jpg';
import flagKr from '../../assets/images/flags/kr-flag.jpg';
import flagNo from '../../assets/images/flags/no-flag.jpg';
import flagTr from '../../assets/images/flags/tr-flag.jpg';

const languages = [
  { value: 'en', label: <img src={flagUk} height="30px" width="45px" alt="En" /> },
  { value: 'de', label: <img src={flagDe} height="30px" width="45px" alt="De" /> },
  { value: 'fr', label: <img src={flagFr} height="30px" width="45px" alt="Fr" /> },
  { value: 'it', label: <img src={flagIt} height="30px" width="45px" alt="It" /> },
  { value: 'cs', label: <img src={flagCs} height="30px" width="45px" alt="Cs" /> },
  { value: 'sk', label: <img src={flagSk} height="30px" width="45px" alt="Sk" /> },
  { value: 'bg', label: <img src={flagBg} height="30px" width="45px" alt="Bg" /> },
  { value: 'pl', label: <img src={flagPl} height="30px" width="45px" alt="Pl" /> },
  { value: 'ru', label: <img src={flagRu} height="30px" width="45px" alt="Ru" /> },
  { value: 'ro', label: <img src={flagRo} height="30px" width="45px" alt="Ro" /> },
  { value: 'sl', label: <img src={flagSi} height="30px" width="45px" alt="Si" /> },
  { value: 'es', label: <img src={flagEs} height="30px" width="45px" alt="Es" /> },
  { value: 'hr', label: <img src={flagHr} height="30px" width="45px" alt="Hr" /> },
  { value: 'nl', label: <img src={flagNl} height="30px" width="45px" alt="Nl" /> },
  { value: 'fi', label: <img src={flagFi} height="30px" width="45px" alt="Fi" /> },
  { value: 'sv', label: <img src={flagSe} height="30px" width="45px" alt="Se" /> },
  { value: 'el', label: <img src={flagGr} height="30px" width="45px" alt="Gr" /> },
  { value: 'hu', label: <img src={flagHu} height="30px" width="45px" alt="Hu" /> },
  { value: 'zh', label: <img src={flagCn} height="30px" width="45px" alt="Cn" /> },
  { value: 'ja', label: <img src={flagJp} height="30px" width="45px" alt="Jp" /> },
  { value: 'sr', label: <img src={flagRs} height="30px" width="45px" alt="Rs" /> },
  { value: 'ko', label: <img src={flagKr} height="30px" width="45px" alt="Kr" /> },
  { value: 'no', label: <img src={flagNo} height="30px" width="45px" alt="No" /> },
  { value: 'tr', label: <img src={flagTr} height="30px" width="45px" alt="Tr" /> },
];

class LanguageSwitcherSelector extends React.Component {
  changeLanguageHandler = (code) => {
    i18n.changeLanguage(code.value);
    window.location.reload();
  }

  render() {
    const { lang } = this.props;
    const DropdownIndicator = () => (
      <i aria-hidden="true" className="dropdown icon" />
    );

    return (
      <Select
        placeholder=""
        value="en"
        onChange={(lng) => this.changeLanguageHandler(lng)}
        options={languages}
        // onInputChange={this.changeLanguageHandler}
        // onFocus={() => this.handleFocus()}
        className={`Select language-selector-select lang-${lang}`}
        classNamePrefix="Select"
        isSearchable={false}
        components={{ DropdownIndicator }}
      />
    );
  }
}

LanguageSwitcherSelector.propTypes = {
  lang: PropTypes.string,
};

export default LanguageSwitcherSelector;
