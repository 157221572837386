const bgTranslations = {
  buyer: {
    participant: 'Участник #',
    participant_info: 'Първо изберете дейност и особеност',
    select: 'Избери…',
    name: 'Име*',
    firstname: 'Първо име*',
    enter_name: 'Въведете Вашето име',
    surname: 'Фамилия',
    enter_surname: 'Въведете Вашата Фамилия',
    age: 'Възраст*',
  },
  daypicker: {
    no_available_dates: '*Няма налични дати, съответстващи на предпочитанията за уроци',
    select_activity: '*Първо изберете дейност',
    select_speciality: '*Първо изберете особеност',
    fill_participants: '*Първо попълнете участниците',
  },
  footer: {
    get_in_touch: 'СВЪРЖЕТЕ СЕ',
    address: 'Адрес',
    phone: 'Телефон',
    email: 'Имейл',
    map: 'Карта с местоположение',
  },
  header: {
    checkout: 'Напускане',
  },
  item_extra_content: {
    add_to_cart: 'Добави в количката',
    product_removed: 'Продуктът е премахнат',
    product_added: 'Продуктът е добавен',
    remove: 'ПРЕМАХНИ',
    day: 'ден',
  },
  lesson: {
    remove: 'Премахни',
    prefered_language: 'Предпочитан език*',
    activity: 'Дейности*',
    speciality: 'Особености*',
    participants_info: 'Информация за участниците:',
    number_of_participants: 'Брой участници:',
    max_no_participants: '*Максимален брой участници:',
    calculating: 'Изчислява се…',
  },
  not_found: {
    title: 'Опа!',
    description: 'Страницата, която търсите, не може да бъде намерена',
    button: 'Върнете се обратно',
  },
  search: {
    resort: 'Посещение',
    add_filters: 'Допълнителни филтри',
    clear_filters: 'Изчисти филтри',
    button: 'ТЪРСЕНЕ',
    activity: 'Дейности',
    speciality: 'Особености',
    start_date: 'Начална дата',
    end_date: 'Крайна дата',
    language: 'Език',
    type: 'Вид',
    age: 'Възраст',
  },
  validators: {
    required: 'Това поле е задължително',
    phone: 'Невалиден телефон',
    email: 'Невалиден имейл',
    less: 'Трябва да е равно или по-малко от 10',
    more: 'Трябва да е равно или повече от 10',
  },
  schoolinfo: {
    no_info: 'Няма информация',
  },
  price: {
    admin_fee: 'Административна такса',
    total: 'Общо:',
  },
  header_desktop: {
    loading: 'Зареждане…',
  },
  spacees: {
    available_spaces: 'Налични места:',
  },
  no_participant: {
    disabled: 'неспособен',
    max: 'Достигнат е максималният брой участници',
    no: 'Брой участници',
  },
  checkout_status: {
    thank: 'БЛАГОДАРЯ ВИ',
    error: 'SOMETHING WENT WRONG',
    back: 'ОБРАТНО КЪМ ПРЕГЛЕД',
    continue: 'ПРОДЪЛЖЕТЕ',
  },
  client: {
    details: 'Вашите данни:',
    title: 'Заглавие*',
    mrs: 'Г-жа',
    mr: 'Г-н',
    name: 'Име*',
    firstname: 'Първо име*',
    enter_name: 'Въведете Вашето име',
    surname: 'Фамилия*',
    enter_surname: 'Въведете Вашата фамилия',
    tel: 'Телефонен номер*',
    enter_tel: 'Въведете Вашия телефонен номер',
    next: 'СЛЕДВАЩ',
  },
  preview: {
    review: 'Прегледайте поръчката си',
    participants: 'Участници',
    name: 'Име',
    firstname: 'Първо име',
    surname: 'Фамилия',
    age: 'Възраст',
    exp: 'Ниво на опит',
    details: 'Вашите данни:',
    email: 'Имейл:',
    tel: 'Телефонен номер:',
    back: 'ОБРАТНО',
    pay: 'ПЛАТЕТЕ СЕГА',
  },
  error: {
    try_again: 'Нещо се обърка.Моля опитайте по-късно',
  },
  resort_select: {
    resort: 'Посещение',
  },
  item_list: {
    no_results: 'Няма резултат',
  },
  success: {
    placed: 'Вашата поръчка е направена успешно',
  },
  levels: {
    first_steps: 'първи стъпки',
    beginner: 'начинаещ',
    intermediate: 'междинен',
    advanced: 'напреднал',
  },
  cart: {
    empty: 'Количката е празна',
  },
  api_activity: {
    Snowboard: 'Сноуборд',
    'Cross-country': 'Бягане',
    'Ice Climbing': 'Ледено катерене',
    Snowshoes: 'Снегоходки',
    Telemark: 'Телемаркиране',
    'Ice Skating': 'Фигурно пързаляне',
    SKI: 'Ски',
    Any: 'Всякакви',
  },
  api_speciality: {
    Racing: 'Рейсинг',
    'Ice Hockey': 'Хокей на лед',
    'Cat skiing': 'Cat skiing',
    Standard: 'Стандартен',
    Heliskiing: 'Хели-ски',
    'Figure skating': 'Фигурно пързаляне',
    Skating: 'Пързаляне с кънки',
    Any: 'Всякакви',
    Handicap: 'Хендикап',
    Freeride: 'Freeride',
    Guiding: 'Guiding',
    Freestyle: 'Свободен стил',
    Classic: 'Класически',
    Touring: 'Излет',
  },
  api_type: {
    Any: 'Всякакви',
    Private: 'индивидуални',
    Group: 'групи',
  },
};

export default bgTranslations;
