import PropTypes from 'prop-types';

export const activitiesShape = PropTypes.oneOfType([PropTypes.object, PropTypes.array]);
export const specialitiesShape = PropTypes.oneOfType([PropTypes.object, PropTypes.array]);

export const lessonShape = PropTypes.shape({
  // eslint-disable-next-line react/forbid-prop-types
  activities: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  activity: PropTypes.any,
  activityName: PropTypes.string,
  buyers: PropTypes.arrayOf(PropTypes.object),
  items: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]),
  languages: PropTypes.arrayOf(PropTypes.string),
  level: PropTypes.string,
  maxAge: PropTypes.number,
  minAge: PropTypes.number,
  name: PropTypes.string,
  specialityName: PropTypes.string,
  type: PropTypes.string,
});

export const orderShape = PropTypes.shape({
  // eslint-disable-next-line react/forbid-prop-types
  client: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  lessons: PropTypes.array,
});
