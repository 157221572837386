import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
  memo,
} from 'react';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getFormValues } from 'redux-form';
import Pager from 'react-pager';
import { Item as SemanticItem, Loader } from 'semantic-ui-react';
import { map } from 'lodash';
import moment from 'moment';
import {
  isCurrentPageFetching,
  getCurrentPageResults,
  getCurrentTotalResultsCount,
  getCurrentPageNumber,
} from '../../modules/redux-paginator';
import Item from './Item';
import { filterOutLessonsWithinDate, requestCustomLessonsPage } from '../../redux/actions/lessonActions';
import { getTotalPagesCount } from '../../utils/helpers';
import { formatDateQuery } from '../../utils/format';

const ItemList = ({
  pagination,
  lesson,
  filterOutLessonsWithinDateAction,
  searchValues,
  requestCustomLessonsPageAction,
  filterPayload,
}) => {
  const {
    pageSize,
    visiblePages,
    offer,
  } = useMemo(() => ({
    pageSize: 5,
    visiblePages: 3,
    offer: !!(window.location.search),
  }), []);
  const [{
    lessons,
    currentPage,
    loading,
    lessonsCount,
  }, setLessonsState] = useState({
    lessons: getCurrentPageResults(lesson, pagination, 'lessons'),
    lessonsCount: getCurrentTotalResultsCount(pagination, 'lessons'),
    currentPage: getCurrentPageNumber(pagination, 'lessons'),
    loading: isCurrentPageFetching(pagination, 'lessons'),
  });
  const itemListRef = useRef();

  useEffect(() => {
    setLessonsState({
      lessons: getCurrentPageResults(lesson, pagination, 'lessons'),
      lessonsCount: getCurrentTotalResultsCount(pagination, 'lessons'),
      currentPage: getCurrentPageNumber(pagination, 'lessons'),
      loading: isCurrentPageFetching(pagination, 'lessons'),
    });
  }, [pagination, lesson]);

  const handlePageChanged = (newPage) => {
    const offset = itemListRef.current.offsetTop - 100;
    window.scrollTo(0, offset);

    const pageNo = newPage + 1;
    if (offer && searchValues && searchValues.timeFrom && searchValues.timeTo) {
      const tFrom = formatDateQuery(searchValues.timeFrom);
      const tTo = formatDateQuery(searchValues.timeTo);
      filterOutLessonsWithinDateAction(pageNo, pageSize, tFrom, tTo);
    } else {
      const updatedPayload = filterPayload === undefined ? {
        timeFrom: moment().toISOString(),
        timeTo: moment().add(1, 'month').toISOString(),
      } : filterPayload;
      requestCustomLessonsPageAction(pageNo, pageSize, updatedPayload);
    }
  };

  const pagesCount = useMemo(() => getTotalPagesCount(lessonsCount, pageSize),
    [lessonsCount, pageSize]);

  return (
    <div
      className="transparent-list items-segment"
      ref={itemListRef}
    >
      {loading
        && (
        <div className="loader-container">
          <Loader
            inline="centered"
            active
            size="large"
          />
        </div>
        )}
      {!(lessons && !lessons.length && !loading)
        ? (
          <SemanticItem.Group>
            {map(lessons, (item, index) => (
              <Item
                key={index}
                item={item}
              />
            ))}
            {pagesCount > 1
              ? (
                <Pager
                  total={pagesCount}
                  current={currentPage - 1}
                  visiblePages={visiblePages}
                  titles={{
                    prev: <div className="skic-icon skic-icon__chevron-left" />,
                    prevSet: '...',
                    nextSet: '...',
                    next: <div className="skic-icon skic-icon__chevron-right" />,
                  }}
                  onPageChanged={handlePageChanged}
                />
              )
              : null}
          </SemanticItem.Group>
        )
        : (
          <div className="no-results">
            {i18n.t('item_list.no_results')}
          </div>
        )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  pagination: state.pagination,
  lesson: state.lesson,
  searchValues: getFormValues('search')(state),
  filterPayload: state.filter,
});

const mapDispatchToProps = (dispatch) => ({
  filterOutLessonsWithinDateAction: bindActionCreators(filterOutLessonsWithinDate, dispatch),
  requestCustomLessonsPageAction: bindActionCreators(requestCustomLessonsPage, dispatch),
});

ItemList.propTypes = {
  filterOutLessonsWithinDateAction: PropTypes.func,
  requestCustomLessonsPageAction: PropTypes.func,
  lesson: PropTypes.objectOf(PropTypes.object),
  pagination: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    params: PropTypes.object,
    // eslint-disable-next-line react/forbid-prop-types
    pages: PropTypes.object,
  }),
  searchValues: PropTypes.shape({
    timeFrom: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    timeTo: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
  }),
  filterPayload: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
  ])),
};

export default connect(mapStateToProps, mapDispatchToProps)(memo(ItemList));
