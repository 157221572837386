import {
  find, forEach, isEmpty, map, omit, uniqBy,
} from 'lodash';
import { levelsOptions } from '../options/Levels';
import { languagesOptions } from '../options/Languages';
import { formatOption } from './format';
import { findItem } from './helpers';
import { ymd } from './dates';

export const notNeededProperties = [
  'activities',
  'activityName',
  'description',
  'languages',
  'level',
  'price',
  'resortName',
  'specialityName',
  'timeRange',
];

// take only selected dates from fetched lesson.dates

export const filterByDates = (lesson, items) => {
  if (!lesson.dates) {
    throw new Error('No dates provided');
  }
  const lessonDates = lesson.dates.map((row) => ymd(row));

  if (items[0].type === 'individual') {
    return items[0].items.filter((row) => lessonDates.includes(row.date));
  }
  if (items[0].dates) {
    return items[0].dates.filter((date) => lessonDates.includes(ymd(date)));
  }

  let dateField = lesson.type === 'group' ? 'timeFrom' : 'date';
  if (!items[0].date) {
    dateField = 'timeFrom';
  }

  return items.filter((row) => lessonDates.includes(row[dateField].substring(0, 10)));
};

export const mapCartItemsToLessons = (
  cartItems, lessons, lessonsDirty, buyers, age,
) => {
  const returnItems = [];
  forEach(cartItems, (lesson) => {
    let item = find(lessons, { id: lesson });
    const buyer = find(buyers, { id: lesson });
    const itemDirty = find(lessonsDirty, { id: lesson });
    if (itemDirty) {
      item = itemDirty;
      returnItems.push({
        ...item,
      });
    } else if (buyer) {
      returnItems.push({
        ...item,
        clientAmount: buyer.clientAmount,
        buyers: buyer.buyers,
        language: { value: 'en', label: 'English' },
      });
    } else {
      let buyerValue = {
        level: item.level,
      };
      if (item.level !== 'any' && item.type === 'individual') {
        buyerValue = {
          ...find(levelsOptions(), { value: item.level }),
        };
      }

      returnItems.push({
        ...item,
        clientAmount: 1,
        buyers: [
          {
            ...buyerValue,
          },
        ],
      });
      if (age) {
        let updatedBuyers = [];
        forEach(returnItems, (item2, key) => {
          updatedBuyers = item2.buyers;
          if (item2.type === 'group') {
            forEach(updatedBuyers, (buyer2, index) => {
              updatedBuyers[index].age = age;
            });
          }
          returnItems[key].buyers = updatedBuyers;
        });
      }
    }
  });
  return returnItems;
};

export const findId = (lessons, id) => {
  let index;
  if (lessons && id) {
    for (let i = 0; i < lessons.length; i += 1) {
      if (lessons[i].id === id) {
        index = i;
        break;
      }
    }
  }
  return index;
};

export const createOptionsFrom = (
  values, entities = null, activityId,
) => {
  const options = [];
  values.forEach((value) => {
    options.push({
      label: value,
      value,
      id: entities && entities.length > 1 && (
        activityId
          ? (
            entities.filter((r) => r.activity.id === activityId).filter(
              (r) => r.name === value,
            )[0].id
          )
          : entities.filter((row) => row.name === value)[0].id),
    });
  });
  return options;
};

export const createSpecialityOptionsFrom = (values, specialities = null) => {
  if (!values) return [];

  return values.map((val) => {
    const { id } = specialities.find((r) => r.name === val);
    return {
      value: val,
      label: val,
      id,
    };
  });
};

export const mapLanguagesToOptions = (languages) => {
  const languagesReturn = [];
  forEach(languages, (language) => {
    const option = find(languagesOptions, { value: language });
    if (!languagesReturn.includes(option)) {
      languagesReturn.push(option);
    }
  });

  return languagesReturn;
};

const mapBuyersToLesson = (buyersData) => {
  if (buyersData[0].name) {
    return buyersData;
  }

  const buyersReturn = [];
  forEach(buyersData, (buyer) => {
    buyersReturn.push({
      level: formatOption(buyer.value, 'value'),
    });
  });
  return buyersReturn;
};

const mapClientToPayload = (clientData) => {
  const { phoneNumber } = clientData;

  return {
    ...omit(clientData, 'phoneNumber'),
    address: [],
    phoneNumbers: [{
      phoneNumber,
    }],
    gender: formatOption(clientData.gender, 'value'),
  };
};

const mapLessonToPayload = (lessonData) => ({
  ...omit(lessonData, notNeededProperties),
  activity: typeof lessonData.activity === 'number'
    ? lessonData.activity
    : formatOption(lessonData.activity, 'id'),
  buyers: mapBuyersToLesson(lessonData.buyers),
  guid: lessonData.id,
  language: formatOption(lessonData.language, 'value'),
  paid: false,
  speciality: formatOption(lessonData.speciality, 'id'),
});

export const getActivityIDFromSpeciality = (activities, specialityId) => {
  const activity = Object.values(activities).filter((spec) => {
    if (!specialityId) throw new Error('No activity id');
    const specialityArr = spec.specialities.filter((r) => r.id === specialityId);
    return specialityArr[0] ? specialityArr[0].id : false;
  });
  return activity && activity[0].id;
};

export const mapDataToPayload = (
  clientData, lessonsData, cartItems, activities,
) => {
  const client = mapClientToPayload(clientData);
  const lessons = [];
  forEach(cartItems, (item) => {
    const formValuesIndex = findId(lessonsData.lessons, item);
    const lesson = mapLessonToPayload(lessonsData.lessons[formValuesIndex], activities);
    if (lesson.type === 'individual' && !lesson.activity && activities) {
      lesson.activity = activities.filter((r) => r.name === lessonsData
        .lessons[formValuesIndex].activityName)[0].id;
    } else {
      lesson.activity = lesson.activity || getActivityIDFromSpeciality(activities,
        lesson);
    }

    if (lesson.type === 'individual') {
      Object.assign(lesson, { id: lesson.guid });
    }

    let omittedLesson = {
      ...lesson,
    };

    if (lesson.type === 'individual') {
      omittedLesson = {
        ...omit(lesson, ['group', 'guid']),
      };
    }

    omittedLesson.dates && omittedLesson.dates.forEach((date) => {  // eslint-disable-line
      const newLesson = JSON.parse(JSON.stringify(omittedLesson)); // clone to get new obj
      const ymdDate = ymd(date);
      if (newLesson.type === 'group') {
        const sortedItem = findItem(omittedLesson.items, date);
        newLesson.timeFrom = ymdDate.concat(sortedItem[0].timeFrom.substring(10));
        newLesson.timeTo = ymdDate.concat(sortedItem[0].timeTo.substring(10));
      } else {
        newLesson.timeFrom = ymdDate.concat(omittedLesson.timeFrom.substring(10));
        newLesson.timeTo = ymdDate.concat(omittedLesson.timeTo.substring(10));
      }
      newLesson.dateFrom = ymdDate;
      newLesson.dateTo = ymdDate;
      newLesson.clientAmount = omittedLesson.buyers.length;
      lessons.push(newLesson);
    });
  });
  return {
    client,
    lessons,
  };
};

export const mapSpecialitiesToArray = (specialities) => {
  const formattedValues = [];
  forEach(uniqBy(specialities, 'name'), (uniqSpeciality) => {
    formattedValues.push({
      name: uniqSpeciality.name,
      id: [],
      activity: [],
    });
  });
  forEach(formattedValues, (formattedValue) => {
    forEach(specialities, (speciality) => {
      if (speciality.name === formattedValue.name) {
        formattedValue.id.push(speciality.id);
        formattedValue.activity.push(speciality.activity.id);
      }
    });
  });
  const mappedSpecialities = formattedValues;
  forEach(mappedSpecialities, (mappedSpeciality) => {
    mappedSpeciality.id.push(...mappedSpecialities[11].id);
  });
  return mappedSpecialities;
};

export const checkIfFetchLessonsIsNeeded = (lesson, payloadFromLocalStorage) => {
  if (isEmpty(lesson) || (!isEmpty(lesson) && payloadFromLocalStorage.length > 0)) {
    const idsFromLocalStorage = map(payloadFromLocalStorage, (item) => item.id);
    const lessonsIds = map(lesson, (lessonItem) => lessonItem.id);
    if (!idsFromLocalStorage.every((id) => lessonsIds.indexOf(id) > -1)) {
      return true;
    }
  }
  return false;
};
