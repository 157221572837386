import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';

const TextField = ({
  input,
  placeholder,
  label,
  meta: { touched, error },
  width = 8,
  labelClass = '',
  className = '',
  maxLength,
}) => (
  <Grid.Column width={width}>
    <div className={touched && error ? `checkout-lesson-label--error ${labelClass}` : labelClass}>
      {label}
    </div>
    <div className={`ui fluid input ${className}`}>
      <input
        {...input}
        placeholder={placeholder}
        maxLength={maxLength}
      />
    </div>
    {touched && error && <small className="error">{error}</small>}
  </Grid.Column>
);

TextField.propTypes = {
  label: PropTypes.string,
  labelClass: PropTypes.string,
  className: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string,
  }).isRequired,
  placeholder: PropTypes.string,
  width: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  maxLength: PropTypes.number,
};

export default TextField;
