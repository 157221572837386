const enTranslations = {
  buyer: {
    participant: 'Participant #1',
    participant_info: 'Select activity and speciality first',
    select: 'Select...',
    name: 'Name*',
    firstname: 'Firstname*',
    enter_name: 'Enter your name',
    surname: 'Surname*',
    enter_surname: 'Enter your surname',
    age: 'Age*',
  },
  daypicker: {
    no_available_dates: '*No available dates matching lesson preferences',
    select_activity: '*Select activity first',
    select_speciality: '*Select speciality first',
    fill_participants: '*Fill in participants first',
  },
  footer: {
    get_in_touch: 'GET IN TOUCH',
    address: 'Address',
    phone: 'Phone',
    email: 'Email address',
    map: 'Location map',
  },
  header: {
    checkout: 'Checkout',
  },
  item_extra_content: {
    add_to_cart: 'ADD TO CART',
    product_removed: 'Product removed',
    product_added: 'Product added',
    remove: 'REMOVE',
    day: 'day',
  },
  lesson: {
    remove: 'Remove',
    prefered_language: 'Prefered language*',
    activity: 'Activity*',
    speciality: 'Speciality*',
    participants_info: 'Participants info:',
    number_of_participants: 'Number of participants:',
    max_no_participants: '*Max number of participants:',
    calculating: 'Calculating...',
  },
  not_found: {
    title: 'Whoops',
    description: 'The page you are looking for cannot be found',
    button: 'GO BACK',
  },
  search: {
    resort: 'Resort',
    add_filters: 'Additional filters',
    clear_filters: 'Clear filters',
    button: 'SEARCH',
    activity: 'Activity',
    speciality: 'Speciality',
    start_date: 'Start date',
    end_date: 'End date',
    language: 'Language',
    type: 'Type',
    age: 'Age',
  },
  validators: {
    required: 'This field is required',
    phone: 'Invalid phone',
    email: 'Invalid email',
    less: 'Must be equal or less than 10',
    more: 'Must be equal or more than 10',
  },
  schoolinfo: {
    no_info: 'No information',
  },
  price: {
    admin_fee: 'Administration fee',
    total: 'Total:',
  },
  header_desktop: {
    loading: 'Loading...',
  },
  spacees: {
    available_spaces: 'Available spaces:',
  },
  no_participant: {
    disabled: 'disabled',
    max: 'Maximum number of participants reached',
    no: 'Number of participants',
  },
  checkout_status: {
    thank: 'THANK YOU',
    error: 'SOMETHING WENT WRONG',
    back: 'BACK TO CHECKOUT',
    continue: 'CONTINUE',
  },
  client: {
    details: 'Your details:',
    title: 'Title*',
    mrs: 'Mrs',
    mr: 'Mr',
    name: 'Name*',
    firstname: 'Firstname*',
    enter_name: 'Enter your name',
    surname: 'Surname*',
    enter_surname: 'Enter your surname',
    tel: 'Telephone number*',
    enter_tel: 'Enter your phone number',
    next: 'NEXT',
  },
  preview: {
    review: 'Review your order',
    participants: 'Participants',
    name: 'Name',
    firstname: 'Firstname',
    surname: 'Surname',
    age: 'Age',
    exp: 'Experience level',
    details: 'Your details:',
    email: 'Email:',
    tel: 'Telephone number:',
    back: 'BACK',
    pay: 'PAY NOW',
  },
  error: {
    try_again: 'Something went wrong... Please try again later',
  },
  resort_select: {
    resort: 'Resort',
  },
  item_list: {
    no_results: 'No results',
  },
  success: {
    placed: 'Your order was placed successfully',
  },
  levels: {
    first_steps: 'first steps',
    beginner: 'beginner',
    intermediate: 'intermediate',
    advanced: 'advanced',
  },
  cart: {
    empty: 'Cart is empty',
  },
  api_activity: {
    Snowboard: 'Snowboard',
    'Cross-country': 'Cross-country',
    'Ice Climbing': 'Ice Climbing',
    Snowshoes: 'Snowshoes',
    Telemark: 'Telemark',
    'Ice Skating': 'Ice Skating',
    SKI: 'SKI',
    Any: 'Any',
  },
  api_speciality: {
    Racing: 'Racing',
    'Ice Hockey': 'Ice Hockey',
    'Cat skiing': 'Cat skiing',
    Standard: 'Standard',
    Heliskiing: 'Heliskiing',
    'Figure skating': 'Figure skating',
    Skating: 'Skating',
    Any: 'Any',
    Handicap: 'Handicap',
    Freeride: 'Freeride',
    Guiding: 'Guiding',
    Freestyle: 'Freestyle',
    Classic: 'Classic',
    Touring: 'Touring',
  },
  api_type: {
    Any: 'Any',
    Private: 'Private',
    Group: 'Group',
  },
};

export default enTranslations;
