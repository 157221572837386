import request from 'superagent';
import { createPaginator } from '../modules/redux-paginator';
import { api, subdomain } from '../config';

const filterLessons = (pageNo, pageSize, payload) => request
  .post(`${api}/shop/filter/${subdomain}?page=${pageNo}&size=${pageSize}`)
  .set('Content-Type', 'application/json')
  .send(payload);

// eslint-disable-next-line import/prefer-default-export
export const lessonsPaginator = createPaginator(`${api}/instructor/lesson/search/paginated`, ['lessons'], {
  pageArgName: 'page',
  idKey: 'id',
  countKey: 'count',
  resultsKey: 'results',
}, filterLessons);
