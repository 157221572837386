import moment from 'moment';
import * as c from '../../constants/Api';

const timeFrom = moment().toISOString();
const timeTo = moment().add(1, 'month').toISOString();

const initialState = {
  type: 'any',
  specialities: [],
  languages: [],
  resorts: [],
  timeFrom,
  timeTo,
};

const filter = (state = initialState, action) => {
  switch (action.type) {
    case c.SELECT_RESORT_SUCCESS: {
      return {
        ...state,
        resorts: action.payload,
      };
    }
    case c.SELECT_SPECIALITIES_SUCCESS: {
      return {
        ...state,
        specialities: action.payload,
      };
    }
    case c.SELECT_LANGUAGES_SUCCESS: {
      return {
        ...state,
        languages: action.payload,
      };
    }
    case c.SET_FILTER_SUCCESS: {
      return {
        ...state,
        payload: action.payload,
      };
    }
    case c.SELECT_TYPE_SUCCESS: {
      return {
        ...state,
        type: action.payload,
      };
    }
    case c.SELECT_AGE_SUCCESS: {
      return {
        ...state,
        minAge: action.payload,
      };
    }
    case c.SELECT_TIME_FROM_DATE_SUCCESS: {
      return {
        ...state,
        timeFrom: action.payload,
      };
    }
    case c.SELECT_TIME_TO_DATE_SUCCESS: {
      return {
        ...state,
        timeTo: action.payload,
      };
    }
    default:
      return state;
  }
};

export default filter;
