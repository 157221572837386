import React, { Component } from 'react';
import i18n from 'i18next';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import LanguageSwitcher from '../Field/LanguageSelector';

class HeaderFixedDesktop extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
    };
  }

  // Adds an event listener when the component is mount.
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  // Remove the event listener when the component is unmount.
  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  // Hide or show the menu.
  handleScroll = () => {
    const offset = document.getElementsByClassName('homepage-container')[0].offsetTop;
    const visible = window.pageYOffset > offset - 100;
    this.setState({
      visible,
    });
  };

  render() {
    const { account, history, cart } = this.props;
    const { visible } = this.state;
    const className = classnames(
      'sticky-header',
      { 'sticky-header--hidden': !visible },
    );
    return (
      <div className={className}>
        <div
          className="sticky-header__content__title"
          onClick={() => history.push('/')}
        >
          {account.name || i18n.t('header_desktop.loading')}
        </div>
        <LanguageSwitcher />
        <div className="sticky-header__content">
          <button
            onClick={() => window.scrollTo(0, 0)}
            type="button"
          >
            <div className="skic-icon skic-icon__search-black" />
            {i18n.t('search.button')}
          </button>
          <button
            onClick={() => history.push('/checkout')}
            disabled={!cart.cartItems.length}
            type="button"
          >
            {cart.cartItems.length
              ? (
                <div className="sticky-header__checkout-number">
                  {cart.cartItems.length}
                </div>
              )
              : <div className="skic-icon skic-icon__basket" />}
            {i18n.t('header.checkout')}
          </button>
        </div>
      </div>
    );
  }
}

HeaderFixedDesktop.propTypes = {
  account: PropTypes.shape({
    name: PropTypes.string,
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  }),
  cart: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    cartItems: PropTypes.array,
  }),
};

export default withRouter(HeaderFixedDesktop);
