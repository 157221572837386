import * as c from '../../constants/Api';

const resorts = (state = {}, action) => {
  switch (action.type) {
    case c.API_RESORTS_SUCCESS: {
      return action.payload;
    }
    default:
      return state;
  }
};

export default resorts;
