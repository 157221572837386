import * as c from '../../constants/Api';

const buyers = (state = {}, action) => {
  switch (action.type) {
    case c.SAVE_BUYERS_SUCCESS: {
      return {
        ...state,
        [action.payload.id]: action.payload,
      };
    }
    default:
      return state;
  }
};

export default buyers;
