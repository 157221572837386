const fiTranslations = {
  buyer: {
    participant: 'Osallistuja #',
    participant_info: 'Valitse aktiviteetti ja ala ensin',
    select: 'Valitse...',
    name: 'Nimi*',
    firstname: 'Etunimi*',
    enter_name: 'Kirjoita nimesi',
    surname: 'Sukunimi*',
    enter_surname: 'Kirjoita sukunimesi',
    age: 'Ikä*',
  },
  daypicker: {
    no_available_dates: '* Toivomillesi oppitunneille ei ole saatavana vapaita päiviä',
    select_activity: '* Valitse ensin aktiviteetti',
    select_speciality: '* Valitse ala ensin',
    fill_participants: '* Täytä ensin osallistujat',
  },
  footer: {
    get_in_touch: 'OTA YHTEYTTÄ',
    address: 'Osoite',
    phone: 'Puhelinnumero',
    email: 'Sähköpostiosoite',
    map: 'Sijainti kartalla',
  },
  header: {
    checkout: 'Kassalle',
  },
  item_extra_content: {
    add_to_cart: 'LISÄÄ OSTOSKORIIN',
    product_removed: 'Tuote poistettu',
    product_added: 'Tuote lisätty',
    remove: 'POISTA',
    day: 'Päivä',
  },
  lesson: {
    remove: 'Poista',
    prefered_language: 'Ensijainen kieli*',
    activity: 'Aktiviteetti*',
    speciality: 'Ala*',
    participants_info: 'Osallistujan tiedot:',
    number_of_participants: 'Osallistujien lukumäärä',
    max_no_participants: 'Osallistujien enimmäismäärä:',
    calculating: 'Laskee…',
  },
  not_found: {
    title: 'Oho',
    description: 'Etsimääsi sivua ei löydy',
    button: 'TAKAISIN',
  },
  search: {
    resort: 'kohde',
    add_filters: 'lisää hakuehtoja',
    clear_filters: 'Tyhjennä haku',
    button: 'HAE',
    activity: 'Aktiviteetti',
    speciality: 'Ala',
    start_date: 'Aloituspäivämäärä',
    end_date: 'Lopetuspäivämäärä',
    language: 'Kieli',
    type: 'Tyyppi',
    age: 'Ikä',
  },
  validators: {
    required: 'Tämä on pakollinen kenttä',
    phone: 'Virheellinen puhelinnumero',
    email: 'Virheellinen sähköposti',
    less: 'Täytyy olla sama tai pienempi kuin 10',
    more: 'Täytyy olla sama tai suurempi kuin 10',
  },
  schoolinfo: {
    no_info: 'Ei tietoa',
  },
  price: {
    admin_fee: 'Käsittelykulu',
    total: 'Yhteensä',
  },
  header_desktop: {
    loading: 'Lataa',
  },
  spacees: {
    available_spaces: 'Saatavilla olevat tilat',
  },
  no_participant: {
    disabled: 'Ei käytössä',
    max: 'Osallistujen enimmäismäärä on täynnä',
    no: 'Osallistujamäärä',
  },
  checkout_status: {
    thank: 'KIITOS',
    error: 'JOKIN MENI VIKAAN',
    back: 'TAKAISIN KASSALLE',
    continue: 'JATKA',
  },
  client: {
    details: 'Tietosi:',
    title: 'Titteli:',
    mrs: 'Rouva',
    mr: 'Herra',
    name: 'Nimi*',
    firstname: 'Etunimi*',
    enter_name: 'Kirjoita nimesi',
    surname: 'Sukunimi*',
    enter_surname: 'Kirjoita sukunimesi',
    tel: 'Puhelinnumero*',
    enter_tel: 'Kirjoita puhelinnumerosi',
    next: 'SEURAAVA',
  },
  preview: {
    review: 'Tarkista tilauksesi',
    participants: 'Osallistujat',
    name: 'Nimi',
    firstname: 'Etunimi',
    surname: 'Sukunimi',
    age: 'Ikä',
    exp: 'Kokemustaso',
    details: 'Tietosi:',
    email: 'Sähköposti:',
    tel: 'Puhelinnumero:',
    back: 'TAKAISIN',
    pay: 'MAKSA NYT',
  },
  error: {
    try_again: 'Jokin meni vikaan... yritä myöhemmin uudelleen',
  },
  resort_select: {
    resort: 'Kohde',
  },
  item_list: {
    no_results: 'Ei tuloksia',
  },
  success: {
    placed: 'Tilauksesi on vastaanotettu',
  },
  levels: {
    first_steps: 'Aluksi',
    beginner: 'Aloittelija',
    intermediate: 'Keskitaso',
    advanced: 'Edistynyt',
  },
  cart: {
    empty: 'Ostoskori on tyhjä',
  },
  api_activity: {
    Snowboard: 'Lumilauta',
    'Cross-country': 'Maastohiihto',
    'Ice Climbing': 'Jääkiipeily',
    Snowshoes: 'Lumikengät',
    Telemark: 'Telemark',
    'Ice Skating': 'Luistelu',
    SKI: 'HIIHTO',
    Any: 'Mikä tahansa',
  },
  api_speciality: {
    Racing: 'Kilpailu',
    'Ice Hockey': 'Jääkiekko',
    'Cat skiing': 'Cat skiing',
    Standard: 'Tavallinen',
    Heliskiing: 'Helihiihto',
    'Figure skating': 'Taitoluistelu',
    Skating: 'Luistelu',
    Any: 'Mikä tahansa',
    Handicap: 'vamma',
    Freeride: 'Freeride',
    Guiding: 'Opastus',
    Freestyle: 'Freestyle',
    Classic: 'Klassinen',
    Touring: 'Matka',
  },
  api_type: {
    Any: 'Mikä tahansa',
    Private: 'yksilöllinen',
    Group: 'ryhmä',
  },
};

export default fiTranslations;
