import React from 'react';
import PropTypes from 'prop-types';
import { formatDate } from '../../utils/format';
import IconLabel from './IconLabel';

export const DateRange = ({ dates, type }) => {
  let firstDate = '';
  let lastDate = '';
  if (type === 'group') {
    firstDate = dates[0] ? dates[0].timeFrom : dates.timeFrom;
    lastDate = dates.length > 1 && Object.values(dates).slice(-1)[0].timeTo;
  } else {
    firstDate = dates[0].date;
    lastDate = dates[dates.length - 1].date;
  }
  return (
    <div>
      {dates[0] && type === 'individual'
        ? (
          <span>
            {formatDate(firstDate)}
            {' '}
            {lastDate !== dates[0].date && `- ${formatDate(lastDate)}`}
          </span>
        )
        : (
          <span>
            {formatDate(firstDate)}
            {lastDate && lastDate !== firstDate && ` - ${formatDate(lastDate)}`}
          </span>
        )}
    </div>
  );
};

DateRange.propTypes = {
  type: PropTypes.string.isRequired,
  dates: PropTypes.arrayOf(PropTypes.object),
};

const DateDisplay = ({ dates, type }) => (
  <IconLabel icon="date">
    <DateRange
      dates={Object.values(dates)}
      type={type}
    />
  </IconLabel>
);

DateDisplay.propTypes = {
  type: PropTypes.string,
  dates: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

export default DateDisplay;
