const noTranslations = {
  buyer: {
    participant: 'Deltaker #',
    participant_info: 'Velg aktivitet og spesialitet først',
    select: 'Å velge...',
    name: 'Navn*',
    firstname: 'Fornavn*',
    enter_name: 'Skriv inn navnet ditt',
    surname: 'Etternavn',
    enter_surname: 'Skriv inn etternavnet ditt',
    age: 'Alder*',
  },
  daypicker: {
    no_available_dates: '*Ingen tilgjengelige datoer som samsvarer med leksjonspreferansene',
    select_activity: '*Velg aktivitet først',
    select_speciality: '*Velg spesialitet først',
    fill_participants: '*Fyll inn deltakere først',
  },
  footer: {
    get_in_touch: 'TA KONTAKT',
    address: 'Adresse',
    phone: 'Telefon',
    email: 'Epostadresse',
    map: 'Plasseringskart',
  },
  header: {
    checkout: 'Sjekk ut',
  },
  item_extra_content: {
    add_to_cart: 'LEGG I HANDLEKURV',
    product_removed: 'Produktet er fjernet',
    product_added: 'Produkt lagt til',
    remove: 'FJERNE',
    day: 'Dag',
  },
  lesson: {
    remove: 'Fjerne',
    prefered_language: 'Foretrukket språk*',
    activity: 'Aktivitet*',
    speciality: 'Spesialitet*',
    participants_info: 'Deltaker info:',
    number_of_participants: 'Antall deltakere:',
    max_no_participants: '*Maks antall deltakere:',
    calculating: 'Beregner...',
  },
  not_found: {
    title: 'Huff',
    description: 'Siden du leter etter blir ikke funnet',
    button: 'GÅ TILBAKE',
  },
  search: {
    resort: 'Feriested',
    add_filters: 'Ekstra filtre',
    clear_filters: 'Tøm filtre',
    button: 'SØK',
    activity: 'Aktivitet',
    speciality: 'Spesialitet',
    start_date: 'Startdato',
    end_date: 'Sluttdato',
    language: 'Språk',
    type: 'Type',
    age: 'Alder',
  },
  validators: {
    required: 'Dette feltet er obligatorisk',
    phone: 'Ugyldig telefon',
    email: 'Ugyldig epost',
    less: 'Må være lik eller mindre enn 10',
    more: 'Må være lik eller mer enn 10',
  },
  schoolinfo: {
    no_info: 'Ingen informasjon',
  },
  price: {
    admin_fee: 'Administrasjons avgift',
    total: 'Total:',
  },
  header_desktop: {
    loading: 'Laster inn...',
  },
  spacees: {
    available_spaces: 'Tilgjengelige plasser:',
  },
  no_participant: {
    disabled: 'Funksjonshemmet',
    max: 'Maksimalt antall deltakere nådd',
    no: 'Antall deltakere',
  },
  checkout_status: {
    thank: 'TAKK SKAL DU HA',
    error: 'NOE GIKK GALT',
    back: 'TILBAKE TIL KASSA',
    continue: 'FORTSETTE',
  },
  client: {
    details: 'Dine detaljer:',
    title: 'Tittel*',
    mrs: 'Fru',
    mr: 'MR',
    name: 'Navn*',
    firstname: 'Fornavn*',
    enter_name: 'Skriv inn navnet ditt',
    surname: 'Etternavn*',
    enter_surname: 'Skriv inn etternavnet ditt',
    tel: 'Telefonnummer*',
    enter_tel: 'Tast inn ditt telefonnummer',
    next: 'NESTE',
  },
  preview: {
    review: 'Se over bestillingen din',
    participants: 'Deltakere',
    name: 'Navn',
    firstname: 'Fornavn',
    surname: 'Etternavn',
    age: 'Alder',
    exp: 'Erfaringsnivå',
    details: 'Dine detaljer:',
    email: 'E-post:',
    tel: 'Telefonnummer:',
    back: 'TILBAKE',
    pay: 'BETAL NÅ',
  },
  error: {
    try_again: 'Noe gikk galt. Prøv igjen senere',
  },
  resort_select: {
    resort: 'Feriested',
  },
  item_list: {
    no_results: 'Ingen resultater',
  },
  success: {
    placed: 'Bestillingen din ble lagt inn',
  },
  levels: {
    first_steps: 'Første steg',
    beginner: 'Nybegynner',
    intermediate: 'Mellomliggende',
    advanced: 'Avansert',
  },
  cart: {
    empty: 'Handlevognen er tom',
  },
  api_activity: {
    Snowboard: 'Snowboard',
    'Cross-country': 'Langrenn',
    'Ice Climbing': 'Isklatring',
    Snowshoes: 'Truger',
    Telemark: 'Telemark',
    'Ice Skating': 'Skøyter',
    SKI: 'SKI',
    Any: 'Noen',
  },
  api_speciality: {
    Racing: 'Racing',
    'Ice Hockey': 'Ishockey',
    'Cat skiing': 'Cat skiing',
    Standard: 'Standard',
    Heliskiing: 'Heliskiing',
    'Figure skating': 'Kunstløp',
    Skating: 'Skating',
    Any: 'Noen',
    Handicap: 'Handicap',
    Freeride: 'Freeride',
    Guiding: 'Guiding',
    Freestyle: 'Freestyle',
    Classic: 'Klassisk',
    Touring: 'Touring',
  },
  api_type: {
    Any: 'Noen',
    Private: 'individuell',
    Group: 'gruppe',
  },
};

export default noTranslations;
