import 'regenerator-runtime/runtime';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import DefaultLayout from './containers/Layouts/DefaultLayout';
import NotFound from './containers/NotFound/NotFound';
import configureStore from './redux/store/configureStore';
import HomePage from './containers/HomePage/HomePage';
import Success from './containers/Success/Success';
import Error from './containers/Error/Error';
import Checkout from './containers/Checkout';

const store = configureStore();

ReactDOM.render(
  (
    <Provider store={store}>
      <Router>
        <Switch>
          <Route
            exact
            name="default"
            path="/"
            render={(routeProps) => (
              <DefaultLayout name="default" RenderComponent={HomePage} routeProps={routeProps} />
            )}
          />
          <Route
            name="checkout"
            path="/checkout/:preview?"
            render={(routeProps) => (
              <DefaultLayout name="checkout" RenderComponent={Checkout} routeProps={routeProps} />
            )}
          />
          <Route
            name="success"
            path="/success"
            render={(routeProps) => (
              <DefaultLayout name="success" RenderComponent={Success} routeProps={routeProps} />
            )}
          />
          <Route
            name="error"
            path="/error"
            render={(routeProps) => (
              <DefaultLayout name="error" RenderComponent={Error} routeProps={routeProps} />
            )}
          />
          <Route name="404" path="*" component={NotFound} />
        </Switch>
      </Router>
    </Provider>
  ),
  document.getElementById('root'),
);
