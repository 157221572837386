import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { paginatorMiddleware } from '../../modules/redux-paginator';
import reducers from '../modules/reducer';

/* eslint-disable */
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
/* eslint-enable */

const middlewares = [thunk, paginatorMiddleware];
const middlewareEnhancer = applyMiddleware(...middlewares);
const enhancers = composeEnhancers(middlewareEnhancer);

export default function configureStore(initialState) {
  const store = createStore(reducers, initialState, enhancers);
  window.store = store;
  return store;
}
