const csTranslations = {
  buyer: {
    participant: 'Účastník #',
    participant_info: 'Nejprve vyberte aktivitu a specializaci',
    select: 'Vybrat...',
    name: 'Název*',
    firstname: 'Jméno*',
    enter_name: 'Zadejte své jméno',
    surname: 'Příjmení',
    enter_surname: 'Zadejte své příjmení',
    age: 'Věk*',
  },
  daypicker: {
    no_available_dates: '*Žádná dostupná data odpovídající preferencím lekcí',
    select_activity: '* Nejprve vyberte aktivitu',
    select_speciality: '* Nejprve vyberte specialitu',
    fill_participants: '*Nejprve vyplňte účastníky',
  },
  footer: {
    get_in_touch: 'KONTAKTUJTE NÁS',
    address: 'Adresa',
    phone: 'Telefon',
    email: 'Emailová adresa',
    map: 'Mapa umístění',
  },
  header: {
    checkout: 'Košík',
  },
  item_extra_content: {
    add_to_cart: 'PŘIDAT DO KOŠÍKU',
    product_removed: 'Produkt byl odstraněn',
    product_added: 'Produkt přidán',
    remove: 'ODSTRANIT',
    day: 'Den',
  },
  lesson: {
    remove: 'Odstranit',
    prefered_language: 'Preferovaný jazyk*',
    activity: 'Aktivita*',
    speciality: 'Specialita*',
    participants_info: 'Informace o účastnících:',
    number_of_participants: 'Počet zúčastněných:',
    max_no_participants: '*Maximální počet účastníků:',
    calculating: 'Probíhá výpočet...',
  },
  not_found: {
    title: 'Jejda',
    description: 'Stránka, kterou hledáte, nebyla nalezena',
    button: 'Jděte ZPĚT',
  },
  search: {
    resort: 'Letovisko',
    add_filters: 'Další filtry',
    clear_filters: 'Vymazat filtry',
    button: 'VYHLEDÁVÁNÍ',
    activity: 'Aktivita',
    speciality: 'Specialita',
    start_date: 'Počáteční datum',
    end_date: 'Datum ukončení',
    language: 'Jazyk',
    type: 'Typ',
    age: 'Věk',
  },
  validators: {
    required: 'Toto pole je povinné',
    phone: 'Neplatný telefon',
    email: 'Neplatný e-mail',
    less: 'Musí být roven nebo menší než 10',
    more: 'Musí být rovno nebo více než 10',
  },
  schoolinfo: {
    no_info: 'Žádné informace',
  },
  price: {
    admin_fee: 'Administrativní poplatek',
    total: 'Celkem:',
  },
  header_desktop: {
    loading: 'Načítání...',
  },
  spacees: {
    available_spaces: 'Dostupná místa:',
  },
  no_participant: {
    disabled: 'zakázáno',
    max: 'Byl dosažen maximální počet účastníků',
    no: 'Počet zúčastněných',
  },
  checkout_status: {
    thank: 'DĚKUJI',
    error: 'NĚCO SE POKAZILO',
    back: 'ZPĚT K POKLADNĚ',
    continue: 'POKRAČOVAT',
  },
  client: {
    details: 'Vaše údaje:',
    title: 'Titul*',
    mrs: 'Paní',
    mr: 'Pan',
    name: 'Název*',
    firstname: 'Jméno*',
    enter_name: 'Zadejte své jméno',
    surname: 'Příjmení*',
    enter_surname: 'Zadejte své příjmení',
    tel: 'Telefonní číslo*',
    enter_tel: 'Vložte své telefonní číslo',
    next: 'DALŠÍ',
  },
  preview: {
    review: 'Zkontrolujte svou objednávku',
    participants: 'Účastníci',
    name: 'Název',
    firstname: 'Jméno',
    surname: 'Příjmení',
    age: 'Věk',
    exp: 'Úroveň zkušeností',
    details: 'Vaše údaje:',
    email: 'E-mailem:',
    tel: 'Telefonní číslo:',
    back: 'ZPĚT',
    pay: 'ZAPLAŤ TEĎ',
  },
  error: {
    try_again: 'Něco se pokazilo... Zkuste to prosím znovu později',
  },
  resort_select: {
    resort: 'Letovisko',
  },
  item_list: {
    no_results: 'Žádné výsledky',
  },
  success: {
    placed: 'Vaše objednávka byla úspěšně zadána',
  },
  levels: {
    first_steps: 'První kroky',
    beginner: 'Začátečník',
    intermediate: 'Středně pokročilí',
    advanced: 'Pokročilý',
  },
  cart: {
    empty: 'Košík je prázdný',
  },
  api_activity: {
    Snowboard: 'Snowboard',
    'Cross-country': 'Přespolní',
    'Ice Climbing': 'Lezení v ledu',
    Snowshoes: 'Sněžnice',
    Telemark: 'Telemark',
    'Ice Skating': 'Bruslení',
    SKI: 'LYŽE',
    Any: 'Vše',
  },
  api_speciality: {
    Racing: 'Závodění',
    'Ice Hockey': 'Lední hokej',
    'Cat skiing': 'Cat skiing',
    Standard: 'Standard',
    Heliskiing: 'Heliskiing',
    'Figure skating': 'Krasobruslení',
    Skating: 'Jízda na bruslích',
    Any: 'Vše',
    Handicap: 'Handicap',
    Freeride: 'Jízda zdarma',
    Guiding: 'Vedení',
    Freestyle: 'Volný styl',
    Classic: 'Klasický',
    Touring: 'Touring',
  },
  api_type: {
    Any: 'Vše',
    Private: 'individuální',
    Group: 'skupina',
  },
};

export default csTranslations;
