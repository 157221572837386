/* eslint-disable */
import { REQUEST_PAGE } from './actionTypes'
import { receivePage } from './actions'
import {
  fetchPage,
  FROM_CACHE_FLAG,
} from './agent'

const paginatorMiddleware = ({ dispatch }) => next => action => {
  if (action && action.type === REQUEST_PAGE) {
    const {
      meta: {
        endpoint,
        name,
        initialItem,
        resultsKey,
        countKey,
        pageArgName,
        idKey,
      },
      payload: {
        page,
        params,
        payload,
      },
      callApi,
    } = action;
    dispatch(dispatch => {
      try {
        fetchPage(endpoint, pageArgName, page, params, callApi, payload)
          .then(res => {
            const { response, body, [FROM_CACHE_FLAG]: fromCache } = res;
            let results, count;



            if (typeof resultsKey === 'undefined') {
              results = response;
            }
            else if (response) {
              results = response[resultsKey];
              count = response[countKey];
            } else {
              results = body[resultsKey];
              count = body[countKey];
            }
            dispatch(receivePage(
              endpoint,
              name,
              initialItem,
              pageArgName,
              idKey,
              page,
              params,
              results,
              count,
              res,
              !(typeof fromCache === 'undefined'),
            ))
          })
      }
      catch (err) {

      }
    })
  }
  return next(action);
};

export default paginatorMiddleware
/* eslint-enable */
