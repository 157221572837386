const huTranslations = {
  buyer: {
    participant: 'Résztvevő #',
    participant_info: 'Először válassza ki a tevékenységet és az ágazatot',
    select: 'Kiválasztás',
    name: 'Név*',
    firstname: 'Keresztnév*',
    enter_name: 'Adja meg a nevét*',
    surname: 'Vezetéknév',
    enter_surname: 'Adja meg a vezetéknevét',
    age: 'Kor*',
  },
  daypicker: {
    no_available_dates: '*Nincs elérhető dátum a kiválasztott órákhoz',
    select_activity: '*Először válassza ki a tevékenységet',
    select_speciality: '*Először válassza ki az ágazatot',
    fill_participants: '*Először töltse ki a résztvevőket',
  },
  footer: {
    get_in_touch: 'LÉPJEN VELÜNK KAPCSOLATBA',
    address: 'Cím',
    phone: 'Telefonszám',
    email: 'E-mail cím',
    map: 'Térkép',
  },
  header: {
    checkout: 'Fizetés',
  },
  item_extra_content: {
    add_to_cart: 'HOZZÁADÁS A KOSÁRHOZ',
    product_removed: 'Termék eltávolítva',
    product_added: 'Termék hozzáadva',
    remove: 'ELTÁVOLÍTÁS',
    day: 'Nap',
  },
  lesson: {
    remove: 'Eltávolítás',
    prefered_language: 'Preferált nyelv*',
    activity: 'Tevékenység*',
    speciality: 'Ágazat*',
    participants_info: 'Résztvevők információi:',
    number_of_participants: 'Résztvevők száma:',
    max_no_participants: '*Résztvevők maximális száma',
    calculating: 'Számítás...',
  },
  not_found: {
    title: 'Upsz',
    description: 'Az oldal nem található',
    button: 'VISSZA',
  },
  search: {
    resort: 'Üdülő',
    add_filters: 'Egyéb szűrők',
    clear_filters: 'Szűrők eltávolítása',
    button: 'KERESÉS',
    activity: 'Tevékenység',
    speciality: 'Ágazat',
    start_date: 'Kezdés dátuma',
    end_date: 'Befejezés dátuma',
    language: 'Nyelv',
    type: 'Típus',
    age: 'Kor*',
  },
  validators: {
    required: 'Ez a mező kötelező',
    phone: 'Érvénytelen telefonszám',
    email: 'Érvénytelen e-mail cím',
    less: 'Kevesebb vagy egyenlő, mint 10',
    more: 'Nagyobb vagy egyenlő, mint 10',
  },
  schoolinfo: {
    no_info: 'Nincs információ',
  },
  price: {
    admin_fee: 'Adminisztrációs költség',
    total: 'Összesen:',
  },
  header_desktop: {
    loading: 'Betöltés...',
  },
  spacees: {
    available_spaces: 'Elérhető helyek:',
  },
  no_participant: {
    disabled: 'Letiltva',
    max: 'Elérte a maximális résztvevők számát',
    no: 'Résztvevők száma:',
  },
  checkout_status: {
    thank: 'KÖSZÖNJÜK',
    error: 'VALAMI HIBA TÖRTÉNT',
    back: 'VISSZA A FIZETÉSHEZ',
    continue: 'FOLYTATÁS',
  },
  client: {
    details: 'Az Ön adatai:',
    title: 'Titulus*',
    mrs: 'Hölgy',
    mr: 'Úr',
    name: 'Név*',
    firstname: 'Keresztnév*',
    enter_name: 'Adja meg a nevét',
    surname: 'Vezetéknév*',
    enter_surname: 'Adja meg a vezetéknevét',
    tel: 'Telefonszám*',
    enter_tel: 'Adja meg a telefonszámát',
    next: 'KÖVETKEZŐ',
  },
  preview: {
    review: 'Tekintse át rendelését',
    participants: 'Résztvevők',
    name: 'Név',
    firstname: 'Keresztnév',
    surname: 'Vezetéknév',
    age: 'Kor',
    exp: 'Tapasztalati szint',
    details: 'Az Ön adatai:',
    email: 'E-mail:',
    tel: 'Telefonszám:',
    back: 'VISSZA',
    pay: 'FIZETÉS MOST',
  },
  error: {
    try_again: 'Valami hiba történt… Próbálja meg később',
  },
  resort_select: {
    resort: 'Üdülő',
  },
  item_list: {
    no_results: 'Nincs eredmény',
  },
  success: {
    placed: 'Az Ön rendelése sikeres volt',
  },
  levels: {
    first_steps: 'Első lépések',
    beginner: 'Kezdő',
    intermediate: 'Középhaladó',
    advanced: 'Haladó',
  },
  cart: {
    empty: 'A kosár üres',
  },
  api_activity: {
    Snowboard: 'Snowboard',
    'Cross-country': 'Cross-country',
    'Ice Climbing': 'Jégmászás',
    Snowshoes: 'Hócipők',
    Telemark: 'Telemark',
    'Ice Skating': 'Jégkorcsolyázás',
    SKI: 'SÍELÉS',
    Any: 'Bármi',
  },
  api_speciality: {
    Racing: 'Verseny',
    'Ice Hockey': 'Jéghoki',
    'Cat skiing': 'Cat skiing',
    Standard: 'Általános',
    Heliskiing: 'Heliskiing',
    'Figure skating': 'Műkorcsolya',
    Skating: 'Korcsolya',
    Any: 'Bármi',
    Handicap: 'Handicap',
    Freeride: 'Freeride',
    Guiding: 'Guiding',
    Freestyle: 'Freestyle',
    Classic: 'Klasszikus',
    Touring: 'Túra',
  },
  api_type: {
    Any: 'Bármi',
    Private: 'egyéni',
    Group: 'csoportos',
  },
};

export default huTranslations;
