import i18n from 'i18next';

export const required = (value) => (value ? undefined : i18n.t('validators.required'));
export const requiredAny = (value) => (((value && value.name && value.name.toLowerCase() === 'any') || !value) ? i18n.t('validators.required') : undefined);
export const level = (value) => (((value && value.level && value.level.toLowerCase() === 'any') || !value) ? i18n.t('validators.required') : undefined);
export const daypicker = (value) => (((value && value.length === 0) || !value) ? i18n.t('validators.required') : undefined);

export const phone = (value) => (
  (value && !/^(\+\d{1}(|-)\d{1,3}(| |-))[0-9()+ -]{6,15}$/.test(value))
    ? i18n.t('validators.phone')
    : undefined
);

export const email = (value) => {
  if (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) {
    return i18n.t('validators.email');
  }

  return undefined;
};

export const maxValue = (max) => (value) => (value && parseInt(value, 10) > max
  ? i18n.t(`Must be equal or less than ${max}`) : undefined);
export const minValue = (min) => (value) => (value && parseInt(value, 10) < min
  ? i18n.t(`Must be equal or more than ${min}`) : undefined);

export const minVal1 = minValue(1);
