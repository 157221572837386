import React from 'react';
import PropTypes from 'prop-types';

const ItemLabel = ({ label = '', children, icon = '' }) => (
  <div className="item-icon-label">
    {label ? <div>{label}</div> : ''}
    {icon ? <div className={`skic-icon skic-icon__${icon}`} /> : ''}
    {children}
  </div>
);

ItemLabel.propTypes = {
  label: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
  ]).isRequired,
  icon: PropTypes.string,
};

export default ItemLabel;
