const hrTranslations = {
  buyer: {
    participant: 'Sudionik #',
    participant_info: 'Prvo odaberite djelatnost i specijalnost',
    select: 'Odaberite…',
    name: 'Ime*',
    firstname: 'Ime*',
    enter_name: 'Unesite ime',
    surname: 'Prezime',
    enter_surname: 'Unesite prezime',
    age: 'Dob*',
  },
  daypicker: {
    no_available_dates: '*Nema dostupnih datuma koji odgovaraju zadanim postavkama',
    select_activity: '*Prvo odaberite aktivnost',
    select_speciality: '*Prvo odaberite specijalnost',
    fill_participants: '*Prvo upišite sudionike',
  },
  footer: {
    get_in_touch: 'JAVITE SE',
    address: 'Adresa',
    phone: 'Telefon',
    email: 'E pošta',
    map: 'Karta lokacije',
  },
  header: {
    checkout: 'Provjera',
  },
  item_extra_content: {
    add_to_cart: 'DODAJ U KOŠARICU',
    product_removed: 'Proizvod uklonjen',
    product_added: 'Proizvod dodan',
    remove: 'Ukloni',
    day: 'Dan',
  },
  lesson: {
    remove: 'Ukloni',
    prefered_language: 'Željeni jezik*',
    activity: 'Aktivnost*',
    speciality: 'Specijalnost*',
    participants_info: 'Podatci o sudionicima:',
    number_of_participants: 'Broj sudionika:',
    max_no_participants: '*Maksimalni broj sudionika:',
    calculating: 'Izračunavam…',
  },
  not_found: {
    title: 'Ups…',
    description: 'Stranicu koju tražite nije moguće pronaći',
    button: 'Natrag',
  },
  search: {
    resort: 'Rješenje',
    add_filters: 'Dodatni filteri',
    clear_filters: 'Očistiti filtere',
    button: 'Traži',
    activity: 'Aktivnost',
    speciality: 'Specijalnost',
    start_date: 'Datum početka',
    end_date: 'Datum završetka',
    language: 'Jezik',
    type: 'Vrsta',
    age: 'Dob',
  },
  validators: {
    required: 'Ovo polje je obavezno',
    phone: 'Neispravan broj telefona',
    email: 'Neispravna adresa e pošte',
    less: 'Mora biti jednako ili manje od 10',
    more: 'Mora biti jednako ili više od 10',
  },
  schoolinfo: {
    no_info: 'Nema informacija',
  },
  price: {
    admin_fee: 'Administrativna naknada',
    total: 'Ukupno:',
  },
  header_desktop: {
    loading: 'Učitavam…',
  },
  spacees: {
    available_spaces: 'Slobodnih mjesta:',
  },
  no_participant: {
    disabled: 'Onemogućeno',
    max: 'Dosegnut je maksimalan broj sudionika',
    no: 'Broj sudionika',
  },
  checkout_status: {
    thank: 'Hvala vam',
    error: 'Nešto nije u redu',
    back: 'NATRAG NA PROVJERU',
    continue: 'Nastavite',
  },
  client: {
    details: 'Vaši podatci:',
    title: 'Titula',
    mrs: 'Gospođa',
    mr: 'Gospodin',
    name: 'Ime*',
    firstname: 'Ime*',
    enter_name: 'Upišite vaše ime',
    surname: 'Prezime',
    enter_surname: 'Upišite vaše prezime',
    tel: 'Broj telefona',
    enter_tel: 'Upišite vaš broj telefona',
    next: 'Sljedeće',
  },
  preview: {
    review: 'Pregledajte svoju narudžbu',
    participants: 'Sudionici',
    name: 'Ime',
    firstname: 'Ime',
    surname: 'Prezime',
    age: 'Dob',
    exp: 'Razina iskustva',
    details: 'Vaši podatci:',
    email: 'E pošta:',
    tel: 'Broj telefona:',
    back: 'NATRAG',
    pay: 'Platite',
  },
  error: {
    try_again: 'Nešto je pošlo po zlu. Molimo pokušajte kasnije',
  },
  resort_select: {
    resort: 'Rješenje',
  },
  item_list: {
    no_results: 'Nema rezultata',
  },
  success: {
    placed: 'Vaša narudžba je uspješno poslana',
  },
  levels: {
    first_steps: 'Prvi koraci',
    beginner: 'Početnik',
    intermediate: 'Srednji',
    advanced: 'Napredni',
  },
  cart: {
    empty: 'Košarica je prazna',
  },
  api_activity: {
    Snowboard: 'Snowboard',
    'Cross-country': 'Cross-country',
    'Ice Climbing': 'Penjanje po ledu',
    Snowshoes: 'Snježne krplje',
    Telemark: 'Telemark',
    'Ice Skating': 'Klizanje na ledu',
    SKI: 'SKIJANJE',
    Any: 'Bilo koje',
  },
  api_speciality: {
    Racing: 'Utrkivanje',
    'Ice Hockey': 'Hokej na ledu',
    'Cat skiing': 'Cat skiing',
    Standard: 'Standardno',
    Heliskiing: 'Heli skijanje',
    'Figure skating': 'Umjetničko klizanje',
    Skating: 'Klizanje',
    Any: 'Bilo koje',
    Handicap: 'Hendikep',
    Freeride: 'Besplatna vožnja',
    Guiding: 'Vođenje',
    Freestyle: 'Slobodni stil',
    Classic: 'Klasični',
    Touring: 'Obilasci',
  },
  api_type: {
    Any: 'Bilo koje',
    Private: 'individualno',
    Group: 'grupno',
  },
};

export default hrTranslations;
